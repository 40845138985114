import { useEffect } from "react";
import { useDispatch } from "react-redux";
import PaintCostC from "../../containers/admin-rates/PaintCostC";
import { fetchMaterialDefaults } from "../../redux/material-defaults/action";

const PaintCost = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMaterialDefaults());
  }, []);
  return <PaintCostC />;
};

export default PaintCost;
