import { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Select,
  Input,
  Form,
  Rate,
  message,
} from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  bulkUpdateRates,
  projectAndOptionRatesNewUpdate,
  projectRateDeleteAndOptionRatesUpdate,
} from "../../redux/project/action";

import "./SendRequest.less";

import { useParams } from "react-router-dom";
import _, { update } from "lodash";
import { RectangleIcon } from "../../utils/icons";
import { compareObjects } from "../../utils/helpers";
var crypto = require("crypto");

type Props = {
  showEditModal: boolean;
  toggleEditModal: (item: any) => void;
  row: any;
  setDataRates: any;
  paintRates: any;
};

const EditPaintRates = (props: Props) => {
  const [surface, setSurface] = useState<any>({});
  const [prepColor, setPrepColor] = useState("");
  const [paintColor, setPaintColor] = useState("");
  const [primeColor, setPrimeColor] = useState("");
  const dispatch = useDispatch<any>();
  const [form] = Form.useForm();
  const { id } = useParams<any>();

  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  useEffect(() => {
    setSurface(props?.row);
  }, [props?.row, props.showEditModal]);

  useEffect(() => {
    if (props.row && Object.keys(props?.row)?.length > 0) {
      form.setFieldsValue({
        ...props.row,
        paintMaterial: props.row.paintMaterial?._id,
        primerMaterial: props.row.primerMaterial?._id,
      });
    }
  }, [props?.row?._id, props.showEditModal]);

  useEffect(() => {
    setColor(setPrepColor, surface?.prepLevel);
  }, [surface?.prepLevel]);

  useEffect(() => {
    setColor(setPaintColor, surface?.prepLevel);
  }, [surface?.paintLevel]);

  useEffect(() => {
    setColor(setPrimeColor, surface?.prepLevel);
  }, [surface?.primeLevel]);

  const setColor = (setColorFn: Function, value: number, defaultValue = 0) => {
    switch (value) {
      case 0:
        setColorFn("#d1cec4");
        break;
      case 1:
        setColorFn("#FF0000");
        break;
      case 2:
        setColorFn("#FDB913");
        break;
      case 3:
        setColorFn("#00B44F");
        break;
      default:
        setColor(setColorFn, defaultValue, defaultValue);
    }
  };

  const handleHover = (
    setColorFn: Function,
    level: number,
    defaultValue: number
  ) => {
    setColor(setColorFn, level, defaultValue);
  };

  const onFinish = (values: any) => {
    const newRow = { ...surface };
    const newObject = Object.assign(newRow, values);

    let updatedObject: any = newObject;

    updatedObject.modified = true;

    props.toggleEditModal(false);
    if (updatedObject.paintMaterial === "customerSupplied") {
      updatedObject.customerSuppliedPaint = true;
      delete updatedObject.paintMaterial;
      updatedObject.customerSuppliedPaint = true;
    } else {
      updatedObject.customerSuppliedPaint = false;
    }
    if (updatedObject?.primerMaterial === "customerSupplied") {
      updatedObject.customerSuppliedPrimer = true;
      delete updatedObject.primerMaterial;
      updatedObject.customerSuppliedPrimer = true;
    } else {
      updatedObject.customerSuppliedPrimer = false;
    }
    const body = {
      category: "paint",
      rate: updatedObject,
      project: id,
    };

    const defaultPaintRates = adminDefaults.materialDefaults.paints;
    const defaultPrimerRates = adminDefaults.materialDefaults.primers;
    const paintRateObject = defaultPaintRates.find(
      (element: any) => element._id === updatedObject.paintMaterial
    );
    const primerRateObject = defaultPrimerRates.find(
      (element: any) => element._id === updatedObject.primerMaterial
    );

    setSurface({
      ...newObject,
      paintMaterial: paintRateObject,
      primerMaterial: primerRateObject,
    });

    let project: any = _.cloneDeep(items[currentProject]);
    let projectPaintIndex = project.projectRates.paintRates.findIndex(
      (element: any) => element._id === surface._id
    );
    for (var key in updatedObject) {
      if (
        typeof project.projectRates.paintRates[projectPaintIndex][key] ===
        "number"
      ) {
        project.projectRates.paintRates[projectPaintIndex][key] =
          +updatedObject[key];
      } else if (key === "paintMaterial") {
        project.projectRates.paintRates[projectPaintIndex][key] =
          paintRateObject;
      } else if (key === "primerMaterial") {
        project.projectRates.paintRates[projectPaintIndex][key] =
          primerRateObject;
      } else {
        project.projectRates.paintRates[projectPaintIndex][key] =
          updatedObject[key];
      }
    }

    //some calculations will be required
    for (const singleOption of project.options) {
      let optionPaintIndex = singleOption.rates.paintRates.findIndex(
        (element: any) => element.projectLaborRate === surface._id
      );

      if (optionPaintIndex >= 0) {
        for (var key in updatedObject) {
          if (
            typeof singleOption.rates.paintRates[optionPaintIndex][key] ===
            "number"
          ) {
            singleOption.rates.paintRates[optionPaintIndex][key] =
              +updatedObject[key];
          } else if (key === "paintMaterial") {
            singleOption.rates.paintRates[optionPaintIndex][key] =
              paintRateObject;
          } else if (key === "primerMaterial") {
            singleOption.rates.paintRates[optionPaintIndex][key] =
              primerRateObject;
          } else {
            if (key !== "_id") {
              singleOption.rates.paintRates[optionPaintIndex][key] =
                updatedObject[key];
            }
          }
        }
      }
    }

    dispatch(bulkUpdateRates(body, surface._id, project, adminDefaults));
  };

  const deepEqual = (obj1: any, obj2: any, depth: number = 0): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects
    const keys1 = Object.keys(obj1).filter(
      (key) =>
        !["_id", "customerSuppliedPaint", "customerSuppliedPrimer"].includes(
          key
        )
    );
    const keys2 = Object.keys(obj2).filter(
      (key) =>
        !["_id", "customerSuppliedPaint", "customerSuppliedPrimer"].includes(
          key
        )
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (depth === 1 && obj2[key]?._id) {
        if (obj1[key] !== obj2[key]._id) {
          return false;
        }
        continue;
      }

      if (
        depth === 1 &&
        key === "defaultRate" &&
        obj1[key]?._id &&
        obj2[key]?._id
      ) {
        if (obj1[key]._id !== obj2[key]._id) {
          return false;
        }
        continue;
      }
      if (!(key === "defaultRate")) {
        if (
          !keys2.includes(key) ||
          !deepEqual(obj1[key], obj2[key], depth + 1)
        ) {
          console.log(
            "Check if every key-value pair in obj1 matches that in obj2"
          );
          return false;
        }
      }
    }

    return true;
  };

  const deepEqualPaintCheck = (editedObject: any, oldObjectArray: any) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
        paintMaterial: oldObject.paintMaterial
          ? oldObject.paintMaterial._id
          : null,
        primerMaterial: oldObject.primerMaterial
          ? oldObject.primerMaterial._id
          : null,
      };
      if (deepEqual(editedObject, updatedOldObject)) {
        return {
          rate: updatedOldObject,
          result: true,
        };
        // return true;
      }
    }
    return false;
  };

  const deepEqualForClone = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      console.log("equal");
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter((key) => key !== "_id");
    const keys2 = Object.keys(obj2).filter((key) => key !== "_id");

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForPaintClone = (
    editedObject: any,
    oldObjectArray: any
  ) => {
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
        paintMaterial: oldObject.paintMaterial
          ? oldObject.paintMaterial._id
          : null,
        primerMaterial: oldObject.primerMaterial
          ? oldObject.primerMaterial._id
          : null,
      };
      if (deepEqualForClone(editedObject, updatedOldObject)) {
        return {
          rate: updatedOldObject,
          result: true,
        };
      }
    }
    return false;
  };

  const handleWhenEditedRateAlreadyExits = (
    rateThatExits: any,
    editedRate: any
  ) => {
    let reduxObj = [];
    let project: any = _.cloneDeep(items[currentProject]);
    let optionRatesToBeDeletedIdArray = [];
    for (const singleOption of project.options) {
      const foundRateIndex = singleOption.rates.paintRates.findIndex(
        (item: any) => item.projectLaborRate === rateThatExits._id
      );
      const foundEditedRateIndex = singleOption.rates.paintRates.findIndex(
        (item: any) => item.projectLaborRate === editedRate._id
      );
      if (foundEditedRateIndex !== -1) {
        singleOption.rates.paintRates[foundEditedRateIndex].isDeleted = true;
        optionRatesToBeDeletedIdArray.push(
          singleOption.rates.paintRates[foundEditedRateIndex]._id
        );
      }
      if (foundRateIndex === -1) {
        let count = 0;
        let coats = 1;
        let color = "TBD";
        let prepHours = count / rateThatExits.prepLaborRate;
        let primeHours = rateThatExits.primeHours
          ? rateThatExits.primeHours
          : 0;
        let paintHours = (count * coats) / rateThatExits.paintLaborRate;
        let primerGallons = rateThatExits.primerGallons
          ? rateThatExits.primerGallons
          : 0;
        let primeSelect = rateThatExits.primeSelect
          ? rateThatExits.primeSelect
          : "spot";
        let paintGallons = (count * coats) / rateThatExits.paintSpreadRate;
        const projectLaborRate = rateThatExits._id;
        let _id = crypto.randomBytes(12).toString("hex");
        let newObj = {
          ...rateThatExits,
          _id,
          option: singleOption.optionInfo._id,
          projectLaborRate: projectLaborRate,
          count,
          coats,
          color,
          prepHours,
          primeHours,
          paintHours,
          primerGallons,
          primeSelect,
          paintGallons,
          totalPaintHours: prepHours + primeHours + paintHours,
          paintMaterialCost:
            rateThatExits.paintMaterial?.priceAfterTax * paintGallons +
            rateThatExits.primerMaterial?.priceAfterTax * primerGallons,
        };
        reduxObj.push(newObj);
        singleOption.rates.paintRates.push(...reduxObj);
      }
    }
    const editedRateIndexInProject = project.projectRates.paintRates.findIndex(
      (item: any) => item._id === editedRate._id
    );
    project.projectRates.paintRates[editedRateIndexInProject].isDeleted = true;

    // project.projectRates.paintRates.splice(editedRateIndexInProject, 1);
    const body = {
      category: "paint",
      projectRateToBeDeletedId: editedRate._id,
      optionRate: reduxObj,
      optionRatesToBeDeletedIdArray,
      project: id,
    };
    dispatch(
      projectRateDeleteAndOptionRatesUpdate(body, id, project, adminDefaults)
    );
  };

  const newOnFinish = (values: any) => {
    const newRow = { ...surface };
    const oldRateIndex = items[
      currentProject
    ].projectRates.paintRates.findIndex((item: any) => item._id === newRow._id);
    const oldRate = items[currentProject].projectRates.paintRates[oldRateIndex];
    Object.assign(newRow, values);
    if (compareObjects(newRow, oldRate)) {
      props.toggleEditModal(false);
      return;
    }
    let updatedObject = newRow;
    updatedObject.modified = true;
    const notDeletedRates = props.paintRates.filter(
      (item: any) => item.isDeleted === false
    );
    let rateAlreadyExists: any;
    if (updatedObject.isCloned) {
      const isCloned = notDeletedRates.filter(
        (item: any) => item.isCloned === true
      );
      rateAlreadyExists = deepEqualCheckForPaintClone(updatedObject, isCloned);
    } else {
      const isNotCloned = notDeletedRates.filter(
        (item: any) => item.isCloned === false
      );
      const temp = isNotCloned.filter(
        (item: any) => item.defaultRate._id === updatedObject.defaultRate._id
      );
      rateAlreadyExists = deepEqualPaintCheck(updatedObject, temp);
    }

    if (rateAlreadyExists.result) {
      handleWhenEditedRateAlreadyExits(rateAlreadyExists.rate, updatedObject);
      props.toggleEditModal(false);
      // message.error("Rate Already Exists");
    } else {
      onFinish(values);
      props.toggleEditModal(false);
      // let newId = crypto.randomBytes(12).toString("hex");
      // const newRow = { ...surface, _id: newId };
      // const newObject = Object.assign(newRow, values);
      // let updatedObject: any = newObject;
      // updatedObject.modified = true;
      // if (updatedObject.paintMaterial === "customerSupplied") {
      //   updatedObject.customerSuppliedPaint = true;
      //   delete updatedObject.paintMaterial;
      //   updatedObject.customerSuppliedPaint = true;
      // } else {
      //   updatedObject.customerSuppliedPaint = false;
      // }
      // if (updatedObject?.primerMaterial === "customerSupplied") {
      //   updatedObject.customerSuppliedPrimer = true;
      //   delete updatedObject.primerMaterial;
      //   updatedObject.customerSuppliedPrimer = true;
      // } else {
      //   updatedObject.customerSuppliedPrimer = false;
      // }
      // console.log(updatedObject);
      // const defaultPaintRates = adminDefaults.materialDefaults.paints;
      // const defaultPrimerRates = adminDefaults.materialDefaults.primers;
      // const paintRateObject = defaultPaintRates.find(
      //   (element: any) => element._id === updatedObject.paintMaterial
      // );
      // const primerRateObject = defaultPrimerRates.find(
      //   (element: any) => element._id === updatedObject.primerMaterial
      // );
      // setSurface({
      //   ...newObject,
      //   paintMaterial: paintRateObject,
      //   primerMaterial: primerRateObject,
      // });
      // let project: any = _.cloneDeep(items[currentProject]);
      // let reduxUpdatedRate = {
      //   ...newObject,
      //   paintMaterial: paintRateObject,
      //   primerMaterial: primerRateObject,
      // };
      // project.projectRates.paintRates.push({ ...reduxUpdatedRate });
      // let reduxObj = [];
      // for (const singleOption of project.options) {
      //   let count = 0;
      //   let coats = 1;
      //   let color = "TBD";
      //   let prepHours = count / updatedObject.prepLaborRate;
      //   let primeHours = updatedObject.primeHours
      //     ? updatedObject.primeHours
      //     : 0;
      //   let paintHours = (count * coats) / updatedObject.paintLaborRate;
      //   let primerGallons = updatedObject.primerGallons
      //     ? updatedObject.primerGallons
      //     : 0;
      //   let primeSelect = updatedObject.primeSelect
      //     ? updatedObject.primeSelect
      //     : "spot";
      //   let paintGallons = (count * coats) / updatedObject.paintSpreadRate;
      //   const projectLaborRate = updatedObject._id;
      //   let _id = crypto.randomBytes(12).toString("hex");
      //   let newObj = {
      //     ...updatedObject,
      //     _id,
      //     option: singleOption.optionInfo._id,
      //     projectLaborRate: projectLaborRate,
      //     count,
      //     coats,
      //     color,
      //     prepHours,
      //     primeHours,
      //     paintHours,
      //     primerGallons,
      //     primeSelect,
      //     paintGallons,
      //     totalPaintHours: prepHours + primeHours + paintHours,
      //     paintMaterialCost:
      //       updatedObject.paintMaterial?.priceAfterTax * paintGallons +
      //       updatedObject.primerMaterial?.priceAfterTax * primerGallons,
      //   };
      //   reduxObj.push(newObj);
      //   singleOption.rates.paintRates.push(...reduxObj);
      // }
      // const body = {
      //   category: "paint",
      //   projectRate: updatedObject,
      //   optionRate: reduxObj,
      //   project: id,
      // };
      // console.log(body, project);
      // dispatch(
      //   projectAndOptionRatesNewUpdate(body, id, project, adminDefaults)
      // );
    }
  };

  const handlePaintChange = (value: number) => {
    if (surface?.defaultRate) {
      const defaultRate = adminDefaults.defaultRates.paintRates.find(
        (item: any) => {
          if (typeof item.defaultRate === "string") {
            return item._id === surface.defaultRate;
          } else {
            return item._id === surface.defaultRate._id;
          }
        }
      );
      const newData = { ...surface };

      switch (value) {
        case 1:
          newData.paintLevel = 1;
          form.setFieldsValue({
            paintLaborRate: defaultRate?.paintLaborHard,
          });
          break;
        case 2:
          newData.paintLevel = 2;
          form.setFieldsValue({
            paintLaborRate: defaultRate?.paintLaborMedium,
          });
          break;
        case 3:
          newData.paintLevel = 3;
          form.setFieldsValue({
            paintLaborRate: defaultRate?.paintLaborEasy,
          });
          break;
        case 0:
          break;
        default:
          break;
      }

      // props.setDataRates(newData);
      setSurface(newData);
    }
  };

  const handlePrepChange = (value: number) => {
    if (surface?.defaultRate) {
      const defaultRate = adminDefaults.defaultRates.paintRates.find(
        (item: any) => {
          if (typeof item.defaultRate === "string") {
            return item._id === surface.defaultRate;
          } else {
            return item._id === surface.defaultRate._id;
          }
        }
      );
      const newData = { ...surface };

      switch (value) {
        case 1:
          newData.prepLevel = 1;
          form.setFieldsValue({
            prepLaborRate: defaultRate?.prepLaborHard,
          });
          break;
        case 2:
          newData.prepLevel = 2;
          form.setFieldsValue({
            prepLaborRate: defaultRate?.prepLaborMedium,
          });
          break;
        case 3:
          newData.prepLevel = 3;
          form.setFieldsValue({
            prepLaborRate: defaultRate?.prepLaborEasy,
          });
          break;
        case 0:
          break;
        default:
          break;
      }
      setSurface(newData);
      // props.setDataRates(newData);
    }
  };
  const handlePrimeChange = (value: number) => {
    if (surface?.defaultRate) {
      const defaultRate = adminDefaults.defaultRates.paintRates.find(
        (item: any) => {
          if (typeof item.defaultRate === "string") {
            return item._id === surface.defaultRate;
          } else {
            return item._id === surface.defaultRate._id;
          }
        }
      );
      const newData = { ...surface };

      switch (value) {
        case 1:
          newData.primeLevel = 1;
          form.setFieldsValue({
            primeLaborRate: defaultRate?.primeLaborHard,
          });
          break;
        case 2:
          newData.primeLevel = 2;
          form.setFieldsValue({
            primeLaborRate: defaultRate?.primeLaborMedium,
          });
          break;
        case 3:
          newData.primeLevel = 3;
          form.setFieldsValue({
            primeLaborRate: defaultRate?.primeLaborEasy,
          });
          break;
        case 0:
          break;
        default:
          break;
      }

      // props.setDataRates(newData);
      setSurface(newData);
    }
  };

  const handlePaint = (e: any) => {
    let newData = { ...surface };
    newData.paintLaborRate = e.target.value;
    newData.paintLevel = 0;
    form.setFieldsValue({
      paintLaborRate: e.target.value,
      paintLevel: 0,
    });
    // props.setDataRates(newData);
    setSurface(newData);
  };

  const handlePrep = (e: any) => {
    let newData = { ...surface };
    newData.prepLaborRate = e.target.value;
    newData.prepLevel = 0;
    form.setFieldsValue({
      prepLaborRate: e.target.value,
      prepLevel: 0,
    });
    // props.setDataRates(newData);
    setSurface(newData);
  };

  const handlePrime = (e: any) => {
    let newData = { ...surface };
    newData.primeLaborRate = e.target.value;
    newData.primeLevel = 0;
    form.setFieldsValue({
      primeLaborRate: e.target.value,
      primeLevel: 0,
    });
    // props.setDataRates(newData);
    setSurface(newData);
  };

  return (
    <Modal
      className="surface-modal"
      centered={true}
      visible={props.showEditModal}
      onCancel={props.toggleEditModal}
      footer={false}
      title={
        <h1 style={{ marginTop: 10 }} className="send-title-text">
          Edit Paint Rates
        </h1>
      }
    >
      <div className="edit-paint-modal">
        <Form
          form={form}
          onFinish={newOnFinish}
          scrollToFirstError
          layout="vertical"
          style={{ padding: 20 }}
        >
          <Form.Item
            label={<h1>Surface</h1>}
            name="item"
            rules={[{ required: true, message: "Please enter surface!" }]}
          >
            <Input className="edit-paint-modal-input" />
          </Form.Item>

          <Form.Item label={<h1>Type</h1>} name="type">
            <Input disabled className="edit-paint-modal-input" />
          </Form.Item>
          <Row gutter={50}>
            <Col span={12}>
              <Form.Item
                label={<h1>Paint Labor</h1>}
                name="paintLaborRate"
                rules={[
                  { required: true, message: "Please enter paint labor !" },
                ]}
              >
                <Input
                  type="number"
                  step="any"
                  min="0.0"
                  onChange={handlePaint}
                  className="edit-paint-modal-input"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<h1>Paint Difficulty</h1>}>
                <div style={{ marginTop: 10 }} className="set-opacity">
                  <Rate
                    style={{ color: paintColor }}
                    onHoverChange={(level: number) =>
                      handleHover(setPaintColor, level, surface?.paintLevel)
                    }
                    count={3}
                    value={surface?.paintLevel}
                    onChange={handlePaintChange}
                    character={<RectangleIcon />}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={50}>
            <Col span={12}>
              <Form.Item
                label={<h1>Prep Labor</h1>}
                name="prepLaborRate"
                rules={[
                  { required: true, message: "Please enter prep labor!" },
                ]}
              >
                <Input
                  type="number"
                  step="any"
                  min="0.0"
                  className="edit-paint-modal-input"
                  onChange={handlePrep}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<h1>Prep Difficulty</h1>}>
                <div style={{ marginTop: 10 }} className="set-opacity">
                  <Rate
                    style={{ color: prepColor }}
                    onHoverChange={(level: number) =>
                      handleHover(setPrepColor, level, surface?.prepLevel)
                    }
                    count={3}
                    value={surface?.prepLevel}
                    onChange={handlePrepChange}
                    character={<RectangleIcon />}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={50}>
            <Col span={12}>
              <Form.Item
                label={<h1>Prime Labor</h1>}
                name="primeLaborRate"
                rules={[
                  { required: true, message: "Please enter prime labor !" },
                ]}
              >
                <Input
                  type="number"
                  step="any"
                  min="0.0"
                  className="edit-paint-modal-input"
                  onChange={handlePrime}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<h1>Prime Difficulty</h1>}>
                <div style={{ marginTop: 10 }} className="set-opacity">
                  <Rate
                    style={{ color: primeColor }}
                    onHoverChange={(level: number) =>
                      handleHover(setPrimeColor, level, surface?.primeLevel)
                    }
                    count={3}
                    value={surface?.primeLevel}
                    onChange={handlePrimeChange}
                    character={<RectangleIcon />}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={<h1>Paint</h1>}
            name="paintSpreadRate"
            rules={[
              { required: true, message: "Please enter paint spread rate !" },
            ]}
          >
            <Input type="number" className="edit-paint-modal-input" />
          </Form.Item>
          <Form.Item
            label={<h1>Prime</h1>}
            name="primerSpreadRate"
            rules={[
              { required: true, message: "Please enter primer spread rate !" },
            ]}
          >
            <Input type="number" className="edit-paint-modal-input" />
          </Form.Item>
          <Form.Item label={<h1>Paint Material</h1>} name="paintMaterial">
            <Select
              showSearch
              dropdownMatchSelectWidth={false}
              style={{ caretColor: "transparent" }}
              filterOption={(input, option) =>
                (option?.children?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              // onChange={(value: string) => handlePrimeChange(value)}
            >
              {adminDefaults.materialDefaults.paints.map(
                (material: any, index: any) => (
                  <option key={index} value={material["_id"]}>
                    {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                  </option>
                )
              )}
              <option key="customerSupplied" value="customerSupplied">
                Customer Supplied
              </option>
            </Select>
            {/* <Input className="edit-paint-modal-input" /> */}
          </Form.Item>
          <Form.Item label={<h1>Primer Material</h1>} name="primerMaterial">
            <Select
              showSearch
              filterOption={(input, option) =>
                (option?.children?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownMatchSelectWidth={false}
              style={{ caretColor: "transparent" }}
              // onChange={(value: string) => handlePrimeChange(value)}
            >
              {adminDefaults.materialDefaults.primers.map(
                (material: any, index: any) => (
                  <option key={index} value={material["_id"]}>
                    {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                  </option>
                )
              )}
              <option key="customerSupplied" value="customerSupplied">
                Customer Supplied
              </option>
            </Select>
          </Form.Item>

          <Row justify="end">
            <Col>
              <Button
                key="back"
                onClick={props.toggleEditModal}
                className="cancel-btn"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button htmlType="submit" type="primary" className="send-btn">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditPaintRates;
