import { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Select,
  Table,
  Popover,
  Row,
  Col,
  AutoComplete,
  message,
} from "antd";
import "../../containers/OptionDetails/OptionDetails.less";
import ExpandedRow from "./ExpandedRow";
import ActionMenu from "./ActionMenu";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAuthUser } from "../../utils/authToken";
import api from "../../utils/api";
import {
  removeRatesFromOptions,
  addServiceToProject,
  addRatesToOptions,
} from "../../redux/project/action";
import _ from "lodash";
import React from "react";
import { RenderExpandIconProps } from "rc-table/lib/interface";
import CustomExpandIcon from "./CustomExpandIcon";
import { useParams } from "react-router-dom";
import { customToFixed } from "../../utils/helpers";
var crypto = require("crypto");

const { Option } = Select;
type Props = {
  roundGallons?: boolean;
  setPaintState?: any;
  paintRates?: any;
  calculateSqFt?: any;
  setRatesValue?: any;
  handleCheckModifiedOnEdit?: any;
  selectedOption?: any
};

const Painting = (props: Props) => {
  const [countIndex, setCountIndex] = useState<any>();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
  const [count, setCount] = useState<any>();
  const [plusVisible, setPlusVisible] = useState<any>([]);
  const [minusVisible, setMinusVisible] = useState<any>([]);

  const [filteredColorOptions, setFilteredColorOptions] = useState<any>([]);
  const { id } = useParams<{ id: string }>();
  const user = getAuthUser();
  const dispatch = useDispatch<any>();
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const handleExpand = (key: any) => {
    if (expandedRowKeys.includes(key)) {
      setExpandedRowKeys(expandedRowKeys.filter((k: any) => k !== key));
    } else {
      setExpandedRowKeys([...expandedRowKeys, key]);
    }
  };
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      for (let i = 0; i < props.paintRates?.length; i++) {
        calculatePaintGallons(i);
        calculatePrimerGallons(i);
      }
    } else {
      isMounted.current = true;
    }
  }, [props.roundGallons]);

  function onCountChange(e: any, index: any, rowId: string) {
    if (parseInt(e.target.value) >= 0 || e.target.value === "") {
      const newData = [...props.paintRates];
      const rowIndex = newData.findIndex((item: any) => item._id === rowId);
      newData[rowIndex].save = false;
      if (e.target.value === "") {
        newData[rowIndex].count = 0;
      } else {
        newData[rowIndex].count = parseInt(e.target.value);
      }
      // newData[rowIndex].modified = true;
      // props.setPaintState(newData);
      calculatePrepHours(rowIndex, newData);
      if (newData[rowIndex].primeSelect === "full") {
        calculatePrimeHours(rowIndex, newData);
        calculatePrimerGallons(rowIndex, newData);
      }
      calculatePaintGallons(rowIndex, newData);
      calculatePaintHours(rowIndex, newData);
      props.calculateSqFt();
    }
  }

  const colorOptions = adminDefaults.colors.map((color: any) => {
    return {
      label: (
        <Row>
          <div
            style={{
              background: color.hexCode,
              height: 20,
              width: 20,
              marginRight: 10,
            }}
          ></div>
          <div>{color.name}</div>
        </Row>
      ),
      value: color.name,
    };
  });

  const handleAdd = (index: number) => {
    const newData = [...props.paintRates];
    newData[countIndex].save = false;
    if (count) {
      newData[countIndex].count =
        parseInt(newData[countIndex].count) + parseInt(count);
      // newData[countIndex].modified = true;
      props.setPaintState(newData);
      calculatePrepHours(countIndex, newData);
      if (newData[countIndex].primeSelect === "full") {
        calculatePrimeHours(countIndex, newData);
        calculatePrimerGallons(countIndex, newData);
      }
      calculatePaintGallons(countIndex, newData);
      calculatePaintHours(countIndex, newData);
      props.calculateSqFt();
      const prev = [...plusVisible];
      prev[index] = false;
      setPlusVisible(prev);
      setCount("");
    }
  };

  const handlePlusCancel = (index: number) => {
    const prev = [...plusVisible];
    prev[index] = false;
    setPlusVisible(prev);
  };
  const handleMinusCancel = (index: number) => {
    const prev = [...minusVisible];
    prev[index] = false;
    setMinusVisible(prev);
  };
  const handleMinus = (index: number) => {
    if (props.paintRates[countIndex].count - parseInt(count) >= 0) {
      const newData = [...props.paintRates];
      newData[countIndex].save = false;
      newData[countIndex].count = newData[countIndex].count - parseInt(count);
      // newData[countIndex].modified = true;
      // props.setPaintState(newData);
      calculatePrepHours(countIndex, newData);
      if (newData[countIndex].primeSelect === "full") {
        calculatePrimeHours(countIndex, newData);
        calculatePrimerGallons(countIndex, newData);
      }
      calculatePaintGallons(countIndex, newData);
      calculatePaintHours(countIndex, newData);
      const prev = [...minusVisible];
      prev[index] = false;
      setMinusVisible(prev);
      props.calculateSqFt();
    }
    setCount("");
  };

  const handleAddValue = (index: number, rowId: string) => {
    const newData = [...props.paintRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    newData[rowIndex].save = false;
    newData[rowIndex].count =
      newData[rowIndex].count +
      Math.floor(newData[rowIndex].paintLaborRate / 2);
    // newData[rowIndex].modified = true;
    props.setPaintState(newData);
    calculatePrepHours(rowIndex, newData);
    if (newData[rowIndex].primeSelect === "full") {
      calculatePrimeHours(rowIndex, newData);
      calculatePrimerGallons(rowIndex, newData);
    }
    calculatePaintGallons(rowIndex, newData);
    calculatePaintHours(rowIndex, newData);
    props.calculateSqFt();
  };

  const handleSubtractValue = (index: number, rowId: string) => {
    const newData = [...props.paintRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    if (
      props.paintRates[rowIndex].count >=
      Math.floor(props.paintRates[rowIndex].paintLaborRate / 2)
    ) {
      newData[rowIndex].save = false;
      newData[rowIndex].count =
        newData[rowIndex].count -
        Math.floor(newData[rowIndex].paintLaborRate / 2);
      // newData[rowIndex].modified = true;
      // props.setPaintState(newData);
      props.calculateSqFt();
    }
    calculatePrepHours(rowIndex, newData);
    if (newData[rowIndex].primeSelect === "full") {
      calculatePrimeHours(rowIndex, newData);
      calculatePrimerGallons(rowIndex, newData);
    }
    calculatePaintGallons(rowIndex, newData);
    calculatePaintHours(rowIndex, newData);
  };

  const handlePrime = (value: string, index: number, rowId: string) => {
    const newData = [...props.paintRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    newData[rowIndex].save = false;
    newData[rowIndex].primeSelect = value;
    // newData[rowIndex].modified = true;
    // props.setPaintState(newData);
    if (value === "full") {
      calculatePrimeHours(rowIndex, newData);
      calculatePrimerGallons(rowIndex, newData);
    } else if (value === "spot") {
      const newData = [...props.paintRates];
      newData[rowIndex].primeHours = 0;
      newData[rowIndex].primerGallons = 0;
      // newData[rowIndex].modified = true;
      // props.setPaintState(newData);
      calculateTotalPaintHours(rowIndex, newData);
      calculatePaintMaterialCost(rowIndex, newData);
    }
  };
  const handleCoats = (value: number, index: number, rowId: any) => {
    const newData = [...props.paintRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    newData[rowIndex].save = false;
    newData[rowIndex].coats = value;
    // newData[rowIndex].modified = true;
    props.setPaintState(newData);
    calculatePaintHours(rowIndex, newData);
    if (newData[rowIndex].primeSelect === "full") {
      calculatePrimerGallons(rowIndex, newData);
    }
    calculatePaintGallons(rowIndex, newData);
  };

  const handleDifficultyChange = (
    value: number,
    index: number,
    type: string,
    rowId: string
  ) => {
    const newData = _.cloneDeep(props.paintRates);
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    handleModifiedRate(rowIndex);
    if (newData[rowIndex].defaultRate) {
      if (type === "prep") {
        newData[rowIndex].prepLevel = value;
        newData[rowIndex].modified = true;

        if (typeof newData[rowIndex].defaultRate === "string") {
          const defaultRateItem = adminDefaults.defaultRates.paintRates.find(
            (item: any) => item._id === newData[rowIndex].defaultRate
          );

          if (defaultRateItem) {
            switch (value) {
              case 1:
                newData[rowIndex].prepLaborRate = defaultRateItem.prepLaborHard;
                break;
              case 2:
                newData[rowIndex].prepLaborRate =
                  defaultRateItem.prepLaborMedium;
                break;
              case 3:
                newData[rowIndex].prepLaborRate = defaultRateItem.prepLaborEasy;
                break;
              default:
                break;
            }
          }
        } else {
          switch (value) {
            case 1:
              newData[rowIndex].prepLaborRate =
                newData[rowIndex].defaultRate.prepLaborHard;
              break;
            case 2:
              newData[rowIndex].prepLaborRate =
                newData[rowIndex].defaultRate.prepLaborMedium;
              break;
            case 3:
              newData[rowIndex].prepLaborRate =
                newData[rowIndex].defaultRate.prepLaborEasy;
              break;
            default:
              break;
          }
        }

        // props.setPaintState(newData);
        calculatePrepHours(rowIndex, newData);
      } else if (type === "paint") {
        newData[rowIndex].paintLevel = value;
        newData[rowIndex].modified = true;
        if (typeof newData[rowIndex].defaultRate === "string") {
          const defaultRateItem = adminDefaults.defaultRates.paintRates.find(
            (item: any) => item._id === newData[rowIndex].defaultRate
          );
          if (defaultRateItem) {
            switch (value) {
              case 1:
                newData[rowIndex].paintLaborRate =
                  defaultRateItem.paintLaborHard;
                break;
              case 2:
                newData[rowIndex].paintLaborRate =
                  defaultRateItem.paintLaborMedium;
                break;
              case 3:
                newData[rowIndex].paintLaborRate =
                  defaultRateItem.paintLaborEasy;
                break;
              default:
                break;
            }
          }
        } else {
          switch (value) {
            case 1:
              newData[rowIndex].paintLaborRate =
                newData[rowIndex].defaultRate.paintLaborHard;
              break;
            case 2:
              newData[rowIndex].paintLaborRate =
                newData[rowIndex].defaultRate.paintLaborMedium;
              break;
            case 3:
              newData[rowIndex].paintLaborRate =
                newData[rowIndex].defaultRate.paintLaborEasy;
              break;
            default:
              break;
          }
        }

        // props.setPaintState(newData);
        calculatePaintHours(rowIndex, newData);
      } else if (type === "prime") {
        newData[rowIndex].primeLevel = value;
        newData[rowIndex].modified = true;
        if (typeof newData[rowIndex].defaultRate === "string") {
          const defaultRateItem = adminDefaults.defaultRates.paintRates.find(
            (item: any) => item._id === newData[rowIndex].defaultRate
          );

          if (defaultRateItem) {
            switch (value) {
              case 1:
                newData[rowIndex].primeLaborRate =
                  defaultRateItem.primeLaborHard;
                break;
              case 2:
                newData[rowIndex].primeLaborRate =
                  defaultRateItem.primeLaborMedium;
                break;
              case 3:
                newData[rowIndex].primeLaborRate =
                  defaultRateItem.primeLaborEasy;
                break;
              default:
                break;
            }
          }
        } else {
          switch (value) {
            case 1:
              newData[rowIndex].primeLaborRate =
                newData[rowIndex].defaultRate.primeLaborHard;
              break;
            case 2:
              newData[rowIndex].primeLaborRate =
                newData[rowIndex].defaultRate.primeLaborMedium;
              break;
            case 3:
              newData[rowIndex].primeLaborRate =
                newData[rowIndex].defaultRate.primeLaborEasy;
              break;
            default:
              break;
          }
        }

        // props.setPaintState(newData);
        calculatePrimeHours(rowIndex, newData);
      }
    }
  };

  const calculatePrepHours = (index: number, newData: any) => {
    // const newData = [...props.paintRates];

    newData[index].prepHours =
      newData[index].count / newData[index].prepLaborRate;

    newData[index].prepHours = customToFixed(newData[index].prepHours, 2);
    // newData[index].modified = true;

    // props.setPaintState(newData);
    calculateTotalPaintHours(index, newData);
  };

  const calculatePrimeHours = (index: number, newData: any) => {
    // const newData = [...props.paintRates];
    if (newData[index].primeSelect === "full") {
      newData[index].primeHours =
        newData[index].count / newData[index].primeLaborRate;

      newData[index].primeHours = customToFixed(newData[index].primeHours, 2);
      // newData[index].modified = true;

      // props.setPaintState(newData);
      calculateTotalPaintHours(index, newData);
    } else {
      props.setPaintState(newData);
    }
  };

  const calculatePaintHours = (index: number, newData: any) => {
    // const newData = [...props.paintRates];
    let coatsMultiplier: number =
      items[currentProject].projectInfo.coatsMultiplier;
    const newCoats =
      newData[index].coats === 2
        ? 1 + coatsMultiplier / 100
        : newData[index].coats === 3
          ? 1 + coatsMultiplier / 50
          : 1;
    newData[index].paintHours =
      (newData[index].count * newCoats) / newData[index].paintLaborRate;

    newData[index].paintHours = customToFixed(newData[index].paintHours, 2);
    // newData[index].modified = true;

    // props.setPaintState(newData);
    calculateTotalPaintHours(index, newData);
  };

  const calculateTotalPaintHours = (index: number, newData: any) => {
    // const newData = [...props.paintRates];

    newData[index].totalPaintHours =
      (newData[index].prepHours ? parseFloat(newData[index].prepHours) : 0) +
      (newData[index].primeHours ? parseFloat(newData[index].primeHours) : 0) +
      (newData[index].paintHours ? parseFloat(newData[index].paintHours) : 0);
    newData[index].totalPaintHours = customToFixed(
      newData[index].totalPaintHours,
      2
    );
    // newData[index].modified = true;

    props.setPaintState(newData);
  };

  const calculatePrimerGallons = (index: number, newData?: any) => {
    // const newData = [...props.paintRates];
    if (newData === undefined) {
      newData = [...props.paintRates];
    }
    if (newData[index].primeSelect === "full") {
      newData[index].primerGallons =
        newData[index].count / newData[index].primerSpreadRate;
      if (props.roundGallons) {
        newData[index].primerGallons = Math.ceil(newData[index].primerGallons);
      }
      // newData[index].modified = true;
      // props.setPaintState(newData);
      calculatePaintMaterialCost(index, newData);
    } else {
      props.setPaintState(newData);
    }
  };

  const calculatePaintGallons = (index: number, newData?: any) => {
    if (newData === undefined) {
      newData = [...props.paintRates];
    }
    let coatsMultiplier: number =
      items[currentProject].projectInfo.coatsMultiplier;
    const newCoats =
      newData[index].coats === 2
        ? 1 + coatsMultiplier / 100
        : newData[index].coats === 3
          ? 1 + coatsMultiplier / 50
          : 1;
    newData[index].paintGallons =
      (newCoats * newData[index].count) / newData[index].paintSpreadRate;
    if (props.roundGallons) {
      newData[index].paintGallons = Math.ceil(newData[index].paintGallons);
    }
    // newData[index].modified = true;
    // props.setPaintState(newData);
    calculatePaintMaterialCost(index, newData);
  };

  const calculatePaintMaterialCost = (index: number, newData: any) => {
    // const newData = [...props.paintRates];

    let updatedPaintMaterialPrice = findMaterial(newData[index].paintMaterial);
    let updatedPrimerMaterialPrice = findMaterial(
      newData[index].primerMaterial
    );

    newData[index].paintMaterialCost =
      (newData[index].paintGallons ? newData[index].paintGallons : 0) *
      (newData[index].customerSuppliedPaint
        ? 0
        : updatedPaintMaterialPrice
          ? updatedPaintMaterialPrice
          : newData[index].paintMaterial
            ? newData[index].paintMaterial.priceAfterTax
            : 0) +
      (newData[index].customerSuppliedPrimer
        ? 0
        : updatedPrimerMaterialPrice
          ? (newData[index].primerGallons ? newData[index].primerGallons : 0) *
          updatedPrimerMaterialPrice
          : (newData[index].primerGallons ? newData[index].primerGallons : 0) *
          (newData[index].primerMaterial
            ? newData[index].primerMaterial.priceAfterTax
            : 0));

    newData[index].paintMaterialCost = customToFixed(
      newData[index].paintMaterialCost,
      2
    );
    // newData[index].modified = true;

    props.setPaintState(newData);
  };

  const handleInputsChange = (e: any, index: number, rowId: string) => {
    // const newData = [...props.paintRates];
    const newData = _.cloneDeep(props.paintRates);
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    handleModifiedRate(rowIndex);

    switch (e.target.name) {
      case "primeLaborRates":
        newData[rowIndex].primeLaborRate = e.target.value
          ? parseFloat(e.target.value)
          : "";
        newData[rowIndex].primeLevel = 0;
        newData[rowIndex].modified = true;
        // props.setPaintState(newData);
        if (newData[rowIndex].primeSelect === "full") {
          calculatePrimeHours(rowIndex, newData);
        } else {
          props.setPaintState(newData);
        }

        break;
      case "paintLaborRates":
        newData[rowIndex].paintLaborRate = e.target.value
          ? parseFloat(e.target.value)
          : "";
        newData[rowIndex].paintLevel = 0;
        newData[rowIndex].modified = true;
        // props.setPaintState(newData);
        calculatePaintHours(rowIndex, newData);
        break;
      case "prepLaborRates":
        newData[rowIndex].prepLaborRate = e.target.value
          ? parseFloat(e.target.value)
          : "";
        newData[rowIndex].prepLevel = 0;
        newData[rowIndex].modified = true;
        // props.setPaintState(newData);
        calculatePrepHours(rowIndex, newData);
        break;
      case "paintSpreadRates":
        newData[rowIndex].paintSpreadRate = e.target.value
          ? parseFloat(e.target.value)
          : "";
        newData[rowIndex].modified = true;
        // props.setPaintState(newData);
        calculatePaintGallons(rowIndex, newData);
        break;
      case "primeSpreadRates":
        newData[rowIndex].primerSpreadRate = e.target.value
          ? parseFloat(e.target.value)
          : "";
        newData[rowIndex].modified = true;
        // props.setPaintState(newData);
        if (newData[rowIndex].primeSelect === "full") {
          calculatePrimerGallons(rowIndex, newData);
        } else {
          props.setPaintState(newData);
        }

        break;
      case "paintPG":
        newData[rowIndex].paintPricePerGallon = e.target.value
          ? parseFloat(e.target.value)
          : "";
        // newData[rowIndex].modified = true;
        // props.setPaintState(newData);
        calculatePaintMaterialCost(rowIndex, newData);
        break;
      case "primerPG":
        newData[rowIndex].primerPricePerGallon = e.target.value
          ? parseFloat(e.target.value)
          : "";
        // newData[rowIndex].modified = true;
        // props.setPaintState(newData);
        calculatePaintMaterialCost(rowIndex, newData);
        break;
      default:
        break;
    }
  };

  const handleDelete = (id: string) => {
    let project = _.cloneDeep(items[currentProject]);
    const newData = [...props.paintRates];
    const optionRate = newData.find((item: any) => item._id === id);
    let optionIndex = project.options.findIndex((option: any) => optionRate.option === option.optionInfo._id)
    project.options[optionIndex].rates.paintRates = _.cloneDeep(props.paintRates);
    project.options[optionIndex].optionInfo = props.selectedOption;
    for (const singleOption of project.options) {
      if (singleOption.optionInfo._id === optionRate.option) {
        const optionRateIndex = singleOption.rates.paintRates.findIndex(
          (item: any) => item._id === id
        );
        singleOption.rates.paintRates[optionRateIndex].isDeleted = true;
        // singleOption.rates.paintRates.splice(optionRateIndex, 1);
      }
    }
    let body = {
      category: "paint",
      id: id,
    };
    dispatch(removeRatesFromOptions(body, project, adminDefaults));
    const index = newData.findIndex((item: any) => item._id === id);
    newData[index].isDeleted = true;
    // newData.splice(index, 1);
    props.setPaintState(newData);
    props.setRatesValue({ paintRates: newData });
  };

  const handleCountChange = (e: any) => {
    if (/^\d*\.?\d*$/.test(e.target.value) && !e.target.value.includes("-")) {
      if (e.target.value >= 0) {
        setCount(e.target.value);
      }
    }
  };

  const handleAddKeyDown = (e: any, index: number) => {
    if (e.key === "Enter") {
      handleAdd(index);
    }
  };

  const handleMinusKeyDown = (e: any, index: number) => {
    if (e.key === "Enter") {
      handleMinus(index);
    }
  };

  const handlePaintMaterialChange = (value: any, row: any) => {
    const newData = [...props.paintRates];
    const paintRateIndex = newData.findIndex(
      (item: any) => item._id === row._id
    );
    if (value === "customerSupplied") {
      newData[paintRateIndex].customerSuppliedPaint = true;
    } else {
      let paintMaterial = adminDefaults.materialDefaults.paints.find(
        (item: any) => item._id === value
      );
      newData[paintRateIndex].paintMaterial = paintMaterial;
      newData[paintRateIndex].customerSuppliedPaint = false;
    }
    // newData[index].save = false;
    handleModifiedRate(paintRateIndex);
    newData[paintRateIndex].modified = true;
    // props.setPaintState(newData);
    calculatePaintMaterialCost(paintRateIndex, newData);
  };

  const handlePrimerMaterialChange = (value: any, row: any) => {
    const newData = [...props.paintRates];
    const paintRateIndex = newData.findIndex(
      (item: any) => item._id === row._id
    );

    if (value === "customerSupplied") {
      newData[paintRateIndex].customerSuppliedPrimer = true;
    } else {
      let primerMaterial = adminDefaults.materialDefaults.primers.find(
        (item: any) => item._id === value
      );
      newData[paintRateIndex].primerMaterial = primerMaterial;
      newData[paintRateIndex].customerSuppliedPrimer = false;
    }

    // newData[index].save = false;
    handleModifiedRate(paintRateIndex);
    newData[paintRateIndex].modified = true;
    // props.setPaintState(newData);
    calculatePaintMaterialCost(paintRateIndex, newData);
  };

  const findMaterial = (material: any) => {
    const findFromProject = items[currentProject]?.projectMaterials?.find(
      (item: any) => item?.defaultMaterialId === material?._id
    );
    if (findFromProject) {
      return findFromProject.price;
    }
    return material?.priceAfterTax;
  };

  const findColor = (data: any) => {
    let response = adminDefaults.colors.find(
      (item: any) => item.name === data.color
    );
    return response;
  };

  const handleColor = (index: any, e: any, rateId: string) => {
    // return (e: any) => {
    const newData = [...props.paintRates];
    let rateIndex = newData.findIndex((x) => x._id === rateId);
    // newData[index].save = false;
    newData[rateIndex].color = e;
    // newData[index].modified = true;
    props.setPaintState(newData);
    // if (newData[index].primeSelect === "full") {
    //   calculatePrimerGallons(index);
    // }
    // calculatePaintGallons(index);
    // };
  };
  const handleColorOptions = (index: any, e: any, rateId: string) => {
    if (e.length > 3) {
      let newOptions = adminDefaults?.colors.filter((item: any) =>
        item.name.toLowerCase().includes(e.toLowerCase())
      );

      setFilteredColorOptions(
        newOptions.map((color: any) => {
          return {
            label: (
              <Row>
                <div
                  style={{
                    background: color.hexCode,
                    height: 20,
                    width: 20,
                    marginRight: 10,
                  }}
                ></div>
                <div>{color.name}</div>
              </Row>
            ),
            value: color.name,
          };
        })
      );
    } else {
      setFilteredColorOptions([]);
    }
    handleColor(index, e, rateId);
  };

  const content = (index: number) => (
    <div style={{ width: 150 }}>
      <Input
        type="number"
        value={count}
        onChange={handleCountChange}
        onKeyDown={(e) => handleAddKeyDown(e, index)}
      />
      <Row justify="space-between">
        <Col>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={() => handleAdd(index)}
          >
            Ok
          </Button>
        </Col>
        <Col>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => handlePlusCancel(index)}
          >
            Close
          </Button>
        </Col>
      </Row>
    </div>
  );
  const minusContent = (index: number) => (
    <div style={{ width: 150 }}>
      <Input
        type="number"
        value={count}
        onChange={handleCountChange}
        onKeyDown={(e) => handleMinusKeyDown(e, index)}
      />
      <Row justify="space-between">
        <Col>
          <Button
            style={{ marginTop: 10 }}
            type="primary"
            onClick={() => handleMinus(index)}
          >
            Ok
          </Button>
        </Col>
        <Col>
          <Button
            style={{ marginTop: 10 }}
            onClick={() => handleMinusCancel(index)}
          >
            Close
          </Button>
        </Col>
      </Row>
    </div>
  );

  const handlePlusVisibleChange = (visibleState: any, index: any) => {
    const prev = [...plusVisible];
    prev[index] = visibleState;
    setPlusVisible(prev);
  };
  const handleMinusVisibleChange = (visibleState: any, index: any) => {
    const prev = [...minusVisible];
    prev[index] = visibleState;
    setMinusVisible(prev);
  };
  const handlePlusIndexState = (_: any, index: any, rowId: string) => {
    const newData = [...props.paintRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    setCountIndex(rowIndex);
    const prev = [...plusVisible];
    prev[rowIndex] = true;
    setPlusVisible(prev);
  };
  const handleMinusIndexState = (_: any, index: any, rowId: string) => {
    const newData = [...props.paintRates];
    const rowIndex = newData.findIndex((item: any) => item._id === rowId);
    setCountIndex(rowIndex);
    const prev = [...minusVisible];
    prev[rowIndex] = true;
    setMinusVisible(prev);
  };

  const deepEqualForClone = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    if (obj1 === obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["_id", "projectLaborRate"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForClone = (editedObject: any, oldObjectArray: any) => {
    const updatedEditedObject = {
      ...editedObject,
      paintMaterial: editedObject.paintMaterial
        ? editedObject.paintMaterial._id
        : null,
      primerMaterial: editedObject.primerMaterial
        ? editedObject.primerMaterial._id
        : null,
    };
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
        paintMaterial: oldObject.paintMaterial
          ? oldObject.paintMaterial._id
          : null,
        primerMaterial: oldObject.primerMaterial
          ? oldObject.primerMaterial._id
          : null,
      };
      if (deepEqualForClone(updatedEditedObject, updatedOldObject)) {
        return true;
      }
    }
    return false;
  };

  const deepEqualForCloneInProjectRates = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    if (obj1 === obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter(
      (key) => !["_id", "item"].includes(key)
    );
    const keys2 = Object.keys(obj2).filter(
      (key) => !["_id", "item"].includes(key)
    );

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2"
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForCloneInProjectRates = (
    editedObject: any,
    oldObjectArray: any
  ) => {
    const updatedEditedObject = {
      ...editedObject,
      paintMaterial: editedObject.paintMaterial
        ? editedObject.paintMaterial._id
        : null,
      primerMaterial: editedObject.primerMaterial
        ? editedObject.primerMaterial._id
        : null,
    };
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
        paintMaterial: oldObject.paintMaterial
          ? oldObject.paintMaterial._id
          : null,
        primerMaterial: oldObject.primerMaterial
          ? oldObject.primerMaterial._id
          : null,
      };
      if (
        deepEqualForCloneInProjectRates(updatedEditedObject, updatedOldObject)
      ) {
        return {
          rate: updatedOldObject,
          result: true,
        };
      }
    }
    return {
      rate: editedObject,
      result: false,
    };
  };

  const handlePaintClone = (
    materialToClone: any,
    index: number,
    surfaceName: string
  ) => {
    const result = props.paintRates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(" ", "") === surfaceName[index].replaceAll(" ", "")) {
        return true;
      }
    });
    if (result === true) {
      message.error("Name already taken");
    } else {
      let projectLaborRateId = crypto.randomBytes(12).toString("hex");
      const newPaintData = _.cloneDeep(materialToClone);
      const newRates = {
        ...newPaintData,
        count: 0,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        projectLaborRate: projectLaborRateId,
        _id: crypto.randomBytes(12).toString("hex"),
        isCloned: true,
      };

      const notDeletedRates = props.paintRates.filter(
        (item: any) => item.isDeleted === false
      );

      const isClonedArray = notDeletedRates.filter(
        (item: any) => item.isCloned === true
      );

      const rateAlreadyExists = deepEqualCheckForClone(newRates, isClonedArray);
      if (rateAlreadyExists) {
        message.error("Rate Already Exists");
      } else {
        let project = _.cloneDeep(items[currentProject]);
        let selectedOptionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[selectedOptionIndex].optionInfo = props.selectedOption;
        project.options[selectedOptionIndex].rates.paintRates = props.paintRates;

        let newProjectRates = [];
        let projectPaintRate = {
          item: newRates.item,
          paintLaborRate: newRates.paintLaborRate,
          paintLevel: newRates.paintLevel,
          paintMaterial: newRates.paintMaterial,
          paintSpreadRate: newRates.paintSpreadRate,
          prepLaborRate: newRates.prepLaborRate,
          prepLevel: newRates.prepLevel,
          primeLaborRate: newRates.primeLaborRate,
          primeLevel: newRates.primeLevel,
          primerMaterial: newRates.primerMaterial,
          primerSpreadRate: newRates.primerSpreadRate,
          project: newRates.project,
          type: newRates.type,
          _id: projectLaborRateId,
          isCloned: true,
          isDeleted: newRates.isDeleted,
          modified: newRates.modified,
          defaultRate: newRates.defaultRate,
        };

        const notDeletedProjectRates = project.projectRates.paintRates.filter(
          (item: any) => item.isDeleted === false
        );

        const isClonedProjectRatesArray = notDeletedProjectRates.filter(
          (item: any) => item.isCloned === true
        );

        const rateAlreadyExistsInProject = deepEqualCheckForCloneInProjectRates(
          newRates,
          isClonedProjectRatesArray
        );

        if (rateAlreadyExistsInProject.result) {
          const foundedRate = rateAlreadyExistsInProject.rate;
          newRates.projectLaborRate = foundedRate._id;
        } else {
          project.projectRates.paintRates.push(projectPaintRate);
          newProjectRates.push(projectPaintRate);
        }

        let optionIndex = project.options.findIndex(
          (item: any) => item.optionInfo._id === newRates.option
        );
        project.options[optionIndex].rates.paintRates.push(newRates);

        const body = [
          {
            category: "paint",
            rate: [newRates],
          },
        ];

        const newData = _.cloneDeep(props.paintRates);
        newData.push({ ...newRates });

        props.setPaintState(newData);

        if (newProjectRates.length !== 0) {
          let newBodyForProjectRates = [
            {
              category: "paint",
              rate: newProjectRates,
            },
          ];
          dispatch(
            addServiceToProject(
              items[currentProject].projectId,
              newBodyForProjectRates
            )
          );
        }
        dispatch(addRatesToOptions(body, project, adminDefaults));
      }
    }
  };

  const handleModifiedRate = (index: number) => {
    const newData = [...props.paintRates];
    props.handleCheckModifiedOnEdit(newData[index]);
  };

  const optionPaintColumns = [
    {
      title: "Surface / Count",
      width: "40%",
      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="text--with--border">{data.item}</div>
            <Popover
              visible={minusVisible[index]}
              onVisibleChange={(visible: any) =>
                handleMinusVisibleChange(visible, index)
              }
              content={minusContent(index)}
              trigger="click"
            >
              <div
                onClick={(e: any) => handleMinusIndexState(e, index, data._id)}
                className="minus--icon--holder"
              >
                <span>-</span>
              </div>
            </Popover>

            <div
              onClick={() => handleSubtractValue(index, data._id)}
              className="qty--icon--holder"
            >
              <span>{Math.floor(data.paintLaborRate / 2)}</span>
            </div>

            <div className="qty--text--field">
              <Input
                onChange={(e: any) => onCountChange(e, index, data._id)}
                value={data.count}
              />
            </div>

            <div
              onClick={() => handleAddValue(index, data._id)}
              className="qty--icon--holder"
            >
              <span>{Math.floor(data.paintLaborRate / 2)}</span>
            </div>
            <Popover
              visible={plusVisible[index]}
              onVisibleChange={(visible: any) =>
                handlePlusVisibleChange(visible, index)
              }
              content={content(index)}
              trigger="click"
            >
              <div
                onClick={(e: any) => handlePlusIndexState(e, index, data._id)}
                className="minus--icon--holder"
              >
                <span>+</span>
              </div>
            </Popover>
          </div>
        );
      },
    },

    {
      title: "Prime",

      width: "5%",
      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder option-detail-table-exp"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Select
              onChange={(value: string) => handlePrime(value, index, data._id)}
              style={{ width: "71px" }}
              value={data.primeSelect}
            >
              <Option value="spot">Spot</Option>
              <Option value="full">Full</Option>
            </Select>
          </div>
        );
      },
    },
    {
      title: "Coats",

      width: "5%",
      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder option-detail-table-exp"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Select
              onChange={(value: number) => handleCoats(value, index, data._id)}
              style={{ width: "51px" }}
              value={data.coats || 1}
            >
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
            </Select>
            {/* <Input onChange={handleCoats(index)} value={data.coats} /> */}
          </div>
        );
      },
    },
    {
      title: (
        <>
          <Row>
            <Col>
              <div
                style={{
                  height: 30,
                  width: 30,
                  // marginTop:8,
                  marginRight: 5,
                }}
              />
            </Col>
            <Col>Color</Col>
          </Row>
        </>
      ),

      width: "35%",
      render: (data: any, _: any, index: number) => {
        // let adminColor = findColor(data);
        let adminColor = adminDefaults.colors.find(
          (item: any) => item.name === data.color
        );
        return (
          // <divOat Milk (FSW9501)l-table-exp"
          //   style={{ display: "flex", flexDirection: "row" }}
          // >
          <>
            <Row style={{ flexFlow: "row" }}>
              <Col>
                <div
                  style={{
                    background: adminColor?.hexCode,
                    height: 32,
                    width: 30,
                    marginRight: 5,
                  }}
                ></div>
              </Col>
              <Col style={{ width: "80%" }}>
                <AutoComplete
                  options={filteredColorOptions}
                  // onSelect={(e: any) => handleColor(index, e)}
                  onChange={(e: any) => handleColorOptions(index, e, data._id)}
                  // onBlur={(e: any) => handleColor(index, e)}
                  defaultValue={data.color}
                  style={{ width: "100%" }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 320 }}
                // onSearch={(e: any) => handleColorOptions(index, e)}
                // filterOption={(input, option) => {
                //   return (option?.value?.toString() ?? "")
                //     .toLowerCase()
                //     .includes(input.toLowerCase());
                // }}
                />
              </Col>
            </Row>
          </>

          // {/* </div> */}
        );
      },
    },

    {
      title: "Action",

      width: "5%",
      render: (data: any, _: any, index: number) => {
        return (
          <div
            className="table--editor--holder"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <ActionMenu
              handleRowExpand={handleExpand}
              handleClone={handlePaintClone}
              handleDelete={handleDelete}
              data={data}
              index={index}
              category="paint"
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {props.paintRates?.length > 0 && (
        <Table
          scroll={{ x: 900 }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          pagination={false}
          columns={optionPaintColumns}
          expandedRowKeys={expandedRowKeys}
          expandIcon={(props) => CustomExpandIcon(props)}
          onExpandedRowsChange={setExpandedRowKeys}
          expandable={{
            expandedRowRender: (record, index) => (
              <ExpandedRow
                record={record}
                index={index}
                handleDifficultyChange={handleDifficultyChange}
                handleInputsChange={handleInputsChange}
                handlePaintMaterialChange={handlePaintMaterialChange}
                handlePrimerMaterialChange={handlePrimerMaterialChange}
              />
            ),
          }}
          dataSource={props.paintRates
            ?.filter((item: any) => !item.isDeleted)
            .sort((a: any, b: any) => a.item.localeCompare(b.item))}
        />
      )}
    </>
  );
};

export default React.memo(Painting);
