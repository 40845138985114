import React, { Fragment } from "react";
import { routes } from "./config/config";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import "./App.less"; // or 'antd/dist/antd.less's
import Login from "./containers/login-signup/Login";
import SearchAccount from "./containers/login-signup/SearchAccount";
import PageNotFound from "./pages/PageNotFound";
import PrivateRouter from "./utils/privateRouter";
import DashboardLayout from "./containers/layout/DashboardLayout";
import { verifyRole } from "./utils/helpers";
import { RootStateOrAny, useSelector } from "react-redux";
import ErrorBannerModal from "./components/error-banner-modal/ErrorBannerModal";
import ResetPassword from "./containers/login-signup/ResetPassword";

// const PrivateApp = ({ user }: any) => (
//   <DashboardLayout>
//     {routes.map(({ Component, path, exact, roles }, index) => {
//       if (roles && verifyRole(user.roles, roles)) {
//         console.log(user);
//         return (
//           <Route key={path} path={path} exact={exact} component={Component} />
//         );
//       }
//       return <Fragment key={`path${index}`} />;
//     })}
//   </DashboardLayout>
// );

const PrivateApp = () => {
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const history = useHistory();

  if (!user || !user.accessToken) {
    history.push("/login");
  }

  return (
    <DashboardLayout>
      {routes.map(({ Component, path, exact, roles }, index) => {
        if (roles && verifyRole(user.roles, roles)) {
          return (
            <Route key={path} path={path} exact={exact} component={Component} />
          );
        }
        return <Fragment key={`path${index}`} />;
      })}
    </DashboardLayout>
  );
};

const App: React.FC = () => {
  const { error } = useSelector((state: RootStateOrAny) => state.offlineData);
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  return (
    <React.Fragment>
      {user && error?.length > 0 && <ErrorBannerModal error={error} />}

      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/search-email" component={SearchAccount} />
          <Route exact path="/reset-password/:verficationkey" component={ResetPassword}/>
          <Route exact path="/page-not-found" component={PageNotFound} />
          <PrivateRouter path="/" component={PrivateApp} />
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;
