import React from "react";
import ContractDefaultContainer from "../../containers/contract-default-container/ContractDefaultContainer";

function ContactDefault() {
  return (
    <div>
      <ContractDefaultContainer />
    </div>
  );
}

export default ContactDefault;
