import { useEffect, useState } from "react";
import api from "../../utils/api";
import { Button, Col, Dropdown, Menu, Row, Spin, Table } from "antd";
import { MoreActionIcon } from "../../utils/icons";
import AddUpdateEstimatorChecklistModal from "../../components/pop-ups/AddUpdateEstimatorChecklistModal";


interface EstimatorChecklist {
 _id?: string;
 description: string;
 isDeleted: boolean;
}
const EstimatorChecklistContainer = () => {
 const [loading, setLoading] = useState(false);
 const [estimatorChecklist, setEstimatorChecklist] = useState<
   EstimatorChecklist[]
 >([]);
 const [isModalVisible, setIsModalVisible] = useState(false);
 const [editingEstimatorChecklist, setEditingEstimatorChecklist] =
   useState<EstimatorChecklist | null>(null);


 useEffect(() => {
   api
     .get("estimator-checklist")
     .then((resp: any) => {
       console.log(resp);
       setEstimatorChecklist(resp);
       setLoading(false);
     })
     .catch(() => {
       setLoading(false);
     });
 }, []);


 const showModal = (estimatorChecklist: EstimatorChecklist | null = null) => {
   setEditingEstimatorChecklist(estimatorChecklist);
   setIsModalVisible(true);
 };


 const handleCancel = () => {
   setIsModalVisible(false);
   setEditingEstimatorChecklist(null);
 };


 const handleDelete = (id: string) => {
   api
     .delete(`estimator-checklist/${id}`)
     .then((resp: any) => {
       console.log("Estimator Checklist Deleted:", resp);


       // Remove the deleted item from the state
       setEstimatorChecklist((prevEstimatorChecklist) =>
         prevEstimatorChecklist.filter(
           (estimatorChecklist) => estimatorChecklist._id !== id
         )
       );
     })
     .catch((error: any) => {
       console.error("Error deleting Estimator Checklist:", error);
     });
 };


 const handleOk = (updatedEstimatorChecklist: EstimatorChecklist) => {
   if (editingEstimatorChecklist) {
     // Handle update logic here
     console.log("Updated Estimator Checklist:", updatedEstimatorChecklist);
     api
       .patch(
         `estimator-checklist/${updatedEstimatorChecklist._id}`,
         updatedEstimatorChecklist
       )
       .then((resp: any) => {
         console.log("Estimator Checklist Updated:", resp);
         // Update the Estimator Checklist in the state
         setEstimatorChecklist((prevEstimatorChecklist) =>
           prevEstimatorChecklist.map((estimatorChecklist) =>
             estimatorChecklist._id === updatedEstimatorChecklist._id
               ? resp
               : estimatorChecklist
           )
         );
       })
       .catch((error: any) => {
         console.error("Error updating Estimator Checklist:", error);
       });
   } else {
     // Handle add logic here
     console.log("New Estimator Checklist:", updatedEstimatorChecklist);
     api
       .post("estimator-checklist", updatedEstimatorChecklist)
       .then((resp: any) => {
         console.log("Estimator Checklist Created:", resp);
         setEstimatorChecklist((prevEstimatorChecklist) => [
           ...prevEstimatorChecklist,
           resp,
         ]);
       })
       .catch((error: any) => {
         console.error("Error creating Estimator Checklist:", error);
       });
   }
   setIsModalVisible(false);
 };


 const tableColumns = [
   {
     title: "Description",
     dataIndex: "description",
     key: "description",
     sorter: (a: EstimatorChecklist, b: EstimatorChecklist) =>
       a.description.localeCompare(b.description),
   },
   {
     title: "Action",
     width: 100,
     render: (record: EstimatorChecklist) => {
       return (
         <Dropdown
           overlay={
             <Menu>
               <Menu.Item
                 key="edit"
                 style={{ fontWeight: "bold" }}
                 onClick={() => showModal(record)}
               >
                 Edit
               </Menu.Item>
               <Menu.Item
                 key="delete"
                 style={{ fontWeight: "bold" }}
                 onClick={() => {
                   if (record._id) {
                     handleDelete(record._id);
                   }
                 }}
               >
                 Delete
               </Menu.Item>
             </Menu>
           }
           trigger={["click"]}
         >
           <div style={{ cursor: "pointer" }}>
             <MoreActionIcon />
           </div>
         </Dropdown>
       );
     },
   },
 ];


 return (
   <>
     {loading ? (
       <Row style={{ padding: "80px 0px" }} justify="center">
         <Spin size="large" />
       </Row>
     ) : (
       <>
         <AddUpdateEstimatorChecklistModal
           visible={isModalVisible}
           onCancel={handleCancel}
           onOk={handleOk}
           estimatorChecklistData={editingEstimatorChecklist || undefined}
         />
         <div>
           <Row
             className="contract-default-header"
             align="middle"
             justify="space-between"
           >
             <Col>
               <h1 style={{ paddingTop: "8px" }}>
                 Estimator Checklist Defaults
               </h1>
             </Col>
             <Col>
               <Button
                 className="contract-add"
                 type="primary"
                 onClick={() => showModal()}
               >
                 + Add
               </Button>
             </Col>
           </Row>
           {estimatorChecklist.length > 0 && (
             <Table
               scroll={{ x: "900px" }}
               rowKey="_id"
               columns={tableColumns}
               dataSource={estimatorChecklist}
               style={{ backgroundColor: "white" }}
               
             />
           )}
         </div>
       </>
     )}
   </>
 );
};


export default EstimatorChecklistContainer;
