import { Modal, Button, Upload, Row, Col, message } from "antd";
import "./AddItemDefaultModal.less";
import { API_BASE } from "../../../constant";
import { getAuthUser } from "../../../utils/authToken";
const { Dragger } = Upload;
type Props = {
  buttonDisable?: boolean;
  showBulkModal: boolean;
  toggleBulkModal: () => void;
  url?: string;
  handleAddCsv?: (info: any) => void;
};

const DefaultBulkEntry = (props: Props) => {
  const user = getAuthUser();

  const handleBeforeUpload = (file: File) => {
    const isCSV = file.type === "text/csv";
    if (!isCSV) {
      message.error("You can only upload CSV file!");
      return Upload.LIST_IGNORE;
    }
    return isCSV;
  };

  return (
    <div>
      <Modal
        className="default-modal-rt"
        centered={true}
        visible={props.showBulkModal}
        onOk={props.toggleBulkModal}
        onCancel={props.toggleBulkModal}
        footer={[
          <div className="send-btn-container">
            <Button
              key="back"
              onClick={props.toggleBulkModal}
              className="default-material-cancel-btn"
            >
              Cancel
            </Button>
          </div>,
        ]}
        title={
          <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
            Add item
          </h1>
        }
      >
        <div className="bulk-default-dragger">
          <Dragger
            action={`${API_BASE}${props.url}`}
            headers={{ Authorization: "Bearer " + user.accessToken }}
            onChange={props.handleAddCsv}
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
            disabled={props.buttonDisable}
          >
            <div style={{ padding: 20 }}>
              <Row justify="center">
                <Col>
                  <Button
                    disabled={props.buttonDisable}
                    className="default-bulk-btn-upload"
                    type="primary"
                  >
                    Upload data from File
                  </Button>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <p>.csv spreadsheet accepted</p>
                </Col>
              </Row>
              <Row justify="center">
                <Col>
                  <h3>
                    You can upload any .csv file if it has the format of
                    _______. You will be able to remove or cleanup any data
                    before the report is finalized.
                  </h3>
                </Col>
              </Row>
            </div>
          </Dragger>
        </div>
      </Modal>
    </div>
  );
};

export default DefaultBulkEntry;
