import React, { useRef, useEffect, useState } from "react";
import { CameraOutlined } from "@ant-design/icons";
import { RootStateOrAny, useSelector } from 'react-redux';
import { Button, message } from 'antd';
import axios from 'axios';
import { API_BASE } from '../../constant';
import { getAuthUser } from '../../utils/authToken';
import { useParams } from 'react-router-dom';

interface ImageListProps {
  fileList: any[]
  setFileList: any
}

const Camera = (props: ImageListProps) => {
  const user = getAuthUser()
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const { id } = useParams<{ id: string }>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (fileInputRef.current) {
      const supportsCameraCapture = 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices;
      const captureValue: any = supportsCameraCapture ? 'camera' : undefined;

      fileInputRef.current.setAttribute('capture', captureValue);
    }
  }, []);

  const openFileBrowser = () => {
    fileInputRef.current?.click();

  };

  const handleFileChange = (event: any) => { //e: React.ChangeEvent<HTMLInputElement>
    const selectedFile = event.target.files[0];
    // const selectedFile = e.target.files?.[0];
    if (event.target.files[0] || items[currentProject]?.projectId && selectedFile.size <= 50000000) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        const thumbUrl = reader.result
        const image = {
          name: selectedFile.name,
          thumbUrl,
          file: selectedFile,
          option: id
        }
        handleUpload(image)
      }
    } else {
      message.error("Max File Size Allowed is 50MB. Try Another file!");
    }
  };

  const handleUpload = async (values: any) => {
    if (online) {
      const formData = new FormData();
      formData.append('file', values.file)
      formData.append("option", values.option)
      setLoading(true)
      await axios
        .post(`${API_BASE}upload-files/options-image/${items[currentProject]?.projectId}`, formData, {
          headers: { Authorization: "Bearer " + user.accessToken },
        })
        .then((response: any) => {
          if (response.data) {
            const newImage = {
              uid: response.data._id,
              name: response.data.fileName,
              addToOption: response.data.addToOption,
              thumbUrl: values.thumbUrl,
            }
            props.setFileList([newImage, ...props.fileList])
            message.success("File uploaded sucessfully")
            setLoading(false)
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Button
        icon={<CameraOutlined />}
        style={{
          margin: 5,
          background: "#FDB913",
          border: "1px solid #FDB913",
          color: "black"
        }}
        loading={loading}
        onClick={openFileBrowser}
      />
      <input
        ref={fileInputRef}
        accept="image/*"
        type="file"
        capture="environment"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
    </>
  );
}

export default Camera;
