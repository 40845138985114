import React, { useEffect, useState } from "react";
import "../../components/proposal/Proposal.less";
import Building from "../../components/proposal/Building";
import BuildingTasks from "./BuildingTasks";
import { useSelector, RootStateOrAny } from "react-redux";
import { Divider } from "antd";
import { useMediaQuery } from "react-responsive";

const ProjectDetails: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const [options, setOptions] = useState<any>();

  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  useEffect(() => {
    let newOptions = items[currentProject]?.options?.filter(
      (option: any) =>
        option?.optionInfo?.included === "included" && (option?.pjcc[14]?.cost > 0)
    );
    setOptions(newOptions);
  }, []);

  if (options?.length === 0) {
    return (
      <>
        {isTabletOrMobile ? (
          <div style={{ padding: "50px 25px 0px 30px", width: "850px" }}>

            {options?.map((item: any) => {
              return (
                <div key={item.optionInfo._id} style={{ marginBottom: 30 }}>
                  <Building option={item} />
                </div>
              );
            })}

            <BuildingTasks
              setup={items[currentProject]?.projectInfo?.proposal?.setup}
              surface={items[currentProject]?.projectInfo?.proposal?.surface}
            />

          </div>
        ) : (
          <div style={{ padding: "50px 25px 0px 30px" }}>

            {options?.map((item: any) => {
              return (
                <div key={item.optionInfo._id} style={{ marginBottom: 30 }}>
                  <Building option={item} />
                </div>
              );
            })}

            <BuildingTasks
              setup={items[currentProject]?.projectInfo?.proposal?.setup}
              surface={items[currentProject]?.projectInfo?.proposal?.surface}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {isTabletOrMobile ? (
        <div style={{ padding: "50px 25px 0px 30px", width: "850px" }}>
          <h2
            className="content-title"
            style={{ fontSize: 24, marginBottom: 0 }}
          >
            INCLUDED DETAILS
          </h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>

          {options?.map((item: any) => {
            return (
              <div key={item.optionInfo._id} style={{ marginBottom: 30 }}>
                <Building option={item} />
              </div>
            );
          })}

          <BuildingTasks
            setup={items[currentProject]?.projectInfo?.proposal?.setup}
            surface={items[currentProject]?.projectInfo?.proposal?.surface}
          />
        </div>
      ) : (
        <div style={{ padding: "50px 25px 0px 30px" }}>
          <h2
            className="content-title"
            style={{ fontSize: 24, marginBottom: 0 }}
          >
            INCLUDED DETAILS
          </h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>

          {options?.map((item: any) => {
            return (
              <div key={item.optionInfo._id} style={{ marginBottom: 30 }}>
                <Building option={item} />
              </div>
            );
          })}

          <BuildingTasks
            setup={items[currentProject]?.projectInfo?.proposal?.setup}
            surface={items[currentProject]?.projectInfo?.proposal?.surface}
          />
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
