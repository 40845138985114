import { Col, Row, Switch, Divider } from "antd";
import "../proposalSetup/ProposalSetup.less";
import { useMediaQuery } from "react-responsive";

type Props = {
  pName: string;
  pTelephone: string;
  pAddress: string;
  pEmail: string;
  pCell: string;
  osName: string;
  osTelephone: string;
  osAddress: string;
  osEmail: string;
  osCell: string;
  mName: string;
  mTelephone: string;
  mAddress: string;
  mEmail: string;
  mCell: string;
  hasOnSite: boolean;
  hasMaintenance: boolean;
};

const ClientContactProposal = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  return (
    <>
      {isTabletOrMobile ? (
        <div className="cc-container" style={{ width: "800px" }}>
          <Row justify="space-between">
            <Col flex="auto">
              <h2 className="content-title" style={{ marginBottom: 0 }}>
                CLIENT
              </h2>
              <Divider
                type="horizontal"
                style={{
                  marginTop: 0,
                  marginRight: 50,
                  borderColor: "#FDB913",
                  borderWidth: 5,
                }}
              ></Divider>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Type of Contact</Col>
                <Col span={8} style={{ fontWeight: 'bold' }}>Primary</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Name</Col>
                <Col span={12}>{props.pName}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Telephone</Col>
                <Col span={12}>{props.pTelephone}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Cell</Col>
                <Col span={12}>{props.pCell}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Emai</Col>
                <Col span={12}>{props.pEmail}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Address</Col>
                <Col span={12}>{props.pAddress}</Col>
              </Row>
            </Col>
            {props.hasOnSite && (

              <Col span={8}>
                <Row>
                  <Col span={8}></Col>
                  <Col style={{ fontWeight: 'bold' }}>Onsite</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Name</Col>
                  <Col span={12}>{props.osName}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Telephone</Col>
                  <Col span={12}>{props.osTelephone}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Cell</Col>
                  <Col span={12}>{props.osCell}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Emai</Col>
                  <Col span={12}>{props.osEmail}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Address</Col>
                  <Col span={12}>{props.osAddress}</Col>
                </Row>
              </Col>
            )}
            {props.hasMaintenance && (

              <Col span={8}>
                <Row>
                  <Col span={8}></Col>
                  <Col style={{ fontWeight: 'bold' }}>Maintenance</Col>
                </Row>

                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Name</Col>
                  <Col span={12}>{props.mName}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Telephone</Col>
                  <Col span={12}>{props.mTelephone}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Cell</Col>
                  <Col span={12}>{props.mCell}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Emai</Col>
                  <Col span={12}>{props.mEmail}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Address</Col>
                  <Col span={12}>{props.mAddress}</Col>

                </Row>
              </Col>
            )}
          </Row>
        </div>
      ) : (
        <div className="cc-container">
          <Row justify="space-between">
            <Col flex="auto">
              <h2 className="content-title" style={{ marginBottom: 0 }}>
                CLIENT
              </h2>
              <Divider
                type="horizontal"
                style={{
                  marginTop: 0,
                  marginRight: 50,
                  borderColor: "#FDB913",
                  borderWidth: 5,
                }}
              ></Divider>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Type of Contact</Col>
                <Col span={8} style={{ fontWeight: 'bold' }}>Primary</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Name</Col>
                <Col span={12}>{props.pName}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Telephone</Col>
                <Col span={12}>{props.pTelephone}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Cell</Col>
                <Col span={12}>{props.pCell}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Emai</Col>
                <Col span={12}>{props.pEmail}</Col>
              </Row>
              <Row>
                <Col span={8} style={{ fontWeight: 'bold' }}>Address</Col>
                <Col span={12}>{props.pAddress}</Col>
              </Row>
            </Col>
            {props.hasOnSite && (

              <Col span={8}>
                <Row>
                  <Col span={8}></Col>
                  <Col style={{ fontWeight: 'bold' }}>Onsite</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Name</Col>
                  <Col span={12}>{props.osName}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Telephone</Col>
                  <Col span={12}>{props.osTelephone}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Cell</Col>
                  <Col span={12}>{props.osCell}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Emai</Col>
                  <Col span={12}>{props.osEmail}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Address</Col>
                  <Col span={12}>{props.osAddress}</Col>
                </Row>
              </Col>
            )}
            {props.hasMaintenance && (

              <Col span={8}>
                <Row>
                  <Col span={8}></Col>
                  <Col style={{ fontWeight: 'bold' }}>Maintenance</Col>
                </Row>

                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Name</Col>
                  <Col span={12}>{props.mName}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Telephone</Col>
                  <Col span={12}>{props.mTelephone}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Cell</Col>
                  <Col span={12}>{props.mCell}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Emai</Col>
                  <Col span={12}>{props.mEmail}</Col>
                </Row>
                <Row>
                  <Col span={8} style={{ fontWeight: 'bold' }}>Address</Col>
                  <Col span={12}>{props.mAddress}</Col>

                </Row>
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

export default ClientContactProposal;
