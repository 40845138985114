import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Menu,
  Dropdown,
  Select,
  Spin,
  message,
  Input,
  Tag,
  Checkbox,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./EquipmentCostsC.less";
import { paintCostColumns, paintHeader } from "./config";
import api from "../../utils/api";
import { BulletIcon, MoreActionIcon } from "../../utils/icons";
import AddPaintCost from "../../components/pop-ups/default-modal/AddPaintCost";
import DefaultBulkEntry from "../../components/pop-ups/default-modal/MaterialDefaultBulkEntry";
import { CSVLink } from "react-csv";
import { RootStateOrAny, useSelector } from "react-redux";
import _ from "lodash";
const { Option } = Select;

const PaintCostC = () => {
  const [paintCost, setPaintCost] = useState<any>([]);
  const [showSingleItemModal, setShowSingleItemModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [materialNotFound, setMaterialNotFound] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [paintSearched, setPaintSearched] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<any>({});
  const [csvItem, setCsvItem] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const { paints, primers, testLoading } = useSelector(
    (state: RootStateOrAny) => state.materialDefaults
  );
  useEffect(() => {
    api
      .get("rates/cost/paint")
      .then((resp: any) => {
        let newResp = _.cloneDeep(resp);
        setPaintCost(resp);
        setCsvItem(
          newResp.map((item: any) => {
            delete item.paintMaterial;
            delete item.primerMaterial;
            return {
              ...item,
            };
          })
        );

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const handleDelete = (id: string) => {
    api.delete(`rates/paint/${id}`).then(() => {
      setPaintCost(paintCost.filter((item: any) => item._id !== id));
    });
  };

  const handleAdd = (values: any) => {
    if (edit) {
      api.put(`rates/paint/${item._id}`, values).then((resp: any) => {
        setPaintCost(
          paintCost.map((data: any) =>
            data._id === item._id ? { ...values, _id: item._id } : data
          )
        );
      });
    } else {
      api
        .post("rates", {
          category: "paint",
          rate: values,
        })
        .then((resp: any) => {
          setPaintCost([...paintCost, resp]);
        });
    }
    toggleSingleItemModal();
  };

  const found = paintCost?.filter((item: any) => {
    if (!paintSearched) {
      return true;
    }
    return item.item.toLowerCase().includes(paintSearched.toLowerCase());
  });

  const handleAddCsv = (info: any) => {
    setButtonDisable(true);
    if (info.file.status === "done") {
      message.success("Successfully Uploaded");
      setButtonDisable(false);
      setPaintCost(info.file.response.data.materialsAdded);
      setMaterialNotFound(info.file.response.data.materialNotFound);
      toggleBulkModal();
    } else if (info.file.status === "error") {
      setButtonDisable(false);
      message.error("Uploading Failed!");
    }
  };

  const toggleBulkModal = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  const toggleSingleItemModal = () => {
    !showSingleItemModal
      ? setShowSingleItemModal(true)
      : setShowSingleItemModal(false);
  };

  const handleSingleItem = () => {
    setEdit(false);
    setItem({});
    toggleSingleItemModal();
  };

  const handleEdit = (data: any) => {
    setItem(data);
    setEdit(true);
    toggleSingleItemModal();
  };

  const handlePaintMaterialChange = (value: any, data: any) => {
    if (value) {
      const payload = {
        ...data,
        paintMaterial: value,
      };
      api.put(`rates/paint/${data._id}`, payload).then((resp: any) => {
        setPaintCost(
          paintCost.map((item: any) => (item._id === data._id ? payload : item))
        );
      });
    }
  };

  const handlePrimerMaterialChange = (value: any, data: any) => {
    if (value) {
      const payload = {
        ...data,
        primerMaterial: value,
      };
      api.put(`rates/paint/${data._id}`, payload).then((resp: any) => {
        setPaintCost(
          paintCost.map((item: any) => (item._id === data._id ? payload : item))
        );
      });
    }
  };

  const handleCheckbox = (e: any, record: any) => {
    const newValue = e.target.checked;
    const updatedValues = { ...record, showLaborRates: newValue };

    api
      .put(`rates/paint/${record._id}`, updatedValues)
      .then((resp: any) => {
        // Assuming paintCost is your state variable containing the data
        setPaintCost(paintCost.map((data: any) => data._id === record._id ? updatedValues : data));
      })
      .catch((error: any) => {
        console.error("Error updating showLaborRates:", error);
      });
  };

  const tableColumns = [
    ...paintCostColumns,
    {
      title: "Paint Material",
      width: "250px",
      render: (data: any) => {
        if (data) {
          return (
            <Select
              showSearch
              dropdownMatchSelectWidth={false}
              style={{ caretColor: "transparent", width: "100%" }}
              filterOption={(input: any, option: any) =>
                (option?.children?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={data?.paintMaterial}
              onChange={(value: string) =>
                handlePaintMaterialChange(value, data)
              }
            >
              {paints.map((material: any, index: any) => (
                <Option key={index} value={material["_id"]}>
                  {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                </Option>
              ))}
            </Select>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: "Primer Material",
      width: "250px",
      render: (data: any) => {
        if (data) {
          return (
            <Select
              showSearch
              dropdownMatchSelectWidth={false}
              style={{ caretColor: "transparent", width: "100%" }}
              filterOption={(input: any, option: any) =>
                (option?.children?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              value={data?.primerMaterial}
              onChange={(value: string) =>
                handlePrimerMaterialChange(value, data)
              }
            >
              {primers.map((material: any, index: any) => (
                <Option key={index} value={material["_id"]}>
                  {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                </Option>
              ))}
            </Select>
          );
        } else {
          return <div></div>;
        }
      },
    },
    {
      title: "Show Rates",
      dataIndex: "showLaborRates",
      width: "150px",
      render: (check: any, record: any) => {
        // console.log("checkkkk---->", check)
        return (
          <Row justify="center">
            <Checkbox
              checked={check}
              onChange={(e: any) => handleCheckbox(e, record)}
            />
          </Row>
        );
      },
    },
    {
      title: "Action",
      fixed: "right",
      width: 85,
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="edit"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleEdit(data)}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleDelete(data._id)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Row style={{ cursor: "pointer" }} justify="center">
              <MoreActionIcon />
            </Row>
          </Dropdown>
        );
      },
    },
  ];
  const handleBulkBtn = () => {
    !showBulkModal ? setShowBulkModal(true) : setShowBulkModal(false);
  };

  return (
    <div className="admin-ec-root">
      <DefaultBulkEntry
        buttonDisable={buttonDisable}
        showBulkModal={showBulkModal}
        toggleBulkModal={toggleBulkModal}
        url="rates/upload"
        handleAddCsv={handleAddCsv}
      />
      <AddPaintCost
        toggleSingleItemModal={toggleSingleItemModal}
        handleAdd={handleAdd}
        showSingleItemModal={showSingleItemModal}
        item={item}
        edit={edit}
        paintMaterials={paints}
        primerMaterials={primers}
      />
      <Row className="admin-ec-header" justify="space-between">
        <Col>
          <h1>Paint Labor Rates</h1>
        </Col>
        <Row gutter={12}>
          <Col>
            <Input
              className="search-manufacture-input"
              prefix={
                <SearchOutlined className="contractor-data-search-icon" />
              }
              placeholder="Search Paint"
              onChange={(e) => {
                setPaintSearched(e.target.value);
              }}
            />
          </Col>
          <div className="contractor-data-vertical-line " />
          <Col>
            <Button
              className="admin-ec-add-single-btn"
              onClick={handleSingleItem}
            >
              Add Single item
            </Button>
          </Col>
          <Col>
            <Button
              className="admin-ec-add-btn"
              type="primary"
              onClick={handleBulkBtn}
            >
              Bulk Entry
            </Button>
          </Col>
        </Row>
      </Row>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8", marginBottom: 30 }} />
          <Button
            className="default-bulk-btn"
            style={{ margin: "0px 20px 10px" }}
            type="primary"
          >
            <CSVLink
              data={csvItem}
              filename={"paint-rates"}
              headers={paintHeader}
              enclosingCharacter={""}
            >
              Download CSV
            </CSVLink>
          </Button>
          {materialNotFound.length > 0 && (
            <div className="admin-ec-header">
              <Tag color="error" style={{ fontSize: 17, marginBottom: 5 }}>
                Materials not found for the following surfaces:
              </Tag>

              {materialNotFound?.map((surface: any) => (
                <div>
                  <BulletIcon style={{ marginRight: 10 }} />
                  {surface.item}
                </div>
              ))}
            </div>
          )}

          <div className="paint-scroll-table">
            {paintCost.length > 0 && (
              <Table
                rowKey="_id"
                columns={tableColumns}
                dataSource={found}
                scroll={{ x: 2000 }}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PaintCostC;
