import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Spin } from "antd";
import "./SkillsList.less";
import api from "../../utils/api";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {
  handleSkillsValue: (item: any) => any;
  handleSkillsChange: (e: any, item: any) => void;

};

const SkillsList = (props: Props) => {
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  return (
    <div className="sl-root">
      <div className="sl-container">
        <h1 className="sl-title">Lists of Skills</h1>
        <div className="sl-checkbox-container">
          <Row>
            {adminDefaults.skills?.map((item: any) => {
              return (
                <Col key={item._id} span={12}>
                  <Checkbox
                    checked={props.handleSkillsValue(item)}
                    onChange={(e: any) => props.handleSkillsChange(e, item)}
                    className="sl-checkbox"
                  >
                    <p className="sl-checkbox-text">{item.title}</p>
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
     
    </div>
  );
};

export default SkillsList;
