import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import "./AddSegmentModal.less";


interface EstimatorChecklist {
 _id?: string;
 description: string;
 isDeleted: boolean;
}


interface AddUpdateEstimatorChecklistModalProps {
 visible: boolean;
 onCancel: () => void;
 onOk: (estimatorChecklist: EstimatorChecklist) => void;
 estimatorChecklistData?: EstimatorChecklist;
}


const AddUpdateEstimatorChecklistModal = ({
 visible,
 onCancel,
 onOk,
 estimatorChecklistData,
}: AddUpdateEstimatorChecklistModalProps) => {
 const [form] = Form.useForm();
 const [description, setDescription] = useState("");


 useEffect(() => {
   if (estimatorChecklistData) {
     form.setFieldsValue({
       description: estimatorChecklistData.description,
       isDeleted: estimatorChecklistData.isDeleted,
     });
     setDescription(estimatorChecklistData.description);
   } else {
     setDescription("");
   }
 }, [estimatorChecklistData, form]);


 const handleOk = () => {
   form
     .validateFields()
     .then(() => {
       const updatedSegment = {
         ...(estimatorChecklistData && { _id: estimatorChecklistData._id }),
         description: description,
         isDeleted: estimatorChecklistData
           ? estimatorChecklistData.isDeleted
           : false,
       };
       onOk(updatedSegment);
       form.resetFields();
       setDescription("");
     })
     .catch((info) => {
       console.error("Validation Failed:", info);
     });
 };


 return (
   <Modal
     title={
       estimatorChecklistData
         ? "Edit Estimator Checklist"
         : "Add Estimator Checklist"
     }
     visible={visible}
     onCancel={onCancel}
     onOk={handleOk}
   >
     <Form form={form} layout="vertical">
       <Form.Item
         label="Description"
         name="description"
         rules={[
           { required: true, message: "Please enter the Description" },
           {
             max: 150,
             message: "Description must be less than 150 characters",
           },
         ]}
       >
         <Input
           placeholder="Enter Description"
           value={description}
           onChange={(e) => setDescription(e.target.value)}
         />
       </Form.Item>
     </Form>
   </Modal>
 );
};


export default AddUpdateEstimatorChecklistModal;
