import { customToFixed } from "./helpers";

var crypto = require("crypto");

const revenueMarginData = [
  { cost: 0, margin: 0.5 },
  { cost: 5000, margin: 0.47 },
  { cost: 10000, margin: 0.42 },
  { cost: 20000, margin: 0.4 },
  { cost: 30000, margin: 0.38 },
  { cost: 50000, margin: 0.36 },
  { cost: 150000, margin: 0.34 },
  { cost: 250000, margin: 0.32 },
  { cost: 10000000, margin: 0.32 },
];

const totalCostGrossMarginEstimation = (marginDefaults: any, cost: any) => {
  //Get all tiered margins below this project cost
  let grossMargin = marginDefaults.filter((item: any) => {
    return cost >= item.cost;
  });
  //Get last index from the filtered grossMargin
  let smallestValue = grossMargin[0]?.margin;
  let smallestIndex = 0;
  for (let i = 1; i < grossMargin.length; i++) {
    if (grossMargin[i]?.margin < smallestValue) {
      smallestValue = grossMargin[i]?.margin;
      smallestIndex = i;
    }
  }
  let index = smallestIndex;

  //TODO: change return object
  //Estimated price
  let costGm = cost / (1 - grossMargin[index]?.margin / 100);
  return { costGm, grossMargin, index };
};

const grossMarginEstimation = (
  marginDefaults: any,
  cost: any,
  singleOption: any,
  name: string,
  totalCostGrossMargin: any
) => {
  //TODO: update this function
  if (singleOption.overRideGrossMargin) {
    let overRideMargin = singleOption.overRideGrossMargin.filter(
      (item: any) => {
        return item.name === name;
      }
    );
    if (overRideMargin.length === 0) {
      let grossMargin = totalCostGrossMargin.grossMargin;
      let index = totalCostGrossMargin.index;
      // let grossMargin = marginDefaults.filter((item: any) => {
      //   return cost >= item.cost;
      // });
      // let index = grossMargin.length - 1;

      let costGm = cost / (1 - grossMargin[index]?.margin / 100);
      return { costGm, grossMargin, index };
    } else {
      const costGm = cost / (1 - overRideMargin[0].margin / 100);
      let index = 0;
      let grossMargin = overRideMargin;
      return { costGm, grossMargin, index };
    }
  } else {
    let grossMargin = totalCostGrossMargin.grossMargin;
    let index = totalCostGrossMargin.index;
    // let grossMargin = marginDefaults.filter((item: any) => {
    //   return cost >= item.cost;
    // });
    // let index = grossMargin.length - 1;

    let costGm =
      cost /
      (1 -
        totalCostGrossMargin.grossMargin[totalCostGrossMargin.index].margin /
          100);
    return { costGm, grossMargin, index };
  }
};
const calculateExpressionDiscount = (project: any, fullBidRevenue: any) => {
  const sumOfAllOptionsPrices = project.options?.reduce(
    (initial: any, curValue: any) => {
      return initial + parseFloat(curValue.pjcc[15].totalAmount);
    },
    0
  );
  const expressionDiscount = fullBidRevenue.costGm - sumOfAllOptionsPrices;
  return expressionDiscount;
};

export const optionPjccObject = (
  projectId: string,
  singleOption: any,
  cost: any,
  costGm: any,
  name: string
) => {
  if (
    name === "Total Material Cost" ||
    name === "Paint Hours" ||
    name === "Carpentry Hours" ||
    name === "Total Hours" ||
    name === "Total Labor" ||
    name === "Average Wage" ||
    name === "Total Cost"
  ) {
    return {
      project: projectId,
      option: singleOption._id,
      name: name,
      cost: +cost,
      amount: +costGm,
      totalAmount: +(
        costGm *
        (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1)
      ),
      grossMargin: +(isNaN(100 * (1 - cost / costGm))
        ? 0
        : 100 * (1 - cost / costGm)),
    };
  }
  if (name === "Estimated Price" || name === "Gross Margin") {
    return {
      project: projectId,
      option: singleOption._id,
      name: name,
      cost: +cost,
      amount: +costGm,
      totalAmount: +(
        costGm *
        (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1)
      ),
      grossMargin: 0,
    };
  } else {
    return {
      project: projectId,
      option: singleOption._id,
      name: name,
      cost: +cost,
      amount: +costGm.costGm,
      totalAmount: +(
        costGm.costGm *
        (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1)
      ),
      grossMargin: costGm.grossMargin[costGm.index].margin,
    };
  }
};

export const pricingCalculation = (project: any, adminDefaults: any) => {
  const projectInfo = project.projectInfo;

  let allOptionsPjcc: any = [];
  let paintLaborOptionPjcc = [];
  let carpentryLaborOptionPjcc = [];
  let carpentryHoursOptionPjcc = [];

  if (project.options.length > 0) {
    for (const singleOption of project.options) {
      for (const paintRate of singleOption.rates.paintRates) {
        if (!paintRate.isDeleted) {
          let coatsMultiplier: number = projectInfo.coatsMultiplier;
          const newCoats =
            paintRate.coats === 2
              ? 1 + coatsMultiplier / 100
              : paintRate.coats === 3
                ? 1 + coatsMultiplier / 50
                : 1;
          paintRate.prepHours = paintRate.count / paintRate.prepLaborRate;
          paintRate.prepHours = customToFixed(paintRate.prepHours, 2);
          paintRate.primeHours = paintRate.primeHours
            ? paintRate.primeHours
            : 0;
          paintRate.primeHours = customToFixed(paintRate.primeHours, 2);
          paintRate.paintHours =
            (paintRate.count * newCoats) / paintRate.paintLaborRate;
          paintRate.paintHours = customToFixed(paintRate.paintHours, 2);

          paintRate.primerGallons =
            singleOption.optionInfo?.roundGallons && paintRate.primerGallons
              ? Math.ceil(paintRate.primerGallons)
              : paintRate.primerGallons
                ? paintRate.primerGallons
                : 0;
          paintRate.paintGallons = singleOption.optionInfo?.roundGallons
            ? Math.ceil(
                (paintRate.count * newCoats) / paintRate.paintSpreadRate
              )
            : (paintRate.count * newCoats) / paintRate.paintSpreadRate;

          paintRate.totalPaintHours =
            paintRate.prepHours + paintRate.primeHours + paintRate.paintHours;
          paintRate.totalPaintHours = customToFixed(
            paintRate.totalPaintHours,
            2
          );

          const findPaintMaterial = project?.projectMaterials?.find(
            (material: any) =>
              material.defaultMaterialId === paintRate?.paintMaterial?._id
          );
          const findPrimerMaterial = project?.projectMaterials?.find(
            (material: any) =>
              material.defaultMaterialId === paintRate?.primerMaterial?._id
          );
          let paintMaterialRate = findPaintMaterial
            ? findPaintMaterial.price
            : paintRate.customerSuppliedPaint
              ? 0
              : paintRate.paintMaterial?.priceAfterTax;
          let primerMaterialRate = findPrimerMaterial
            ? findPrimerMaterial.price
            : paintRate.customerSuppliedPrimer
              ? 0
              : paintRate.primerMaterial?.priceAfterTax;

          paintRate.paintMaterialCost =
            (paintMaterialRate
              ? paintMaterialRate * paintRate.paintGallons
              : 0) +
            (primerMaterialRate
              ? primerMaterialRate * paintRate.primerGallons
              : 0);

          paintRate.paintMaterialCost = customToFixed(
            paintRate.paintMaterialCost,
            2
          );
        }
      }

      for (const carpentryRate of singleOption.rates.carpentryRates) {
        if (!carpentryRate.isDeleted) {
          carpentryRate.carpentryMaterial =
            carpentryRate.materialRate * carpentryRate.count;
          carpentryRate.carpentryMaterial = customToFixed(
            carpentryRate.carpentryMaterial,
            2
          );
          carpentryRate.carpentryHours =
            (carpentryRate.laborRate * carpentryRate.count) /
            projectInfo.carpentryWageRate;
          carpentryRate.carpentryHours = customToFixed(
            carpentryRate.carpentryHours,
            2
          );
        }
      }

      for (const equipmentRate of singleOption.rates.equipmentRates) {
        if (!equipmentRate.isDeleted) {
          equipmentRate.totalCost =
            equipmentRate.count *
            equipmentRate.periods *
            (equipmentRate.cost +
              equipmentRate.puAndDelivery +
              equipmentRate.surcharges) *
            (1 + equipmentRate.tax / 100);
          equipmentRate.totalCost = customToFixed(equipmentRate.totalCost, 2);
        }
      }

      for (const miscRate of singleOption.rates.miscellaneousRates) {
        if (!miscRate.isDeleted) {
          miscRate.totalCost = miscRate.count * miscRate.cost;

          miscRate.totalCost = customToFixed(miscRate.totalCost, 2);
        }
      }

      for (const travelRate of singleOption.rates.travelRates) {
        if (!travelRate.isDeleted) {
          travelRate.totalCost =
            travelRate.rfmCount * travelRate.dnCount * travelRate.cost;
          travelRate.totalCost = customToFixed(travelRate.totalCost, 2);
        }
      }

      let singleOptionPjcc = [];

      let sumOfAllPaintMaterialCost = 0;
      let sumOfAllPaintRateHours = 0;
      for (const item of singleOption.rates.paintRates) {
        if (!item.isDeleted) {
          sumOfAllPaintMaterialCost =
            sumOfAllPaintMaterialCost +
            (isNaN(item.paintMaterialCost) ? 0 : item.paintMaterialCost);
          sumOfAllPaintRateHours =
            sumOfAllPaintRateHours +
            ((item.paintHours ? item.paintHours : 0) +
              (item.primeHours ? item.primeHours : 0) +
              (item.prepHours ? item.prepHours : 0));
        }
      }

      let sumOfAllCarpentryRateHours = 0;
      let sumOfAllCarpentryMaterial = 0;

      for (const item of singleOption.rates.carpentryRates) {
        if (!item.isDeleted) {
          sumOfAllCarpentryRateHours =
            parseFloat(sumOfAllCarpentryRateHours.toFixed(2)) +
            (item.carpentryHours
              ? parseFloat(item.carpentryHours.toFixed(2))
              : 0);
          sumOfAllCarpentryMaterial =
            parseFloat(sumOfAllCarpentryMaterial.toFixed(2)) +
            (item.carpentryMaterial
              ? parseFloat(item.carpentryMaterial.toFixed(2))
              : 0);
        }
      }

      let sumOfAllEquipmentTotalCost = 0;
      for (const item of singleOption.rates.equipmentRates) {
        if (!item.isDeleted) {
          sumOfAllEquipmentTotalCost =
            sumOfAllEquipmentTotalCost + (item.totalCost ? item.totalCost : 0);
        }
      }

      let sumOfAllMiscTotalCost = 0;
      for (const item of singleOption.rates.miscellaneousRates) {
        if (!item.isDeleted) {
          sumOfAllMiscTotalCost =
            sumOfAllMiscTotalCost + (item.totalCost ? item.totalCost : 0);
        }
      }

      let sumOfAllTravelTotalCost = 0;
      for (const item of singleOption.rates.travelRates) {
        if (!item.isDeleted) {
          sumOfAllTravelTotalCost =
            sumOfAllTravelTotalCost + (item.totalCost ? item.totalCost : 0);
        }
      }

      //OptionPjcc CALCULATIONS
      let washHours = 0;
      if (
        singleOption.rates.washRates &&
        singleOption.optionInfo.washIncluded === true
      ) {
        washHours =
          singleOption.rates.washRates.length > 0
            ? singleOption.rates.washRates[0].squareFeet /
              singleOption.rates.washRates[0].rate
            : 0;
      }
      let setup = 0;
      if (singleOption?.optionInfo?.overRideSetupHours) {
        setup = singleOption?.optionInfo?.overRideSetupHours;
      } else {
        setup =
          projectInfo.setupConst *
          (parseFloat(sumOfAllPaintRateHours.toFixed(2)) + washHours);
      }

      let totalPaintHours = parseFloat(
        (
          parseFloat(sumOfAllPaintRateHours.toFixed(2)) +
          washHours +
          setup
        ).toFixed(2)
      );

      let totalCarpentryHours = 1.08 * sumOfAllCarpentryRateHours;

      let totalPaintAndCarpentryHours = totalPaintHours + totalCarpentryHours;

      //PaintCost
      let paintCost = Math.round(
        (isNaN(singleOption.optionInfo.spotPrimeMaterial)
          ? 0
          : singleOption.optionInfo.spotPrimeMaterial) +
          sumOfAllPaintMaterialCost
      );
      //SsCost
      let ssCost = Math.round(
        Math.round(totalPaintAndCarpentryHours) * projectInfo.ssConst
      );
      //CarpentryMaterial
      let carpentryMaterial = Math.round(sumOfAllCarpentryMaterial);
      //TotalMaterialCost
      let totalMaterialCost = Math.round(
        paintCost + ssCost + carpentryMaterial
      );
      //EquipmentCost
      let equipmentCost = Math.round(sumOfAllEquipmentTotalCost);
      //MiscCost
      let miscCost = Math.round(sumOfAllMiscTotalCost);
      //TravelCost
      let travelCost = Math.round(sumOfAllTravelTotalCost);
      //PaintHours
      let paintHours = customToFixed(totalPaintHours, 2);
      //CarpentryHours
      let carpentryHours = customToFixed(totalCarpentryHours, 2);
      //TotalHours
      let totalHours = customToFixed(totalPaintAndCarpentryHours, 2);
      //PaintLabor
      let paintLabor = Math.round(totalPaintHours * projectInfo.paintWageRate);
      //CarpentryLabor
      let carpentryLabor = Math.round(
        totalCarpentryHours * projectInfo.carpentryWageRate
      );
      //TotalLabor
      let totalLabor = Math.round(paintLabor + carpentryLabor);
      //AverageWage
      let averageWage = isNaN(totalLabor / totalHours)
        ? 0
        : Math.round(totalLabor / totalHours);
      //TotalCost
      let totalCost = Math.round(
        totalMaterialCost + totalLabor + miscCost + travelCost + equipmentCost
      );

      let totalCostGrossMargin = totalCostGrossMarginEstimation(
        projectInfo.tieredMargins,
        totalCost
      );

      let paintCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        paintCost,
        singleOption.optionInfo,
        "Paint Cost",
        totalCostGrossMargin
      );

      // let ssCost = totalPaintAndCarpentryHours * ssWageRate.wageValue;
      let ssCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        ssCost,
        singleOption.optionInfo,
        "SS Cost",
        totalCostGrossMargin
      );

      // let carpentryMaterial = sumOfAllCarpentryMaterial;
      let carpentryMaterialGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        carpentryMaterial,
        singleOption.optionInfo,
        "Carpentry Material",
        totalCostGrossMargin
      );

      // let totalMaterialCost = paintCost + ssCost + carpentryMaterial;
      let totalMaterialCostGm =
        paintCostGm.costGm + ssCostGm.costGm + carpentryMaterialGm.costGm;

      // let equipmentCost = sumOfAllEquipmentTotalCost;
      let equipmentCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        equipmentCost,
        singleOption.optionInfo,
        "Equipment Cost",
        totalCostGrossMargin
      );

      // let miscCost = sumOfAllMiscTotalCost;
      let miscCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        miscCost,
        singleOption.optionInfo,
        "Misc Cost",
        totalCostGrossMargin
      );

      // let travelCost = sumOfAllTravelTotalCost;
      let travelCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        travelCost,
        singleOption.optionInfo,
        "Travel Cost",
        totalCostGrossMargin
      );

      // let paintHours = totalPaintHours;

      // let carpentryHours = totalCarpentryHours;

      // let totalHours = totalPaintAndCarpentryHours;

      // let paintLabor = totalPaintHours * paintLaborRate.wageValue;
      let paintLaborGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        paintLabor,
        singleOption.optionInfo,
        "Paint Labor",
        totalCostGrossMargin
      );

      // let carpentryLabor = totalCarpentryHours * carpentryWageRate.wageValue;
      let carpentryLaborGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        carpentryLabor,
        singleOption.optionInfo,
        "Carpentry Labor",
        totalCostGrossMargin
      );

      // let totalLabor = paintLabor + carpentryLabor;
      let totalLaborGm = paintLaborGm.costGm + carpentryLaborGm.costGm;

      let averageWageGm = isNaN(totalLaborGm / totalHours)
        ? 0
        : Math.round(totalLaborGm / totalHours);

      // let totalCost =
      //   totalMaterialCost + totalLabor + miscCost + travelCost + equipmentCost;
      let totalCostGm =
        totalMaterialCostGm +
        totalLaborGm +
        miscCostGm.costGm +
        travelCostGm.costGm +
        equipmentCostGm.costGm;

      let paintCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        paintCost,
        paintCostGm,
        "Paint Cost"
      );

      let ssCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        ssCost,
        ssCostGm,
        "SS Cost"
      );
      let carpentryMaterialObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        carpentryMaterial,
        carpentryMaterialGm,
        "Carpentry Material"
      );
      let totalMaterialCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalMaterialCost,
        totalMaterialCostGm,
        "Total Material Cost"
      );
      let equipmentCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        equipmentCost,
        equipmentCostGm,
        "Equipment Cost"
      );
      let miscCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        miscCost,
        miscCostGm,
        "Miscellaneous Cost"
      );
      let travelCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        travelCost,
        travelCostGm,
        "Travel Cost"
      );
      let paintHoursObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        paintHours,
        paintHours,
        "Paint Hours"
      );
      let carpentryHoursObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        carpentryHours,
        carpentryHours,
        "Carpentry Hours"
      );
      carpentryHoursOptionPjcc.push(carpentryHoursObject);
      let totalHoursObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalHours,
        totalHours,
        "Total Hours"
      );
      let paintLaborObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        paintLabor,
        paintLaborGm,
        "Paint Labor"
      );
      paintLaborOptionPjcc.push(paintLaborObject);
      let carpentryLaborObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        carpentryLabor,
        carpentryLaborGm,
        "Carpentry Labor"
      );
      carpentryLaborOptionPjcc.push(carpentryLaborObject);
      let totalLaborObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalLabor,
        totalLaborGm,
        "Total Labor"
      );
      let averageWageObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        averageWage,
        averageWageGm,
        "Average Wage"
      );
      let totalCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalCost,
        totalCostGm,
        "Total Cost"
      );
      let estimatedPriceObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalCost,
        totalCostGm,
        "Estimated Price"
      );
      let grossMarginObject = {
        project: projectInfo._id,
        option: singleOption.optionInfo._id,
        name: "Gross Margin",
        cost: null,
        amount: Math.round(estimatedPriceObject.amount - totalCostObject.cost),
        totalAmount: Math.round(
          estimatedPriceObject.totalAmount -
            totalCostObject.cost * singleOption.optionInfo.buildingQuantity
        ),
      };
      // let grossMarginObject = optionPjccObject(
      //   projectInfo._id,
      //   singleOption.optionInfo,
      //   totalCost,
      //   totalCostGm,
      //   "Gross Margin"
      // );

      singleOptionPjcc.push(paintCostObject);
      singleOptionPjcc.push(ssCostObject);
      singleOptionPjcc.push(carpentryMaterialObject);
      singleOptionPjcc.push(totalMaterialCostObject);
      singleOptionPjcc.push(equipmentCostObject);
      singleOptionPjcc.push(miscCostObject);
      singleOptionPjcc.push(travelCostObject);
      singleOptionPjcc.push(paintHoursObject);
      singleOptionPjcc.push(carpentryHoursObject);
      singleOptionPjcc.push(totalHoursObject);
      singleOptionPjcc.push(paintLaborObject);
      singleOptionPjcc.push(carpentryLaborObject);
      singleOptionPjcc.push(totalLaborObject);
      singleOptionPjcc.push(averageWageObject);
      singleOptionPjcc.push(totalCostObject);
      singleOptionPjcc.push(estimatedPriceObject);
      singleOptionPjcc.push(grossMarginObject);

      //setting Values to Project
      singleOption.pjcc = singleOptionPjcc;

      allOptionsPjcc.push({
        optionId: singleOption.optionInfo._id,
        optionName: singleOption.optionInfo.title,
        included: singleOption.optionInfo.included,
        buildingQuantity: singleOption.optionInfo.buildingQuantity
          ? singleOption.optionInfo.buildingQuantity
          : 1,
        pjcc: singleOptionPjcc,
      });
    }

    let fullBidCost = allOptionsPjcc.reduce(
      (accumulator: any, currentObject: any) => {
        let totalProfit = currentObject?.pjcc?.find(
          (item: any) => item.name === "Total Cost"
        );
        return (
          accumulator + totalProfit?.cost * currentObject?.buildingQuantity
        );
      },
      0
    );
    const fullBidRevenue = totalCostGrossMarginEstimation(
      projectInfo.tieredMargins,
      fullBidCost
    );

    const optionsPjccWithIncluded: any = allOptionsPjcc.filter((item: any) => {
      return item.included === "included";
    });

    if (projectInfo.overRideGrossMargin.length !== 0) {
      for (const item of projectInfo.overRideGrossMargin) {
        switch (item.name) {
          case "Paint Cost":
          case "SS Cost":
          case "Carpentry Material":
            {
              for (const singleOptionPjcc of allOptionsPjcc) {
                let optionBuildingQuantity = singleOptionPjcc.buildingQuantity;
                for (const element of singleOptionPjcc.pjcc) {
                  if (element.name === item.name) {
                    let newAmount = element.cost / (1 - item.margin / 100);
                    let newTotalAmount = newAmount * optionBuildingQuantity;

                    element.amount = newAmount;
                    element.totalAmount = newTotalAmount;
                    element.grossMargin = item.margin;
                  }
                }
                let newArray = singleOptionPjcc.pjcc.filter((entity: any) => {
                  return (
                    entity.name === "Paint Cost" ||
                    entity.name === "SS Cost" ||
                    entity.name === "Carpentry Material"
                  );
                });

                let cost = newArray.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.cost;
                }, 0);
                let amount = newArray.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.amount;
                }, 0);
                let totalAmount = newArray.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.totalAmount;
                }, 0);
                let grossMargin = (1 - cost / totalAmount) * 100;

                for (const element of singleOptionPjcc.pjcc) {
                  if (element.name === "Total Material Cost") {
                    element.cost = cost;
                    element.amount = amount;
                    element.totalAmount = totalAmount;
                    element.grossMargin = grossMargin;
                  }
                }
                let arrayForTotalCost = singleOptionPjcc.pjcc.filter(
                  (entity: any) => {
                    return (
                      entity.name === "Total Material Cost" ||
                      entity.name === "Miscellaneous Cost" ||
                      entity.name === "Travel Cost" ||
                      entity.name === "Equipment Cost" ||
                      entity.name === "Total Labor"
                    );
                  }
                );

                let newCost = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.cost;
                }, 0);
                let newAmount = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.amount;
                }, 0);
                let newTotalAmount = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.totalAmount;
                }, 0);
                let newGrossMargin = (1 - newCost / newTotalAmount) * 100;

                for (const element of singleOptionPjcc.pjcc) {
                  if (
                    element.name === "Total Cost" ||
                    element.name === "Estimated Price" ||
                    element.name === "Gross Margin"
                  ) {
                    element.cost = newCost;
                    element.amount = newAmount;
                    element.totalAmount = newTotalAmount;
                    element.grossMargin = newGrossMargin;
                  }
                }
              }
            }
            break;
          case "Paint Labor":
          case "Carpentry Labor":
            {
              for (const singleOptionPjcc of allOptionsPjcc) {
                let optionBuildingQuantity = singleOptionPjcc.buildingQuantity;
                for (const element of singleOptionPjcc.pjcc) {
                  if (element.name === item.name) {
                    let newAmount = element.cost / (1 - item.margin / 100);
                    let newTotalAmount = newAmount * optionBuildingQuantity;

                    element.amount = newAmount;
                    element.totalAmount = newTotalAmount;
                    element.grossMargin = item.margin;
                  }
                }
                let newArray = singleOptionPjcc.pjcc.filter((entity: any) => {
                  return (
                    entity.name === "Paint Labor" ||
                    entity.name === "Carpentry Labor"
                  );
                });

                let cost = newArray.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.cost;
                }, 0);
                let amount = newArray.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.amount;
                }, 0);
                let totalAmount = newArray.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.totalAmount;
                }, 0);
                let grossMargin = (1 - cost / totalAmount) * 100;

                for (const element of singleOptionPjcc.pjcc) {
                  if (element.name === "Total Labor") {
                    element.cost = cost;
                    element.amount = amount;
                    element.totalAmount = totalAmount;
                    element.grossMargin = grossMargin;
                  }
                }

                let arrayForTotalCost = singleOptionPjcc.pjcc.filter(
                  (entity: any) => {
                    return (
                      entity.name === "Total Material Cost" ||
                      entity.name === "Miscellaneous Cost" ||
                      entity.name === "Travel Cost" ||
                      entity.name === "Equipment Cost" ||
                      entity.name === "Total Labor"
                    );
                  }
                );

                let newCost = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.cost;
                }, 0);
                let newAmount = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.amount;
                }, 0);
                let newTotalAmount = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.totalAmount;
                }, 0);
                let newGrossMargin = (1 - newCost / newTotalAmount) * 100;

                for (const element of singleOptionPjcc.pjcc) {
                  if (
                    element.name === "Total Cost" ||
                    element.name === "Estimated Price" ||
                    element.name === "Gross Margin"
                  ) {
                    element.cost = newCost;
                    element.amount = newAmount;
                    element.totalAmount = newTotalAmount;
                    element.grossMargin = newGrossMargin;
                  }
                }
              }
            }
            break;
          case "Miscellaneous Cost":
          case "Travel Cost":
          case "Equipment Cost":
            {
              for (const singleOptionPjcc of allOptionsPjcc) {
                let optionBuildingQuantity = singleOptionPjcc.buildingQuantity;
                for (const element of singleOptionPjcc.pjcc) {
                  if (element.name === item.name) {
                    let newAmount = element.cost / (1 - item.margin / 100);
                    let newTotalAmount = newAmount * optionBuildingQuantity;

                    element.amount = newAmount;
                    element.totalAmount = newTotalAmount;
                    element.grossMargin = item.margin;
                  }
                }
                let arrayForTotalCost = singleOptionPjcc.pjcc.filter(
                  (entity: any) => {
                    return (
                      entity.name === "Total Material Cost" ||
                      entity.name === "Miscellaneous Cost" ||
                      entity.name === "Travel Cost" ||
                      entity.name === "Equipment Cost" ||
                      entity.name === "Total Labor"
                    );
                  }
                );

                let newCost = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.cost;
                }, 0);
                let newAmount = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.amount;
                }, 0);
                let newTotalAmount = arrayForTotalCost.reduce(function (
                  previousValue: any,
                  currentValue: any
                ) {
                  return previousValue + currentValue.totalAmount;
                }, 0);
                let newGrossMargin = (1 - newCost / newTotalAmount) * 100;

                for (const element of singleOptionPjcc.pjcc) {
                  if (
                    element.name === "Total Cost" ||
                    element.name === "Estimated Price" ||
                    element.name === "Gross Margin"
                  ) {
                    element.cost = newCost;
                    element.amount = newAmount;
                    element.totalAmount = newTotalAmount;
                    element.grossMargin = newGrossMargin;
                  }
                }
              }
            }
            break;
        }
      }
    }

    let allOptionPjcc: any = [];
    for (const singleOptionPjcc of optionsPjccWithIncluded) {
      allOptionPjcc.push(...singleOptionPjcc.pjcc);
    }

    let projectPjcc: any = [];

    const nameTypes = [
      "Estimated Price",
      "Paint Cost",
      "SS Cost",
      "Carpentry Material",
      "Total Material Cost",
      "Equipment Cost",
      "Miscellaneous Cost",
      "Travel Cost",
      "Paint Hours",
      "Carpentry Hours",
      "Total Hours",
      "Paint Labor",
      "Carpentry Labor",
      "Total Labor",
      "Average Wage",
      "Total Cost",
      "Gross Margin",
    ];
    if (allOptionPjcc.length !== 0) {
      let index: number = 0;
      let maxCount = allOptionPjcc.length / 17;
      let count = 0;
      nameTypes.forEach((nameType) => {
        const pjccOptionByName = allOptionPjcc.filter(
          (item: any) => item.name === nameType
        );
        if (pjccOptionByName.length !== 0) {
          const value: any = {
            cost: 0,
            amount: 0,
            totalAmount: 0,
            grossMargin: 0,
          };
          pjccOptionByName.forEach((singleItem: any) => {
            value.cost +=
              singleItem.cost *
              optionsPjccWithIncluded[count]?.buildingQuantity;
            value.amount +=
              singleItem.amount *
              optionsPjccWithIncluded[count]?.buildingQuantity;
            value.totalAmount += singleItem.totalAmount;
            value.grossMargin += singleItem.grossMargin;
            count++;
            if (count === maxCount) {
              count = 0;
            }
          });
          let itemGrossMargin = 0;
          itemGrossMargin = +((1 - value.cost / value.amount) * 100);
          projectPjcc.push({
            project: projectInfo._id,
            name: nameType,
            cost: value.cost,
            amount: value.amount,
            totalAmount: value.totalAmount,
            grossMargin: !isNaN(itemGrossMargin) ? itemGrossMargin : 0,
            index: index++,
            productionTargets: 0,
          });
        }
      });

      if (projectPjcc.length !== 0) {
        const grossMarginIndex = projectPjcc.findIndex(
          (item: any) => item.name === "Gross Margin"
        );
        const totalCostIndex = projectPjcc.findIndex(
          (item: any) => item.name === "Total Cost"
        );
        const pjccProjectTotalHours = projectPjcc.find(
          (item: any) => item.name === "Total Hours"
        );
        const pjccProjectGrossMargin = projectPjcc.find(
          (item: any) => item.name === "Gross Margin"
        );

        const pjccProjectFullBidCosts = projectPjcc.find(
          (item: any) => item.name === "Total Cost"
        );
        const pjccProjectPriceToClient = projectPjcc.find(
          (item: any) => item.name === "Estimated Price"
        );
        const pjccProjectLabor = projectPjcc.find(
          (item: any) => item.name === "Total Labor"
        );
        const pjccProjectwageIndex = projectPjcc.findIndex(
          (item: any) => item.name === "Average Wage"
        );
        let priceToClient = pjccProjectPriceToClient.totalAmount;
        let dollar = 0;

        const expressionDiscount = calculateExpressionDiscount(
          project,
          fullBidRevenue
        );

        if (projectInfo.discounts) {
          for (const item of projectInfo.discounts) {
            if (item.amountType === "percentage") {
              priceToClient -= priceToClient * (item.amount / 100);
            }
            if (item.amountType === "dollar") {
              priceToClient -= item.amount;
            }
            if (
              item.amountType === "expression" &&
              Number(expressionDiscount) <= 0
            ) {
              priceToClient += Number(expressionDiscount);
            }
          }
        }

        // priceToClient = priceToClient - dollar;
        projectPjcc[pjccProjectwageIndex].cost =
          pjccProjectLabor.cost / pjccProjectTotalHours.cost;
        projectPjcc[pjccProjectwageIndex].totalAmount =
          pjccProjectLabor.amount / pjccProjectTotalHours.amount;
        projectPjcc[grossMarginIndex].cost =
          priceToClient - projectPjcc[totalCostIndex].cost;
        projectPjcc[grossMarginIndex].amount =
          priceToClient - projectPjcc[totalCostIndex].cost;
        projectPjcc[grossMarginIndex].totalAmount =
          priceToClient - projectPjcc[totalCostIndex].cost;

        const projectMargin =
          (1 - pjccProjectFullBidCosts.cost / priceToClient) * 100;

        let grossProfit =
          pjccProjectGrossMargin?.totalAmount /
          pjccProjectTotalHours?.totalAmount;
        grossProfit = isFinite(grossProfit) ? grossProfit : 0;

        projectPjcc.push(
          {
            project: projectInfo._id,
            name: "Gross Profit $ p/hr",
            totalAmount:
              pjccProjectGrossMargin.totalAmount === 0
                ? 0
                : !isNaN(grossProfit)
                  ? grossProfit
                  : undefined,
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Project Costs",
            cost: pjccProjectFullBidCosts.cost,
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Project Margin",
            totalAmount:
              pjccProjectFullBidCosts.cost === 0
                ? 0
                : !isNaN(projectMargin)
                  ? projectMargin
                  : undefined,
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Price to Client",
            totalAmount: priceToClient,
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Full Bid Revenue",
            cost: fullBidCost,
            amount: fullBidRevenue?.costGm,
            totalAmount: fullBidRevenue?.costGm,
            margin: fullBidRevenue.grossMargin[fullBidRevenue.index]?.margin,
          }
        );
      }
    } else {
      let index: number = 0;
      const extraNameTypes = [
        "Gross Profit $ p/hr",
        "Project Costs",
        "Project Margin",
        "Price to Client",
        "Full Bid Revenue",
      ];
      nameTypes.forEach((nameType) => {
        projectPjcc.push({
          project: projectInfo._id,
          name: nameType,
          cost: 0,
          amount: 0,
          totalAmount: 0,
          grossMargin: 0,
          index: index++,
          productionTargets: 0,
        });
      });
      extraNameTypes.forEach((name) => {
        projectPjcc.push({
          project: projectInfo._id,
          name: name,
          cost: 0,
          amount: 0,
          totalAmount: 0,
          grossMargin: 0,
          index: index++,
          productionTargets: 0,
        });
      });
    }

    if (projectInfo.productionPjcc?.length > 0) {
      for (const item of projectPjcc) {
        let productionPjccEntity = projectInfo.productionPjcc.find(
          (entity: any) => entity.name === item.name
        );
        if (productionPjccEntity) {
          item.productionTargets =
            productionPjccEntity.productionTargets === null
              ? item.productionTargets
              : productionPjccEntity.productionTargets;
          item.workOrder = productionPjccEntity.appearOnWorkOrder;
        }
      }
    }

    //setting Values to Project

    const updatedProjectDto = projectPjcc.map((item: any) => {
      return {
        ...item,
        cost: item.cost,
        amount: item.amount,
        totalAmount: item.totalAmount,
        productionTargets: item.productionTargets,
      };
    });
    project.pjcc = updatedProjectDto;

    //WORK Orders - Paint
    const projectPjccPaintLabor = projectPjcc.filter((item: any) => {
      return item.name === "Paint Labor";
    });
    let paintWorkOrderArray = [];

    for (const singleOption of project.options) {
      // const optionWashRates: any = washRates.filter((item: any) => {
      //   return item.option === singleOption.id;
      // });

      if (singleOption?.optionInfo?.included === "included") {
        let totalWashHours = 0;
        if (
          singleOption.rates.washRates.length > 0 &&
          singleOption.optionInfo.washIncluded === true
        ) {
          totalWashHours = singleOption.rates.washRates[0].totalHours;
        }

        // let singleOptionPaintRates = paintRates.filter((item: any) => {
        //   return item.option == singleOption.id;
        // });

        const singleOptionPaintRates = singleOption.rates.paintRates;

        //paintRateId
        let singleOptionPaintRateId: any = [];

        let prepHoursSum = 0;
        let paintHoursSum = 0;
        let primeHoursSum = 0;
        for (const item of singleOptionPaintRates) {
          if (!item.isDeleted) {
            prepHoursSum = prepHoursSum + item?.prepHours;
            paintHoursSum = paintHoursSum + item?.paintHours;
            primeHoursSum = primeHoursSum + item?.primeHours;
            let paintManufacturer = item.paintMaterial?.manufacturer
              ? item.paintMaterial?.manufacturer
              : "";
            let paintProduct = item.paintMaterial?.product
              ? item.paintMaterial?.product
              : "";

            let paintSheen = item.paintMaterial?.sheen
              ? item.paintMaterial?.sheen
              : "";

            let paintMsg = "";
            if (paintManufacturer || paintProduct) {
              paintMsg = `${paintManufacturer}:${paintProduct}:${paintSheen}`;
            }
            let primerManufacturer = item.primerMaterial?.manufacturer
              ? item.primerMaterial?.manufacturer
              : "";
            let primerProduct = item.primerMaterial?.product
              ? item.primerMaterial?.product
              : "";

            let primerSheen = item.primerMaterial?.sheen
              ? item.primerMaterial?.sheen
              : "";
            let primerMsg = "";
            if (primerManufacturer || primerProduct) {
              primerMsg = `${primerManufacturer}:${primerProduct}:${primerSheen}`;
            }
            singleOptionPaintRateId.push({
              item: item?.item,
              showLaborRates: item?.showLaborRates,
              paintMaterial: paintMsg,
              color: item?.color,
              coats: item?.coats,
              paintGallons: isNaN(item.paintGallons) ? 0 : item.paintGallons,
              primerMaterial: primerMsg,
              primerGallons: isNaN(item.primerGallons) ? 0 : item.primerGallons,
              primerCoats: item?.primeSelect,
            });
          }
        }

        let productionTargetsDivideByCost =
          projectPjccPaintLabor[0].productionTargets /
          projectPjccPaintLabor[0].cost;

        //Wash Hours
        let singleOptionWashHours =
          totalWashHours *
          productionTargetsDivideByCost *
          singleOption.optionInfo.buildingQuantity;
        //Setup
        let setup = (paintHoursSum + totalWashHours) * projectInfo.setupConst;
        if (singleOption?.optionInfo?.overRideSetupHours) {
          setup = singleOption.optionInfo.overRideSetupHours;
        }
        //Prep Hours
        let singleOptionPrepHours =
          (prepHoursSum + setup) *
          singleOption.optionInfo.buildingQuantity *
          (projectPjccPaintLabor[0].productionTargets /
            projectPjccPaintLabor[0].cost);
        //Paint Hours
        let singleOptionPaintHours =
          (paintHoursSum + primeHoursSum) *
          (projectPjccPaintLabor[0].productionTargets /
            projectPjccPaintLabor[0].cost) *
          singleOption.optionInfo.buildingQuantity;
        //Paint Labor
        let optionPjccPaintLabor = paintLaborOptionPjcc.filter((item) => {
          return item.option === singleOption.optionInfo._id;
        });

        let singleOptionPaintLabor =
          (optionPjccPaintLabor[0].cost / projectPjccPaintLabor[0].cost) *
          projectPjccPaintLabor[0].productionTargets;

        let paintWorkOrder = {
          _id: crypto.randomBytes(12).toString("hex"),
          project: projectInfo._id,
          item: singleOption.optionInfo.title,
          showLaborRates: singleOption.optionInfo?.showLaborRates,
          address: singleOption.optionInfo.address,
          workOrderNotes: singleOption.optionInfo.workOrderPaintNotes,
          washHours: isNaN(singleOptionWashHours) ? 0 : +singleOptionWashHours,
          prepHours: isNaN(singleOptionPrepHours) ? 0 : +singleOptionPrepHours,
          paintHours: isNaN(singleOptionPaintHours)
            ? 0
            : +singleOptionPaintHours,
          paintLabor: isNaN(singleOptionPaintLabor)
            ? 0
            : +singleOptionPaintLabor,
          paintRateId: isNaN(singleOptionPaintLabor)
            ? []
            : singleOptionPaintRateId,
          optionCreatedAt: singleOption.optionInfo.createdAt,
        };
        paintWorkOrderArray.push(paintWorkOrder);
      }
    }

    //setting Values to Project
    project.paintWorkOrder = paintWorkOrderArray;

    //WORK Orders - Carpentry
    const projectPjccCarpentryLabor = projectPjcc.filter((item: any) => {
      return item.name === "Carpentry Labor";
    });

    let carpentryWorkOrderArray = [];
    for (const singleOption of project.options) {
      // let singleOptionCarpentryRates = carpentryRates.filter((item: any) => {
      //   return item.option == singleOption.id;
      // });

      if (singleOption?.optionInfo?.included === "included") {
        let singleOptionCarpentryRates = singleOption.rates.carpentryRates;
        let optionPjccCarpentryLabor = carpentryLaborOptionPjcc.filter(
          (item) => {
            return item.option === singleOption.optionInfo._id;
          }
        );
        let optionPjccCarpentryHours = carpentryHoursOptionPjcc.filter(
          (item) => {
            return item.option === singleOption.optionInfo._id;
          }
        );
        let singleOptionCarpentryHours =
          optionPjccCarpentryHours[0].totalAmount *
          (projectPjccCarpentryLabor[0].productionTargets /
            projectPjccCarpentryLabor[0].cost) *
          singleOption.optionInfo.buildingQuantity;

        let singleOptionCarpentryLabor =
          optionPjccCarpentryLabor[0].cost *
          (projectPjccCarpentryLabor[0].productionTargets /
            projectPjccCarpentryLabor[0].cost);

        let singleOptionCarpentryRateId = [];
        for (const item of singleOptionCarpentryRates) {
          if (item.count !== 0) {
            singleOptionCarpentryRateId.push({
              item: item.item,
              count: item.count,
            });
          }
        }

        let carpentryWorkOrder = {
          _id: crypto.randomBytes(12).toString("hex"),
          project: projectInfo._id,
          buildingName: singleOption.optionInfo.title,
          numberOfType: isNaN(singleOption.buildingQuantity)
            ? 1
            : singleOption.buildingQuantity,
          carpentryHours: isNaN(singleOptionCarpentryHours)
            ? 0
            : +singleOptionCarpentryHours,
          carpentryLabor: isNaN(singleOptionCarpentryLabor)
            ? 0
            : +singleOptionCarpentryLabor,
          carpentryRateId: isNaN(singleOptionCarpentryLabor)
            ? []
            : singleOptionCarpentryRateId,
          optionCreatedAt: singleOption.optionInfo.createdAt,
          workOrderNotes: singleOption.optionInfo.workOrderCarpentryNotes,
        };
        carpentryWorkOrderArray.push(carpentryWorkOrder);
      }
    }

    //setting Values to Project
    project.carpentryWorkOrder = carpentryWorkOrderArray;

    //PaintOrder

    const address = `${projectInfo.clientName}, ${
      projectInfo.streetAddressOne
    }, ${
      projectInfo.streetAddressTwo ? projectInfo.streetAddressTwo + ", " : ""
    }${projectInfo.city}, ${projectInfo.state}, ${projectInfo.zip}, ${
      projectInfo.country
    }`;

    let paintMaterials: any = {};
    let primerMaterials: any = {};
    for (const option of paintWorkOrderArray) {
      for (const paintRate of option.paintRateId) {
        let paintKey = `${paintRate.paintMaterial},${paintRate.color}`;
        if (paintMaterials[paintKey]) {
          paintMaterials[paintKey] += paintRate.paintGallons;
        } else {
          paintMaterials[paintKey] = paintRate.paintGallons;
        }
        let primerKey = `${paintRate.primerMaterial}`;
        if (primerMaterials[primerKey]) {
          primerMaterials[primerKey] += paintRate.primerGallons;
        } else {
          primerMaterials[primerKey] = paintRate.primerGallons;
        }
      }
    }

    let paintInfo = [];
    let paintKeys: any = Object.entries(paintMaterials);
    for (const item of paintKeys) {
      let newKeys = item[0].split(",");
      if (newKeys[0] !== "") {
        paintInfo.push({
          product: newKeys[0],
          color: newKeys[1],
          paintGallons: Math.ceil(item[1]),
          orderPaintGallons: 0,
        });
      }
    }

    let vendorName = paintInfo[0]?.product?.split(":")[0];
    let foundObj = adminDefaults.vendorDefaults.find((obj: any) => {
      return obj.prefix.includes(vendorName);
    });
    // let vendor;
    // if (vendorName === "SW") {
    //   vendor = adminDefaults.vendorDefaults.find(
    //     (item: any) => item.vendorName === "SW"
    //   );
    // } else {
    //   vendor = adminDefaults.vendorDefaults.find(
    //     (item: any) => item.vendorName === "PPG"
    //   );
    // }

    let primerInfo = [];
    let primerKeys: any = Object.entries(primerMaterials);
    for (const item of primerKeys) {
      if (item[0] !== "") {
        primerInfo.push({
          primer: item[0],
          primeGallons: Math.ceil(item[1]),
          orderPrimeGallons: 0,
        });
      }
    }

    const paintOrder = {
      project: projectInfo._id,
      accountNumber: foundObj?.accountNumber,
      jobSiteAddress: address,
      // prc: prePresentationChecklist?.activePrcDescription || '',
      paintInfo: paintInfo,
      primerInfo: primerInfo,
    };

    //setting Values to Project
    project.paintOrder = paintOrder;

    // Wood Order
    let carpentryMaterial: any = {};
    for (const option of carpentryWorkOrderArray) {
      for (const carpentryRate of option.carpentryRateId) {
        let carpentryKey = `${carpentryRate.item}`;
        if (carpentryMaterial[carpentryKey]) {
          carpentryMaterial[carpentryKey] += carpentryRate.count;
        } else {
          carpentryMaterial[carpentryKey] = carpentryRate.count;
        }
      }
    }

    let productInfo = [];
    let keys = Object.entries(carpentryMaterial);
    for (const item of keys) {
      productInfo.push({ product: item[0], totalLfOrSf: item[1] });
    }

    const carpentryOrder = {
      project: projectInfo._id,
      productInfo: productInfo,
    };

    //setting Values to Project
    project.woodOrder = carpentryOrder;

    return project;
  }

  project.pjcc = [];
  project.paintWorkOrder = [];
  project.carpentryWorkOrder = [];
  project.paintOrder = {};
  project.woodOrder = {};
  project.projectInfo.productionPjcc = [];
  project.projectInfo.discounts = [];
  project.projectInfo.overRideGrossMargin = [];

  return project;
};
