import {
  LOGIN_SUCCESS,
  UPDATE_USER,
  LOGIN_FAIL,
  LOGOUT,
  LOGIN_LOADING,
} from "./types";
const user = localStorage.getItem("user");
const initialState = user
  ? { isLoggedIn: true, isLoading: false, user }
  : { isLoggedIn: false, isLoading: false, user: null };
export default function (state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoggedIn: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}
