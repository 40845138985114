import axios, { Method } from "axios";

import { getAuthUser } from "./authToken";

const defaults = {
  baseURL: process.env.REACT_APP_BACKEND_URL || "http://localhost:3000/",
  headers: () => {
    const user = getAuthUser();

    return {
      "Content-Type": "application/json",
      Authorization: user.accessToken ? "Bearer " + user.accessToken : "",
    };
  },
  error: {
    code: "INTERNAL_ERROR",
    message:
      "Something went wrong. Please check your internet connection or contact our support.",
    status: 503,
    data: {},
  },
};

const api = (url: string, variables: any, method: Method) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${defaults.baseURL}${url}`,
      method,
      headers: defaults.headers(),
      params: method === "get" ? variables : undefined,
      data: method !== "get" ? variables : undefined,
    }).then(
      (response: any) => {
        resolve(response.data);
      },
      (error: any) => {
        reject(defaults.error);
      }
    );
  });

export default {
  get: (url: string, variables?: any, method?: any) =>
    api(url, variables, "get"),
  post: (url: string, variables: any, method?: any) =>
    api(url, variables, "post"),
  put: (url: string, variables: any, method?: any) =>
    api(url, variables, "put"),
  patch: (url: string, variables: any, method?: any) =>
    api(url, variables, "patch"),
  delete: (url: string, variables?: any, method?: any) =>
    api(url, variables, "delete"),
};
