import { useEffect, useState } from "react";
import { Row, Spin, Table } from "antd";
import "../PaymentContractor.less";
import { useHistory } from "react-router-dom";
import api from "../../../utils/api";
import moment from "moment";
import { numberWithCommas } from "../../../utils/helpers";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setAdminDefaults,
  setProjectDataContractor,
} from "../../../redux/project/action";

type Props = {};

const PaymentLogsC = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { items } = useSelector((state: RootStateOrAny) => state.offlineData);
  const dispatch = useDispatch();
  const [payments, setPayments] = useState<any>();

  useEffect(() => {
    setLoading(true);
    api.get(`check-request/payment-logs`).then((resp: any) => {
      setPayments(resp);
    });
    api.get("projects/all-defaults").then((resp: any) => {
      dispatch(setAdminDefaults(resp));
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      width: "20%",
      render: (data: string) => (
        <div>{moment(data).format("MMMM DD, YYYY")}</div>
      ),
    },
    {
      title: "Project Name",
      width: "30%",
      render: (data: any) => <div>{data.project?.jobName}</div>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "30%",
      render: (data: string) => <div>${numberWithCommas(+data)}</div>,
    },
    {
      title: "Action",
      key: "action",

      render: (data: any) => {
        return (
          <a
            className="ant-dropdown-link"
            onClick={async () => {
              await api
                .get(`projects/${data.project._id}`)
                .then((resp: any) => {
                  dispatch(setProjectDataContractor(resp));
                  history.push(
                    `/contractor-payment-record/${data.project._id}`
                  );
                });
            }}
          >
            View Details
          </a>
        );
      },
    },
  ];

  return (
    <>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div className="contractor-payment-root">
          <div className="contractor-payment-title-padding">
            <h1 className="payment-record-title">Payment Logs</h1>
          </div>
          <hr style={{ border: "1px solid #E8E8E8" }} />

          <div className="payment-table-content " style={{ marginTop: "40px" }}>
            <Table
              scroll={{ x: 900 }}
              rowKey="_id"
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? "table_row table-row-light"
                  : "table_row table-row-dark"
              }
              columns={columns}
              dataSource={payments}
              pagination={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentLogsC;
