import React, { useState } from "react";
import { API_BASE } from "../../constant";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Card, Row, Col, Form, Input, Button, Spin } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { CertaproIcon } from "../../utils/icons";
import axios from "axios";
import logo from "../../assets/images/Pro-Driven.jpg";
import "./Login.less";
import { useHistory } from "react-router-dom";
import { login } from "../../redux/user/action";
import { useMediaQuery } from "react-responsive";

const Login = () => {
  const dispatch = useDispatch<any>();
  const [invalidSignIn, setInvalidSignIn] = useState("");
  const auth = useSelector((state: RootStateOrAny) => state.auth);
  const { isLoading } = auth;
  const isLoggedIn = localStorage.getItem("user") !== null;
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:400px)" });

  const switchRoute = (role: string) => {
    switch (role) {
      case "admin":
        history.push("/admin-material-default");
        break;
      case "contractor":
        history.push("/contractor-job-board");
        break;
      default:
        history.push("/");
        break;
    }
  };

  if (isLoggedIn) {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    switchRoute(user.roles[0]);
  }

  const onFinish = (values: any) => {
    dispatch(login(values.email, values.password))
      .then(() => {
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        switchRoute(user.roles[0]);
      })
      .catch((error: string) => {
        setInvalidSignIn(error);
      });
  };
  const handleReset = () => {
    history.push("/search-email");
  };

  return (
    <>
      {isTabletOrMobile ? (
        <>
          {" "}
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div className="background-container">
              <Row justify="center">
                <Col>
                  <div className="certapro-logo">
                    <img
                      style={{
                        height: 100,
                        marginLeft: 0,
                      }}
                      src={logo}
                      alt="logo"
                    />
                    {/* <CertaproIcon style={{ width: 400, height: 85 }} /> */}
                  </div>

                  <Card
                    className="login-card"
                    style={{ width: "350px", margin: "auto" }}
                  >
                    <Form
                      onFinish={onFinish}
                      scrollToFirstError
                      layout="vertical"
                    >
                      <h1 className="login-title">Log In to your account</h1>
                      <div
                        className="input-container"
                        style={{ marginTop: "30px" }}
                      >
                        <div>
                          <Form.Item
                            label={<h1 className="input-title">Email</h1>}
                            name="email"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter your valid email address!",
                                type: "email",
                              },
                            ]}
                          >
                            <Input className="input-field" />
                          </Form.Item>
                          <Form.Item
                            label={<h1 className="input-title">Password</h1>}
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your password!",
                              },
                            ]}
                          >
                            <Input.Password className="input-field" />
                          </Form.Item>
                        </div>

                        <div className="login-btn-container">
                          {invalidSignIn && (
                            <p style={{ color: "red" }}>{invalidSignIn}</p>
                          )}

                          <div>
                            <Form.Item>
                              <Button
                                type="link"
                                className="forget-btn"
                                onClick={handleReset}
                              >
                                Forgotten Password?
                              </Button>
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                className="login-btn "
                                htmlType="submit"
                              >
                                Log In
                              </Button>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </div>
          )}{" "}
        </>
      ) : (
        <>
          {" "}
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <div className="background-container">
              <Row justify="center">
                <Col>
                  <div className="certapro-logo">
                    <img
                      style={{
                        height: 100,
                        marginLeft: 0,
                      }}
                      src={logo}
                      alt="logo"
                    />
                    {/* <CertaproIcon style={{ width: 400, height: 85 }} /> */}
                  </div>

                  <Card className="login-card">
                    <Form
                      onFinish={onFinish}
                      scrollToFirstError
                      layout="vertical"
                    >
                      <h1 className="login-title">Log In to your account</h1>
                      <div
                        className="input-container"
                        style={{ marginTop: "30px" }}
                      >
                        <div>
                          <Form.Item
                            label={<h1 className="input-title">Email</h1>}
                            name="email"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter your valid email address!",
                                type: "email",
                              },
                            ]}
                          >
                            <Input className="input-field" />
                          </Form.Item>
                          <Form.Item
                            label={<h1 className="input-title">Password</h1>}
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your password!",
                              },
                            ]}
                          >
                            <Input.Password className="input-field" />
                          </Form.Item>
                        </div>

                        <div className="login-btn-container">
                          {invalidSignIn && (
                            <p style={{ color: "red" }}>{invalidSignIn}</p>
                          )}

                          <div>
                            <Form.Item>
                              <Button
                                type="link"
                                className="forget-btn"
                                onClick={handleReset}
                              >
                                Forgotten Password?
                              </Button>
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                className="login-btn "
                                htmlType="submit"
                              >
                                Log In
                              </Button>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </div>
          )}{" "}
        </>
      )}
    </>
  );
};

export default Login;
