/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Layout, Row, Col } from "antd";
import OnsiteChecklist from "../../containers/on-site-checklist/OnsiteChecklist";
import Notes from "../../containers/notes/Notes";
import { Spin } from "antd";
import ManagerSteps from "./ManagerSteps";
import useActiveProject from "../../utils/useActiveProject";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { useParams } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import axios from "axios";
import { API_BASE } from "../../constant";
import { getAuthUser } from "../../utils/authToken";
const { Content } = Layout;

const ManagerChecklists: React.FC = () => {
  useActiveProject();
  useEffect(() => {
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);
  const { id } = useParams<any>();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const [isLoading] = useSyncProjectData(id);
  const [loading, setLoading] = useState(true);
  const user = getAuthUser();
  const [waterSourceLocationPhotoList, setWaterSourceLocationPhotoList] = useState<any>([]);
  const [electricalSourceLocationPhotoList, setElectricalSourceLocationPhotoList] = useState<any>([]);


  useEffect(() => {
    if (online) {
      axios
        .get(`${API_BASE}upload-files/onsite-media/${id}`, {
          headers: { Authorization: "Bearer " + user.accessToken },
        })
        .then((resp) => {
          let waterMedia: any[] = [];
          let electricMedia: any[] = [];
          resp.data?.forEach((item: any) => {
            if (item.waterSourcePic) {
              waterMedia.push(item);
            }
            if (item.electricSourcePic) {
              electricMedia.push(item);
            }
          });

          setWaterSourceLocationPhotoList(waterMedia);
          setElectricalSourceLocationPhotoList(electricMedia);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [online]);

  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <ManagerSteps current={1} />
          <div className="steps-content">
            <OnsiteChecklist 
              path="manager-labor-rates"
              waterSourceLocationPhotoList={waterSourceLocationPhotoList}
              setWaterSourceLocationPhotoList={setWaterSourceLocationPhotoList}
              electricalSourceLocationPhotoList={electricalSourceLocationPhotoList}
              setElectricalSourceLocationPhotoList={setElectricalSourceLocationPhotoList}
              loading={loading}
            />
          </div>
        </Content>
      )}
    </>
  );
};

export default ManagerChecklists;
