import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Row,
  Col,
  Card,
  Space,
  message,
  Modal,
  Input,
  Form,
  Spin,
} from "antd";
import Notes from "../../containers/notes/Notes";
import EditJobsite from "../../containers/preview-profile/EditJobsite";
import EditClient from "../../containers/preview-profile/EditClient";
import EditTracking from "../../containers/preview-profile/EditTracking";
import EditMedia from "../../containers/project-media/EditMedia";
import EditAssociatePeopleForm from "../../containers/preview-profile/EditAssociatePeopleForm";
import EmailModal from "../../containers/email-template-modal/EmailModal";

import { useHistory, useParams } from "react-router-dom";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { sendToEstimator, setActiveProject } from "../../redux/project/action";

import ManagerSteps from "./ManagerSteps";
import { setSelectedAssociates } from "../../redux/associatePeople/action";
import moment from "moment";
import api from "../../utils/api";
import { isEmpty } from "lodash";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { API_BASE } from "../../constant";

const { Content } = Layout;

const ManagerPreviewProfile: React.FC = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1150px)" });

  const history = useHistory();
  const { id } = useParams<any>();
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { online } = useSelector((state: RootStateOrAny) => state.offline);

  const [showModal, setShowModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailRecipients, setEmailRecipients] = useState<string>("");
  const [loading, setLoading] = useState(true);
  let [fileList, setFileList] = useState<any>([]);
  const [pageNo, setPageNo] = useState(1);
  const [showButton, setShowButton] = useState(false);
  const [isLoading] = useSyncProjectData(id);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!isEmpty(items)) {
      console.log(items);

      const activeProjectIndex = items.findIndex(
        (project: any) => project.projectId === id
      );
      console.log(activeProjectIndex);
      if (activeProjectIndex >= 0) {
        dispatch(setActiveProject(activeProjectIndex));
        setAssociates(items[activeProjectIndex]);
      }
    }
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    if (online) {
      axios
        .get(`${API_BASE}upload-files/media-document/${id}`, {
          headers: { Authorization: "Bearer " + user.accessToken },
        })
        .then((resp) => {
          if (resp.data.length < 10) {
            setShowButton(false);
          } else {
            setShowButton(true);
          }
          setFileList(resp.data);
          setLoading(false);
          setPageNo(2);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, []);

  const next = () => {
    history.push(
      `/manager-on-site-checklists/${items[currentProject]?.projectId}`
    );
  };

  const setAssociates = (project: any) => {
    dispatch(
      setSelectedAssociates({
        salesAssociate: project.projectInfo?.salesAssociate,
        estimator: project.projectInfo?.estimator,
        productionAssociate: project.projectInfo?.productionAssociate,
      })
    );
  };
  const handleSendToEstimator = () => {
    setEmailSubject(
      `Estimate Request - ${items[currentProject]?.projectInfo?.jobName}`
    );
    setEmailBody(
      `${items[currentProject].projectInfo?.estimator?.fullName
      },\n \nEstimate has been scheduled for ${moment(
        items[currentProject]?.projectInfo?.estimateScheduledDate
      ).format(
        "MMMM DD,YYYY"
      )}.\n\n For more information please visit https://cppindy.com/manager-preview-profile/${items[currentProject].projectInfo?._id
      }. \n\nThank you, \n\nRegards,\n${user?.fullName}`
    );

    setEmailRecipients(
      `${items[currentProject].projectInfo?.estimator?.email}`
    );
    setShowModal(true);
  };

  const handleSendEmail = (body: any) => {
    dispatch(sendToEstimator(id, body));
    message.success("Email Sent");
    setShowModal(false);
  };

  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <EmailModal
            showModal={showModal}
            setShowModal={setShowModal}
            emailSubject={emailSubject}
            emailBody={emailBody}
            emailRecipients={emailRecipients}
            sendEmail={handleSendEmail}
          />

          <ManagerSteps current={0} />
          <div className="steps-content">
            <div>
              <h1 className="profile-title">Profile</h1>
            </div>
            {isTabletOrMobile ? (
              <Row gutter={24}>
                <Col className="gutter-row" lg={24} sm={24}>
                  <EditJobsite />
                  <EditClient />
                  <EditTracking />
                  <Card className="root-container">
                    <div className="job-container form-container ">
                      <EditMedia
                        loading={loading}
                        setLoading={setLoading}
                        fileList={fileList}
                        setFileList={setFileList}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                        showButton={showButton}
                        setShowButton={setShowButton}
                      />
                    </div>
                  </Card>
                </Col>
                <Col className="gutter-row" lg={24} sm={24}>
                  <EditAssociatePeopleForm />
                  <Notes />
                </Col>
              </Row>
            ) : (
              <Row gutter={24}>
                <Col className="gutter-row" lg={15} sm={24}>
                  <EditJobsite />
                  <EditClient />
                  <EditTracking />
                  <Card className="root-container">
                    <div className="job-container form-container ">
                      <EditMedia
                        loading={loading}
                        setLoading={setLoading}
                        fileList={fileList}
                        setFileList={setFileList}
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                        showButton={showButton}
                        setShowButton={setShowButton}
                      />
                    </div>
                  </Card>
                </Col>
                <Col className="gutter-row" lg={9} sm={24}>
                  <EditAssociatePeopleForm />
                  <Notes />
                </Col>
              </Row>
            )}

            {isTabletOrMobile ? (
              <Row
                justify={"center"}
                gutter={25}
                align={"middle"}
                style={{ marginBottom: "20px" }}
              >
                <Col lg={5}></Col>
                <Col lg={15}>
                  <Button
                    type="primary"
                    className="save-button"
                    onClick={next}
                    style={{ marginRight: "25px" }}
                  >
                    Next
                  </Button>

                  <Button
                    type="primary"
                    className="save-button"
                    onClick={handleSendToEstimator}
                  >
                    Send to Estimator
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row justify={"center"} gutter={25} align={"middle"}>
                <Col lg={15}>
                  <Button
                    type="primary"
                    className="save-button"
                    onClick={next}
                    style={{ marginRight: "25px" }}
                  >
                    Next
                  </Button>

                  <Button
                    type="primary"
                    className="save-button"
                    onClick={handleSendToEstimator}
                  >
                    Send to Estimator
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </Content>
      )}
    </>
  );
};

export default ManagerPreviewProfile;
