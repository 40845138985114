import api from "../../utils/api";

const setAssociates = (payload: any) => ({
  type: "SET_ASSOCIATES",
  payload,
});

const setPayProcessing = (payload: any) => ({
  type: "SET_PAY_PROCESSING",
  payload,
});

const addPayProcessing = (payload: any) => ({
  type: "ADD_PAY_PROCESSING",
  payload,
});

const deletePayProcessing = (payload: any) => ({
  type: "DELETE_PAY_PROCESSING",
  payload,
});
const hideLoader = () => ({
  type: "HIDE_LOADER",
});

export const fetchAssociates = () => (dispatch: any) => {
  api
    .get("user/associates")
    .then((response: any) => {
      dispatch(setAssociates(response));
    })
    .catch((err) => {
      console.error(err);
    });
};

export const fetchPayProcessing = () => (dispatch: any) => {
  api
    .get("defaults/pay-processing")
    .then((response: any) => {
      dispatch(setPayProcessing(response[0].user));
      dispatch(hideLoader());
    })
    .catch((err) => {
      console.error(err);
      dispatch(hideLoader());
    });
};

export const addPayProcessingDefaults = (values: any) => (dispatch: any) => {
  api
    .post("defaults/pay-processing", { user: values._id })
    .then(() => {
      dispatch(addPayProcessing(values));
    })
    .catch((err) => {
      console.error(err);
    });
};

export const deletePayProcessingDefaults = (id: string) => (dispatch: any) => {
  api
    .delete(`defaults/pay-processing/${id}`)
    .then(() => {
      dispatch(deletePayProcessing(id));
    })
    .catch((err) => {
      console.error(err);
    });
};
