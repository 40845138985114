import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { syncProjectData } from "../redux/project/action";
import api from "./api";

const useSyncProjectData = (id: string, laborRatesScreen?: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const { online, outbox } = useSelector(
    (state: RootStateOrAny) => state.offline
  );

  useEffect(() => {
    if (online) {
      fetchdata()
    }
  }, [laborRatesScreen ? outbox.length : null]);

  const fetchdata = async () => {
    if (outbox.length === 0) {
      const url = `projects/${id}/${Object.keys(adminDefaults).length > 0 ? "false" : "true"
        }`;
      setIsLoading(true);
      api
        .get(url)
        .then((res) => {
          dispatch(syncProjectData(res));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  return [isLoading];
};

export default useSyncProjectData;
