import { Modal, Button, message } from "antd";
import "./AddItemDefaultModal.less";
import axios from "axios";
import * as XLSX from "xlsx";
import { API_BASE } from "../../../constant";
import { transformData } from "../../../utils/helpers";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAuthUser } from "../../../utils/authToken";
import { useState } from "react";
import "./JobsBulkEntry.less";

interface JobsBulkEntryProps {
  style: any;
  pageReload: any;
}

const JobsBulkEntry: React.FC<JobsBulkEntryProps> = ({ style, pageReload }) => {
  const [invalidJobsModal, setInvalidJobsModal] = useState(false);
  const [invalidJobNames, setInvalidJobNames] = useState([]);
  const [validJobNames, setValidJobNames] = useState([]);

  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const associatePeople = useSelector(
    (state: RootStateOrAny) => state.associatePeople
  );

  const onClose = () => {
    setInvalidJobsModal(!invalidJobsModal)
    if (invalidJobNames.length > 0 && validJobNames.length > 0) {
      pageReload()
    }
  };

  const handleAddXlsx = async (e: any) => {
    try {
      const user = getAuthUser();
      // Read the uploaded file
      const file: File = e.target.files[0];
      const excelData: string = await readFileAsync(file);
      // Parse Excel data
      const workbook: XLSX.WorkBook = XLSX.read(excelData, { type: 'binary' });
      const sheetName: string = workbook.SheetNames[0];
      const sheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
      const parsedData: any[] = XLSX.utils.sheet_to_json(sheet, {
        raw: false,
      });
      // Convert numeric date values to human-readable format
      parsedData.forEach(row => {
        if (row['Created On'] && typeof row['Created On'] === 'number') {
          row['Created On'] = new Date((row['Created On'] - (25567 + 1)) * 86400 * 1000).toLocaleString();
        }
        if (row['Start Time (Estimate Appointment) (Appointment)'] && typeof row['Start Time (Estimate Appointment) (Appointment)'] === 'number') {
          row['Start Time (Estimate Appointment) (Appointment)'] = new Date((row['Start Time (Estimate Appointment) (Appointment)'] - (25567 + 1)) * 86400 * 1000).toLocaleString();
        }
      });

      // Transform and clean the parsed data
      const cleanedData = transformData(parsedData, adminDefaults, associatePeople, user);

      const uniqueJobs = await axios.post(`${API_BASE}projects/filter-jobs`, cleanedData, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!uniqueJobs.data.length) {
        message.warning("All jobs in the uploaded file already exist.");
        return
      }

      const { validData, invalidData } = uniqueJobs.data.reduce(
        (acc: any, item: any) => {
          const missingFields = []; // Array to store missing fields
          // Check for missing fields
          if (!item.primaryContactName) missingFields.push("Primary Contact Name");
          if (!(item.primaryContactTelephone || item.primaryContactCell)) missingFields.push("Primary Contact Telephone or Primary Contact Cell");
          if (!item.primaryContactEmail) missingFields.push("Primary Contact Email");
          if (!item.primaryContactAddress) missingFields.push("Primary Contact Address");
          if (!item.projectType) missingFields.push("Project Type");
          if (!item.projectSubtype) missingFields.push("Project Subtype");
          if (!item.jobNumber) missingFields.push("Job Number");
          if (!item.jobName) missingFields.push("Job Name");
          if (!item.clientName) missingFields.push("Client Name");
          if (!item.streetAddressOne) missingFields.push("Street Address One");
          if (!item.city) missingFields.push("City");
          if (!item.state) missingFields.push("State");
          if (!item.zip) missingFields.push("Zip");
          if (!item.salesAssociate) missingFields.push("Sales Associate");
          if (!item.estimator) missingFields.push("Estimator");

          // If any missing fields, add job name and missing fields to invalidData
          if (missingFields.length > 0) {
            acc.invalidData.push({ jobName: item.jobName, missingFields });
          } else {
            acc.validData.push(item);
          }

          return acc;
        },
        { validData: [], invalidData: [] }
      );
      if (invalidData.length) {
        setInvalidJobsModal(true);
        setInvalidJobNames(invalidData);
      }

      if (validData.length) {
        setValidJobNames(validData);
      }

      const projectsArray = validData.map((entry: any) => {
        const newObj = { ...entry };
        delete newObj.proposalObject;
        return newObj;
      });

      const proposalsArray = validData.map((entry: any) => {
        const newObj = { ...entry.proposalObject };
        return newObj;
      });

      // // Make POST request to create projects with cleaned data
      if (projectsArray.length > 0) {
        await axios.post(`${API_BASE}projects/upload`, projectsArray, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        // // Make POST request to create proposal for each project
        await axios.post(`${API_BASE}proposal/bulk-create`, proposalsArray, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (validData.length > 0 && invalidData.length === 0) {
          message.success("All jobs were successfully created.");
          pageReload()

        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Utility function to read file asynchronously
  const readFileAsync = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e?.target?.result as string;
        resolve(result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  };

  return (
    <div>
      <Modal
        className="default-modal-rt"
        centered={true}
        visible={invalidJobsModal}
        onCancel={onClose}
        maskClosable={false}
        footer={[
          <div className="send-btn-container">
            <Button
              key="back"
              onClick={onClose}
              className="default-material-cancel-btn"
            >
              Ok
            </Button>
          </div>
        ]}
        title={
          <h1 style={{ marginTop: 10 }} className="default-modal-add-item">
            Project Upload Failed
          </h1>
        }
      >
        <div style={{ fontSize: '18px' }}>
          <span>Following projects were not uploaded because some of the required fields were missing:</span>
          {invalidJobNames.map((job: any, index) => (
            <div key={index}>
              <span >{job?.jobName}</span>
              <ul>
                {job?.missingFields.map((field: any, idx: number) => (
                  <li key={idx}>{field}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>

      </Modal>
      <div style={{ position: 'relative', overflow: 'hidden', display: 'inline-block' }}>
        <Button className={style} type="primary">
          Upload C1 View
        </Button>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleAddXlsx}
          style={{ position: 'absolute', top: 0, left: 0, opacity: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </div>
    </div>
  )
};

export default JobsBulkEntry;
