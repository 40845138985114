import React, { useRef, useState } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux';
import { Button, message, Modal, Upload } from 'antd';
import axios, { AxiosResponse } from 'axios';
import { API_BASE } from '../../constant';
import { getAuthUser } from '../../utils/authToken';
import { UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

interface ImageListProps {
    fileList: any[]
    setFileList: any
}

const AddMedia = (props: ImageListProps) => {
    const user = getAuthUser()
    const { items, currentProject } = useSelector(
        (state: RootStateOrAny) => state.offlineData
    );
    const { Dragger } = Upload;
    const { online } = useSelector((state: RootStateOrAny) => state.offline);
    const { id } = useParams<{ id: string }>();
    const [loading, setLoading] = useState(false)
    const [uploadModal, setUploadModal] = useState(false)
    const [modalClose, setModalClose] = useState(false)

    const readFileAsDataURL = (file: File): Promise<string> => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = async (customUploadEvent: any) => {
        let image;
        const selectedFile: File = customUploadEvent.file;
        if (selectedFile?.size <= 50000000) {
            const thumbUrl = await readFileAsDataURL(selectedFile);
            image = {
                name: selectedFile.name,
                file: selectedFile,
                thumbUrl,
            };
        } else {
            message.error("Max File Size Allowed is 50MB. Try Another file!");
        }
        handleUpload(image, customUploadEvent)
    };
    const base64ToBlob = (base64String: string, contentType: string) => {
        const sliceSize = 1024;
        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    const handleUpload = async (image: any, customUploadEvent: any) => {
        if (online) {
            setLoading(true)
            const preSignedUrls: AxiosResponse = await axios.post(`${API_BASE}upload-files/get-signed-put-url`, [image.file.name], {
                headers: {
                    Authorization: "Bearer " + user.accessToken,
                }
            })
            preSignedUrls.data.forEach((element: { key: string, url: string }) => {
                const base64Data = image.thumbUrl.split(",");
                const mimeType = base64Data[0].substring(
                    base64Data[0].indexOf(":") + 1,
                    base64Data[0].indexOf(";")
                );
                let blob = base64ToBlob(base64Data[1], mimeType);
                var options = {
                    onUploadProgress: (event: ProgressEvent) => {
                        const { loaded, total } = event;
                        customUploadEvent.onProgress(
                            {
                                percent: Math.round((loaded / total) * 100),
                            },
                            blob
                        );
                    },
                };
                axios.put(`${element.url}`, blob, options)
                    .then((result: any) => {
                        let body = {
                            key: element.key,
                            mimeType: image.file.type,
                            fileName: image.file.name,
                            project: items[currentProject]?.projectId,
                            option: id,
                            uploadedBy: user.id,
                            addToOption: true,
                        }
                        customUploadEvent.onSuccess(result, blob);
                        axios.post(`${API_BASE}upload-files/options-images-data/${items[currentProject]?.projectId}`, body,
                            {
                                headers: {
                                    Authorization: "Bearer " + user.accessToken,
                                }
                            },
                        ).then((response: any) => {
                            if (response.data) {
                                let newImage = {
                                    uid: response.data._id,
                                    name: response.data.fileName,
                                    addToOption: response.data.addToOption,
                                    thumbUrl: image.thumbUrl
                                }
                                props.setFileList((prevFileList: any) => [newImage, ...prevFileList]);
                                message.success(`${image.file.name} is uploaded sucessfully`)
                            }
                        })
                            .catch((err: any) => {
                                console.log(err);
                            });
                    })
            });
            setLoading(false)
        }
    };

    const handleCancel = () => {
        setModalClose(true);
        setUploadModal(false)
    }

    return (
        <>
            <Button
                icon={<UploadOutlined />}
                style={{ background: "#FDB913", border: "1px solid #FDB913", color: 'black' }}
                loading={loading}
                onClick={() => setUploadModal(true)}
            />
            <Modal
                open={uploadModal}
                title={"Upload Images"}
                footer={null}
                onCancel={handleCancel}
                destroyOnClose={modalClose}
            >
                <Dragger
                    multiple={true}
                    defaultFileList={[]}
                    accept='image/'
                    listType="picture"
                    headers={{ Authorization: "Bearer " + user.accessToken }}
                    customRequest={
                        handleFileChange
                    }
                    showUploadList={
                        {
                            showRemoveIcon: false,
                            showDownloadIcon: false,
                            showPreviewIcon: false
                        }
                    }
                    onPreview={() => false}
                    onRemove={() => false}
                >
                    <p className="drag-text">Drag your files here</p>
                    <p className="or-text">OR</p>
                    <div style={{ paddingTop: "20px", paddingBottom: "30px" }}>
                        <Button className="browse-button">Browse File</Button>
                    </div>
                </Dragger>
            </Modal>

        </>
    )
}

export default AddMedia