import { createStore, compose, applyMiddleware } from "redux";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import thunk from "redux-thunk";
import reducer from "./combineReducer";
import { encryptTransform } from "./encryptTransform";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// configure redux store
const composeEnhancers: any =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const presistOfflineStore = (render: any) => {
  offlineConfig.persistCallback = render;
  const config = {
    ...offlineConfig,
    persistOptions: {
      // add this in your offline config
      transforms: [encryptTransform], // use the encryptTransform here
    },
    discard: (error: any, action: any, retries: any) => retries >= 2,
  };
  return createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk), offline(config))
  );
};
