import { API_BASE } from "../../constant";
import axios from "axios";
import {
  setSelectedEstimator,
  setSelectedProductionAssociate,
  setSelectedSalesAssociate,
} from "../associatePeople/action";
import { getAuthUser } from "../../utils/authToken";
import {
  offlineAction,
  offlineActionDeleteWithCalculations,
  offlineActionWithCalculations,
} from "../../utils/helpers";
import { pricingCalculation } from "../../utils/priceCalculation";

export const clearOfflineData = () => ({
  type: "CLEAR_OFFLINE_DATA",
});

export const clearError = () => ({
  type: "CLEAR_ERROR",
});

export const setProjectItems = (payload: any) => ({
  type: "SET_PROJECTS",
  payload,
});

export const syncProjectData = (payload: any) => ({
  type: "SYNC_PROJECT_DATA",
  payload,
});

export const setProjectDataContractor = (payload: any) => ({
  type: "SET_PROJECT_DATA_CONTRACTOR",
  payload,
});

export const updateProposalCount = (payload: any) => ({
  type: "Update_PROPOSAL_COUNT",
  payload,
});

export const setAdminDefaults = (payload: any) => ({
  type: "SET_ADMIN_DEFAULTS",
  payload,
});

export const setCurrentProject = (payload: any) => ({
  type: "SET_PROJECT",
  payload,
});

export const setActiveProject = (payload: any) => ({
  type: "SET_SINGLE_PROJECT",
  payload,
});

export const addDiscount = (data: any) => {
  const user = getAuthUser();
  return {
    type: "ADD_DISCOUNT",
    payload: { data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}discounts`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "ADD_DISCOUNT_COMMIT", meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "POST",
              path: `discounts`,
            },
          },
        },
      },
    },
  };
};

export const editDiscount = (id: string, body: any) => {
  const user = getAuthUser();
  return {
    type: "EDIT_DISCOUNT",
    payload: { body },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}discounts/${id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "EDIT_DISCOUNT_COMMIT", meta: { body } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "PUT",
              path: `discounts`,
            },
          },
        },
      },
    },
  };
};

export const updateDiscount = (payload: any) => ({
  type: "UPDATE_DISCOUNT",
  payload,
});

export const updateCurrentProject = (payload: any) => ({
  type: "UPDATE_CURRENT_PROJECT",
  payload,
});

const projectsFetchPending = () => ({
  type: "PROJECTS_FETCH_PENDING",
});

export const projectFetchStarted = () => ({
  type: "PROJECT_FETCH_PENDING",
});

const errorFetchingProjects = () => ({
  type: "PROJECTS_FETCH_ERROR",
});

const projectFetchingDone = () => ({
  type: "CURRENT_PROJECT_FETCH_DONE",
});

export const setProjectProposal = (payload: any) => ({
  type: "SET_PROPOSAL",
  payload: payload,
});
export const updateProjectAndIndex = (payload: any) => ({
  type: "UPDATE_PROJECT_AND_INDEX",
  payload: payload,
});

export const setProjectAndIndex = (payload: any) => ({
  type: "SET_PROJECT_AND_INDEX",
  payload: payload,
});

//Labor Rates Screen
export const addLaborRatesToProject = (payload: any) => ({
  type: "ADD_LABOR_RATES_TO_PROJECT",
  payload: payload,
});

export const getProjects =
  () => (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const user = getAuthUser();
    return axios
      .get(`${API_BASE}projects/all-projects`, {
        headers: { Authorization: "Bearer " + user.accessToken },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch(errorFetchingProjects());
        return Promise.reject();
      });
  };

export const getAdminDefaults =
  () => (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const user = getAuthUser();
    return axios
      .get(`${API_BASE}projects/all-defaults`, {
        headers: { Authorization: "Bearer " + user.accessToken },
      })
      .then((response) => {
        dispatch(setAdminDefaults(response.data));
        return response.data;
      })
      .catch((err) => {
        console.error(err);
        dispatch(errorFetchingProjects());
        return Promise.reject();
      });
  };

export const addProject = (data: any) => {
  return offlineAction(
    data,
    "projects",
    "POST",
    "POST_PROJECT_REQUEST",
    "SET_PROJECT_COMMIT",
    "SET_ERROR"
  );
};

export const putOptionDetailsInfo = (payload: any) => {
  return offlineAction(
    payload,
    `options/${payload._id}`,
    "PUT",
    "PUT_OPTION_DETAILS_INFO",
    "PUT_OPTION_DETAILS_INFO_COMMIT",
    "SET_ERROR"
  );
};

export const handleUpdateImageCaption = (payload: any) => ({
  type: "REQUEST_UPDATE_OPTION_IMG_CAPTIONS",
  payload,
});

export const handleAddCaption = (values: any) => {
  console.log("Values for Body", values);
  return offlineAction(
    values,
    `upload-files/option-media-caption`,
    "PATCH",
    "REQUEST_UPDATE_OPTION_IMG_CAPTIONS",
    "COMMIT_UPDATED_OPTION_IMG_CAPTIONS",
    "SET_ERROR"
  );
};

export const updateOptionDetails = (payload: any, payloadForRedux: any) => {
  const user = getAuthUser();
  return {
    type: "UPDATE_OPTION_DETAILS",
    payload: { payloadForRedux },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}options/${payload._id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(payload),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "UPDATE_OPTION_COMMIT",
          meta: { payload },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: { payload, method: "PUT", path: `options/${payload._id}` },
          },
        },
      },
    },
  };
};

export const postPrePresentationChecklist = (payload: any) => {
  return offlineAction(
    payload,
    "pre-presentation-checklist",
    "POST",
    "POST_PRE_PRESENTATION_CHECKLIST",
    "POST_PRE_PRESENTATION_CHECKLIST_COMMIT",
    "SET_ERROR"
  );
};

export const updatePrePresentationChecklist = (data: any) => {
  const user = getAuthUser();
  const body = {
    ...data,
    crew: data.crew?._id,
  };
  return {
    type: "POST_PRE_PRESENTATION_CHECKLIST",
    payload: { data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}pre-presentation-checklist/${data._id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "POST_PRE_PRESENTATION_CHECKLIST_COMMIT",
          meta: { data },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "PUT",
              path: `pre-presentation-checklist/${data._id}`,
            },
          },
        },
      },
    },
  };
};

export const addOnsiteChecklistOtherAnswers = (payload: any) => {
  return offlineAction(
    payload,
    "onsite-checklist-answers",
    "POST",
    "ADD_ONSITE_CHECKLIST_ANSWERS_OTHER",
    "ADD_ONSITE_CHECKLIST_ANSWERS_OTHER_COMMIT",
    "SET_ERROR"
  );
};

export const addOnsiteChecklistToProject = (payload: any) => {
  return offlineAction(
    payload,
    "onsite-checklist-answers",
    "POST",
    "POST_UPDATE_ONSITE_CHECKLIST_ANSWERS",
    "POST_ONSITE_CHECKLIST_ANSWERS_COMMIT",
    "SET_ERROR"
  );
};

export const updateOnsiteChecklistToProject = (payload: any) => {
  return offlineAction(
    payload,
    "onsite-checklist-answers",
    "PUT",
    "POST_UPDATE_ONSITE_CHECKLIST_ANSWERS",
    "UPDATE_ONSITE_CHECKLIST_ANSWERS_COMMIT",
    "SET_ERROR"
  );
};

export const postCheckRequest = (payload: any) => {
  return offlineAction(
    payload,
    "check-request",
    "POST",
    "POST_CHECK_REQUEST",
    "POST_CHECK_REQUEST_COMMIT",
    "SET_ERROR"
  );
};

export const sendCheckRequestEmail = (payload: any) => {
  const user = getAuthUser();
  return {
    type: "",
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}check-request/send-email`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "", meta: { payload } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              payload,
              method: "POST",
              path: `check-request/send-email`,
            },
          },
        },
      },
    },
  };
};

export const uploadProposalFile = (id: string, payload: any) => {
  const user = getAuthUser();
  return {
    type: "",
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}upload-files/single/pdf/${id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(payload),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "", meta: { payload } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              payload,
              method: "POST",
              path: `upload-files/single/pdf/${payload?.id}`,
            },
          },
        },
      },
    },
  };
};

export const updateCheckRequest = (payload: any) => {
  return offlineAction(
    payload,
    `check-request/${payload.project}`,
    "PATCH",
    "PATCH_CHECK_REQUEST",
    "PATCH_CHECK_REQUEST_COMMIT",
    "SET_ERROR"
  );
};

export const updateProjectById = (updates: any) => {
  return offlineAction(
    updates,
    `projects/${updates._id}`,
    "PUT",
    "UPDATE_PROJECT_REQUEST",
    "UPDATE_PROJECT_COMMIT",
    "SET_ERROR"
  );
};

export const updateProjectStateById = (body: any) => {
  return offlineAction(
    body,
    `projects/project-state/${body._id}`,
    "PATCH",
    "UPDATE_PROJECT_STATE_REQUEST",
    "UPDATE_PROJECT_STATE_COMMIT",
    "SET_ERROR"
  );
};

export const updateProjectCheckRequestRetainerPercentByProjectId = (
  body: any
) => {
  return offlineAction(
    body,
    `projects/project-check-req-retainer-percent/${body.projectId}`,
    "PATCH",
    "UPDATE_PROJECT_CHECK_REQUEST_RETAINER_PERCENT_REQUEST",
    "UPDATE_PROJECT_CHECK_REQUEST_RETAINER_PERCENT_COMMIT",
    "SET_ERROR"
  );
};

export const updateProjectAndPjccByProjectId = (
  updates: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);

  return {
    type: "UPDATE_PROJECT_AND_PJCC_REQUEST",
    payload: { newProject },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}projects/${updates._id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(updates),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "UPDATE_PROJECT_AND_PJCC_COMMIT",
          meta: { updates },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              updates,
              method: "PUT",
              path: `projects/${updates._id}`,
            },
          },
        },
      },
    },
  };
};

export const updateProjectOnNewOption = (body: any) => {
  const user = getAuthUser();
  return {
    type: "UPDATE_PROJECT_OVERRIDE_GROSSMARGIN_REQUEST",
    payload: { body },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}projects/override-gm/${body._id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "UPDATE_PROJECT_OVERRIDE_GROSSMARGIN_COMMIT",
          meta: { body },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "PUT",
              path: `projects/override-gm/${body._id}`,
            },
          },
        },
      },
    },
  };
};

export const updateProjectDiscountAndGm = (
  body: any,
  projectId: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  console.log(newProject.pjcc);
  const reduxBody = {
    newProject,
    projectId,
  };
  return {
    type: "UPDATE_PROJECT_DISCOUNT_REQUEST",
    payload: { reduxBody },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}projects/discounts-and-gm/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "UPDATE_PROJECT_DISCOUNT_COMMIT", meta: { body } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "PUT",
              path: `projects/discounts-and-gm/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const updateSingleEntityOfProjectGrossMargin = (
  body: any,
  projectId: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  console.log(newProject.pjcc);
  const reduxBody = {
    newProject,
    projectId,
  };
  return {
    type: "UPDATE_SINGLE_ENTITY_OF_PROJECT_GROSS_MARGIN",
    payload: { reduxBody },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}projects/update-single-gm/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "UPDATE_SINGLE_ENTITY_OF_PROJECT_GROSS_MARGIN",
          meta: { body },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "PUT",
              path: `projects/update-gm/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const removeSingleEntityOfProjectOverRideGrossMargin = (
  body: any,
  projectId: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  console.log(newProject.pjcc);
  const reduxBody = {
    newProject,
    projectId,
  };
  return {
    type: "REMOVE_SINGLE_ENTITY_OF_PROJECT_GROSS_MARGIN",
    payload: { reduxBody },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}projects/remove-single-gm/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "REMOVE_SINGLE_ENTITY_OF_PROJECT_GROSS_MARGIN",
          meta: { body },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "PUT",
              path: `projects/update-gm/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const updateProjectGrossMargin = (
  body: any,
  projectId: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  console.log(newProject.pjcc);
  const reduxBody = {
    newProject,
    projectId,
  };
  return {
    type: "UPDATE_PROJECT_GROSS_MARGIN",
    payload: { reduxBody },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}projects/update-gm/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "UPDATE_PROJECT_GROSS_MARGIN", meta: { body } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "PUT",
              path: `projects/update-gm/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const addNotes = (payload: any) => {
  return offlineAction(
    payload,
    "notes",
    "POST",
    "ADD_NOTES_SUCCESS",
    "SET_NOTES_COMMIT",
    "SET_ERROR"
  );
};

export const undoDeleteOnProjectRates = (projectId: string, data: any) => {
  const user = getAuthUser();
  return {
    type: "PATCH_UNDO_DELETE_SERVICE_TO_PROJECT_REQUEST",
    payload: { projectId, data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}rates/project/undo-delete/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "SET_UNDO_DELETE_SERVICE_TO_PROJECT_COMMIT",
          meta: { data },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "PATCH",
              path: `rates/project/undo-delete/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const addServiceToProject = (projectId: string, data: any) => {
  const user = getAuthUser();
  return {
    type: "POST_SERVICE_TO_PROJECT_REQUEST",
    payload: { projectId, data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}rates/project/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "SET_SERVICE_TO_PROJECT_COMMIT", meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "POST",
              path: `rates/project/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const bulkUpdateRates = (
  data: any,
  id: string,
  project: any,
  adminDefaults: any
) => {
  return offlineActionWithCalculations(
    data,
    `rates/project/${id}`,
    "PATCH",
    "PATCH_SERVICE_TO_PROJECT_REQUEST",
    "SET_SERVICE_TO_PROJECT_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const projectRateDeleteAndOptionRatesUpdate = (
  data: any,
  id: string,
  project: any,
  adminDefaults: any
) => {
  return offlineActionWithCalculations(
    data,
    `rates/project-new-edit-when-rate-exists/${id}`,
    "POST",
    "POST_NEW_SERVICE_TO_PROJECT_OPTIONS_RATE_REQUEST",
    "SET_NEW_SERVICE_TO_PROJECT_OPTIONS_RATE_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const projectAndOptionRatesNewUpdate = (
  data: any,
  id: string,
  project: any,
  adminDefaults: any
) => {
  return offlineActionWithCalculations(
    data,
    `rates/project-new-edit/${id}`,
    "POST",
    "POST_NEW_SERVICE_TO_PROJECT_REQUEST",
    "SET_NEW_SERVICE_TO_PROJECT_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const updateProjectPaintMaterials = (
  projectId: string,
  body: any,
  project: any,
  adminDefaults: any
) => {
  return offlineActionWithCalculations(
    body,
    `material/project-material/${projectId}`,
    "PUT",
    "PUT_PROJECT",
    "PUT_PROJECT_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const removeProjectMaterialFromProject = (
  id: string,
  project: any,
  adminDefaults: any
) => {
  return offlineActionDeleteWithCalculations(
    id,
    `material/project-material/${id}`,
    "DELETE",
    "PUT_PROJECT",
    "PUT_PROJECT_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const removeRatesFromProjectAndOptions = (data: any, projectId: any) => {
  const user = getAuthUser();
  return {
    type: "DELETE_RATES_FROM_PROJECT_AND_OPTION_REQUEST",
    payload: { data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}rates/multiple-rates/project/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "DELETE",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "DELETE_RATES_FROM_PROJECT_AND_OPTION_COMMIT",
          meta: { data },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "DELETE",
              path: `rates/project/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const removeRatesFromOptionsWithIds = (data: any, projectId: any) => {
  const user = getAuthUser();
  return {
    type: "DELETE_RATES_FROM_OPTION_WITH_IDS_REQUEST",
    payload: { data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}rates/multiple-rates/options/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "DELETE",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: "DELETE_RATES_FROM_OPTION_WITH_IDS_COMMIT",
          meta: { data },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "DELETE",
              path: `rates/project/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const removeServiceFromProject = (
  id: string,
  category: string,
  project: any,
  adminDefaults: any
) => {
  return offlineActionDeleteWithCalculations(
    id,
    `rates/project/${category}/${id}`,
    "DELETE",
    "DELETE_SERVICE_FROM_PROJECT_REQUEST",
    "DELETE_SERVICE_FROM_PROJECT_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const removeServiceFromProjectOnly = (
  id: string,
  category: string,
  project: any,
  adminDefaults: any
) => {
  return offlineActionDeleteWithCalculations(
    id,
    `rates/project-only/${category}/${id}`,
    "DELETE",
    "DELETE_SERVICE_FROM_PROJECT_ONLY_REQUEST",
    "DELETE_SERVICE_FROM_PROJECT_ONLY_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const addPlusUpdateModifiedProject = (id: string, data: any) => {
  return offlineAction(
    data,
    `rates/project-modified-rates/${id}`,
    "POST",
    "POST_UPDATE_AND_ADD_MODIFIED_PROJECT_RATES_REQUEST",
    "SET_UPDATE_AND_ADD_MODIFIED_PROJECT_RATES_COMMIT",
    "SET_ERROR"
  );
};

export const addPlusUpdateModifiedProjectRateAndOptionRate = (
  id: string,
  data: any
) => {
  return offlineAction(
    data,
    `rates/project-modified-rates-and-option-rates/${id}`,
    "POST",
    "POST_UPDATE_OR_ADD_MODIFIED_PROJECT_RATES_AND_ADD_OPTION_RATES_REQUEST",
    "SET_UPDATE_OR_ADD_MODIFIED_PROJECT_RATES_AND_ADD_OPTION_RATES_COMMIT",
    "SET_ERROR"
  );
};

export const addRatesToOptions = (
  data: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  return {
    type: "POST_RATES_TO_OPTION_REQUEST",
    payload: { newProject },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}rates/option`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "SET_RATES_TO_OPTION_COMMIT", meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: { data, method: "POST", path: `rates/option` },
          },
        },
      },
    },
  };
};

export const removeRatesFromOptions = (
  data: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  return {
    type: "DELETE_RATES_FROM_OPTION_REQUEST",
    payload: { newProject },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}rates/option/${data.category}/${data.id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "DELETE",
          // body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "DELETE_RATES_FROM_OPTION_COMMIT", meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "DELETE",
              path: `rates/option/${data.category}/${data.id}`,
            },
          },
        },
      },
    },
  };
};

export const addOptionToProject = (
  data: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  return {
    type: "POST_OPTION_TO_PROJECT_REQUEST",
    payload: { newProject },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}options`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "SET_OPTION_TO_PROJECT_COMMIT", meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: { data: { data, method: "POST", path: `options` } },
        },
      },
    },
  };
};

export const cloneOptionToProject = (
  data: any,
  project: any,
  adminDefaults: any
) => {
  const user = getAuthUser();
  var newProject = pricingCalculation(project, adminDefaults);
  return {
    type: "POST_OPTION_CLONE_TO_PROJECT_REQUEST",
    payload: { newProject },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}options/clone`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "SET_OPTION_CLONE_TO_PROJECT_COMMIT", meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: { data: { data, method: "POST", path: `options` } },
        },
      },
    },
  };
};

export const sendMail = (body: any, url: string) => {
  const user = getAuthUser();
  return {
    type: "SEND_VENDOR_EMAIL",
    payload: {},
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}${url}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "SEND_VENDOR_EMAIL_COMMIT", meta: {} },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: { data: { body, method: "POST", path: `${url}` } },
        },
      },
    },
  };
};

export const deleteOptionFromProject = (
  id: string,
  project: any,
  adminDefaults: any
) => {
  return offlineActionDeleteWithCalculations(
    id,
    `options/${id}`,
    "DELETE",
    "DELETE_OPTION_FROM_PROJECT_REQUEST",
    "DELETE_OPTION_FROM_PROJECT_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const updateEstimateChecklist = (payload: any) => {
  return offlineAction(
    payload,
    `projects/${payload.id}`,
    "PUT",
    "UPDATE_ESTIMATE_CHECKLIST",
    "UPDATE_ESTIMATE_CHECKLIST_COMMIT",
    "SET_ERROR"
  );
};

export const addNotesGroup = (payload: any) => {
  return offlineAction(
    payload,
    `options/${payload.id}`,
    "PUT",
    "UPDATE_ESTIMATE_CHECKLIST",
    "UPDATE_ESTIMATE_CHECKLIST_COMMIT",
    "SET_ERROR"
  );
};

export const updateOptionFromProject = (
  data: any,
  project: any,
  adminDefaults: any
) => {
  return offlineActionWithCalculations(
    data,
    `options/update/included`,
    "PUT",
    "PUT_OPTION_TO_PROJECT_REQUEST",
    "SET_OPTION_TO_PROJECT_COMMIT",
    "SET_ERROR",
    project,
    adminDefaults
  );
};

export const sendToEstimator = (id: any, data: any) => {
  const user = getAuthUser();
  return {
    type: "POST_EMAIL_TO_ESTIMATOR_REQUEST",
    payload: { data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}projects/send-email/${id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "SET_EMAIL_TO_ESTIMATOR_COMMIT", meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              data,
              method: "POST",
              path: `projects/send-email/${id}`,
            },
          },
        },
      },
    },
  };
};

export const getProjectByIdOffline =
  (data: any) =>
  (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    dispatch(setCurrentProject(data));
    // dispatch(setActiveProject(data));
    dispatch(
      setSelectedSalesAssociate({
        key: data.salesAssociate._id,
        picture: data.salesAssociate.picture,
        value: data.salesAssociate.fullName,
      })
    );
    if (data.estimator !== undefined) {
      dispatch(
        setSelectedEstimator({
          key: data.estimator._id,
          picture: data.estimator.picture,
          value: data.estimator.fullName,
        })
      );
    }
    if (data.productionAssociate !== undefined) {
      dispatch(
        setSelectedProductionAssociate({
          key: data.productionAssociate._id,
          picture: data.productionAssociate.picture,
          value: data.productionAssociate.fullName,
        })
      );
    }
    dispatch(projectFetchingDone());
  };

export const getProjectById =
  (id: string) =>
  (dispatch: (arg0: { type: string; payload?: any }) => void) => {
    const user = getAuthUser();
    axios
      .get(`${API_BASE}projects/${id}`, {
        headers: { Authorization: "Bearer " + user.accessToken },
      })
      .then((response) => {
        dispatch(setCurrentProject(response.data));
        dispatch(
          setSelectedSalesAssociate({
            key: response.data.salesAssociate._id,
            picture: response.data.salesAssociate.picture,
            value: response.data.salesAssociate.fullName,
          })
        );
        if (response.data.estimator !== undefined) {
          dispatch(
            setSelectedEstimator({
              key: response.data.estimator._id,
              picture: response.data.estimator.picture,
              value: response.data.estimator.fullName,
            })
          );
        }
        if (response.data.productionAssociate !== undefined) {
          dispatch(
            setSelectedProductionAssociate({
              key: response.data.productionAssociate._id,
              picture: response.data.productionAssociate.picture,
              value: response.data.productionAssociate.fullName,
            })
          );
        }
        dispatch(projectFetchingDone());
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  };

export const updateProject =
  (body: any) => (dispatch: (arg0: { type: any; payload?: any }) => void) => {
    const user = getAuthUser();
    return axios
      .put(`${API_BASE}projects/${body._id}`, body, {
        headers: {
          contentType: "application/json",
          authorization: user.accessToken ? "Bearer " + user.accessToken : "", //the token is a variable which holds the token,
        },
      })
      .then((response) => {
        dispatch(setCurrentProject(body));
        return Promise.resolve();
      })
      .catch((error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      });
  };

//   const user = getAuthUser();
//   return axios
//     .post(`${API_BASE}proposal/${id}`, body, {
//       headers: {
//         Authorization: user.accessToken ? "Bearer " + user.accessToken : "",
//         contentType: "application/json",
//       },
//     })
//     .then((resp: any) => {
//       dispatch(
//         setProjectProposal({ ...body, projectId: id, _id: resp.data._id })
//       );
//       return Promise.resolve();
//     })
//     .catch((error: Error) => {
//       console.log(error);
//       return Promise.reject();
//     });
// };

export const createProposal = (projectId: string, body: any) => {
  const user = getAuthUser();
  return {
    type: "SET_PROJECT_PROPOSAL",
    payload: { body, projectId },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}proposal/${projectId}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "POST",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "SET_PROJECT_PROPOSAL_COMMIT", meta: { body } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "POST",
              path: `proposal/${projectId}`,
            },
          },
        },
      },
    },
  };
};

export const updateProposal = (
  projectId: string,
  body: any,
  oldProposal: any
) => {
  const user = getAuthUser();
  return {
    type: "UPDATE_PROJECT_PROPOSAL",
    payload: { body, projectId },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}proposal/${projectId}/${body._id}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
          },
          method: "PUT",
          body: JSON.stringify(body),
        },
        // action to dispatch when effect succeeds:
        commit: { type: "UPDATE_PROJECT_PROPOSAL_COMMIT", meta: { body } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: "SET_ERROR",
          meta: {
            data: {
              body,
              method: "PUT",
              path: `proposal/${body._id}`,
            },
          },
        },
      },
    },
  };
};

export const createJobJacket = (payload: any) => ({
  type: "SET_PROJECT_JOB_JACKET",
  payload,
});
