import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Typography,
  Space,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import logo from "../../assets/images/Pro-Driven.jpg";
import "./Login.less";
import api from "../../utils/api";

const SearchAccount = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const handleEmailSearch = (e: any) => {
    api
      .post(`user/forgot-password`, { email: e.email })
      .then((res: any) => {
        // message.success(
        //   "An email will be sent to this email ID if an account is registered under it!"
        // );
        setSuccessMessage(
          "An email will be sent to this email ID if an account is registered under it!"
        );
        console.log(res);
      })
      .catch((err) => {
        message.error("Some Error Had Accrued!");
        console.log(err);
      });
  };
  return (
    <div>
      <div className="background-container">
        <Row justify="center">
          <Col>
            <div className="certapro-logo">
              <img
                style={{
                  height: 100,
                  marginLeft: 0,
                }}
                src={logo}
                alt="logo"
              />
            </div>

            <Card className="login-card">
              <Form
                layout="vertical"
                autoComplete="off"
                onFinish={handleEmailSearch}
              >
                <h1 className="login-title">Find your account</h1>

                <div className="input-container">
                  <h1
                    className="enter-text"
                    style={{ marginTop: "40px", marginBottom: "30px" }}
                  >
                    Please enter your email address
                  </h1>
                  <div>
                    <Form.Item
                      label={<h1 className="input-title">Email</h1>}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your valid email address!",
                          type: "email",
                        },
                      ]}
                    >
                      <Input className="input-field" />
                    </Form.Item>
                    <Form.Item>
                      <>
                        <Button
                          type="primary"
                          className="login-btn"
                          htmlType="submit"
                        >
                          Send Email
                        </Button>
                      </>
                    </Form.Item>
                  </div>
                  <h1
                    className="enter-text"
                    style={{ marginTop: "28px", marginBottom: "60px" }}
                  >
                    Open the link in the email and update the password
                  </h1>
                </div>
              </Form>
            </Card>

            {successMessage && (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Space>
                  <CheckCircleOutlined style={{ color: "green" }} />
                  <Typography.Text type="success">
                    {successMessage}
                  </Typography.Text>
                </Space>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SearchAccount;
