import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Table,
  Input,
  Button,
  Checkbox,
  Tooltip,
  Typography,
  message,
  Modal,
} from "antd";
import {
  WarningFilled,
  EditOutlined,
  CloseOutlined,
  SaveOutlined,
  CopyOutlined
} from "@ant-design/icons";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setPjcc } from "../../redux/project-pjcc/action";
import { useHistory, useParams } from "react-router-dom";
import { calculateDifferences, numberWithCommas, productionTargetSum } from "../../utils/helpers";
import _ from "lodash";
import {
  postCheckRequest,
  updateProjectAndPjccByProjectId,
} from "../../redux/project/action";
import Notes from "../notes/Notes";
import moment from "moment";
import "./ProductionPjcc.less";
var crypto = require("crypto");

const ProductionPjcc: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { projectPjcc } = useSelector(
    (state: RootStateOrAny) => state.projectPjcc
  );
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [tableData, setTableData] = useState<any>([]);
  const [projectEstimatedPrice, setProjectEstimatedPrice] = useState<any>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notes, setNotes] = useState("");
  const [miscAmounts, setMiscAmounts] = useState<Array<number>>([]);
  const [updatedMisc, setUpdatedMisc] = useState(0)
  const [contractedPrice, setContractedPrice] = useState(0);
  const [previousProductionTargets, setPreviousProductionTargets] = useState<any>({});
  const [copiedProject, setCopiedProject] = useState<any>({});

  useEffect(() => {
    let newData = _.cloneDeep(projectPjcc);
    setProjectEstimatedPrice(projectPjcc[0]);
    let priceToClient = projectPjcc[20]?.totalAmount;
    newData = newData?.filter(
      (item: any) =>
        item.index !== 0 &&
        item.index !== 20 &&
        item.index !== 19 &&
        item.index !== 18 &&
        item.index !== 17 &&
        item.index !== 21
    );
    // Show only if there is any data un pjcc
    if (priceToClient) {
      newData[15].productionTargets = newData[14]?.productionTargets === 0 ? 0 :
        priceToClient - newData[14]?.productionTargets;

      const mappedToTwoDecimal = newData.map((item: any) => {
        if (
          item.name === "Paint Hours" ||
          item.name === "Carpentry Hours" ||
          item.name === "Total Hours"
        ) {
          return {
            ...item,
            productionTargets: parseFloat(item.productionTargets?.toFixed(2)),
          };
        } else {
          return {
            ...item,
            productionTargets: Math.round(item?.productionTargets),
          };
        }
      });

      mappedToTwoDecimal.push({
        name: "Gross Margin %",
        index: 17,
        project: newData[1]?.project,
        cost: ((1 - newData[14]?.cost / projectPjcc[0]?.amount) * 100).toFixed(
          2
        ),
        amount: null,
        totalAmount: null,
        productionTargets: newData[14]?.productionTargets === 0 ? 0 : (
          (1 - newData[14]?.productionTargets / projectPjcc[0]?.amount) *
          100
        ).toFixed(2),
      });

      const updatedTableData = checkAndAddInitialCostKey(
        mappedToTwoDecimal,
        items[currentProject].projectInfo.productionPjcc
      );

      // Save the previous production targets before any edits
      const previousProductionTargets = updatedTableData.reduce((acc: any, item: any) => {
        acc[item.index] = item.productionTargets;
        return acc;
      }, {});

      setTableData(updatedTableData);
      setPreviousProductionTargets(previousProductionTargets); // Save previous production targets
    }
  }, [projectPjcc]);

  useEffect(() => {
    const productionItems = items[
      currentProject
    ].projectInfo?.productionPjcc?.filter((item: any) => {
      return item.appearOnWorkOrder;
    });
    const sum = productionItems?.reduce((initial: any, curValue: any) => {
      return initial + parseFloat(curValue.productionTargets);
    }, 0);
    setContractedPrice(parseFloat(sum?.toFixed(2)));
  }, []);

  const checkAndAddInitialCostKey = (
    updatedProductionPjcc: any,
    oldProductionPjcc: any
  ) => {
    const initialCostMap = oldProductionPjcc?.reduce((acc: any, item: any) => {
      if ("initialCost" in item) {
        acc[item.name] = item.initialCost;
      }
      return acc;
    }, {});

    const updatedArray = updatedProductionPjcc?.map((item: any) => {
      if (item?.name in initialCostMap) {
        return { ...item, initialCost: initialCostMap[item?.name] };
      }
      return item;
    });
    return updatedArray;
  };

  const handleAmount = (e: any, index: number) => {
    const newData = [...tableData];
    newData[index].initialCost = newData[index].cost;
    let previousAmount = 0;
    if (
      items[currentProject].checkRequests.length > 0
    ) {
      previousAmount =
        items[currentProject].projectInfo.productionPjcc[index]
          ?.productionTargets;
    }
    const newAmount = e.target.value - previousAmount;
    // Update miscAmounts array
    const updatedMiscAmounts = [...miscAmounts];
    updatedMiscAmounts[index] = newAmount;
    setMiscAmounts(updatedMiscAmounts);
    // if (!("initialCost" in newData[index])) {
    // }
    // only update value if there is not any check request entry
    newData[index].productionTargets = e.target.value;

    if (index === 0 || index === 1 || index === 2) {
      newData[3].productionTargets =
        parseFloat(newData[0].productionTargets) +
        parseFloat(newData[1].productionTargets) +
        parseFloat(newData[2].productionTargets);
    } else if (index === 10 || index === 11) {
      newData[12].productionTargets =
        parseFloat(newData[10].productionTargets) +
        parseFloat(newData[11].productionTargets);
    }
    newData[14].productionTargets =
      parseFloat(newData[3].productionTargets) +
      parseFloat(newData[4].productionTargets) +
      parseFloat(newData[5].productionTargets) +
      parseFloat(newData[6].productionTargets) +
      parseFloat(newData[12].productionTargets);

    newData[15].productionTargets =
      projectPjcc[20]?.totalAmount - parseFloat(newData[14].productionTargets);

    newData[16].productionTargets =
      (1 - newData[14].productionTargets / projectEstimatedPrice.amount) * 100;
    newData[17].productionTargets = (
      (1 - newData[14]?.productionTargets / projectPjcc[0]?.amount) *
      100
    ).toFixed(2);
    setTableData(newData);
  };

  const next = (redirect: boolean) => {
    const updatedProductionPjcc = tableData.map((item: any) => {
      if ("initialCost" in item) {
        return {
          name: item.name,
          productionTargets: +item.productionTargets,
          appearOnWorkOrder: item.workOrder ? item.workOrder : false,
          initialCost: +item.initialCost,
          cost: item.cost
        };
      } else {
        return {
          name: item.name,
          productionTargets: +item.productionTargets,
          appearOnWorkOrder: item.workOrder ? item.workOrder : false,
          cost: item.cost
        };
      }
    });

    let project = _.cloneDeep(items[currentProject]);
    project.projectInfo.productionPjcc = updatedProductionPjcc;
    let newProjectPjcc = _.cloneDeep(projectPjcc);
    let updatedPjcc = newProjectPjcc.map((item: any) => {
      for (const entity of updatedProductionPjcc) {
        if (item.name === entity.name) {
          return {
            ...item,
            productionTargets: entity.productionTargets,
            workOrder: entity.appearOnWorkOrder,
          };
        }
      }
    });
    updatedPjcc[0] = newProjectPjcc[0];
    updatedPjcc[16] = newProjectPjcc[16];
    updatedPjcc[17] = newProjectPjcc[17];
    updatedPjcc[18] = newProjectPjcc[18];
    updatedPjcc[19] = newProjectPjcc[19];
    updatedPjcc[20] = newProjectPjcc[20];
    const updatedObject = updatedPjcc.map((item: any) => {
      return {
        ...item,
        cost: parseFloat(item?.cost?.toFixed(2)),
        amount: parseFloat(item?.amount?.toFixed(2)),
        totalAmount: parseFloat(item?.totalAmount?.toFixed(2)),
        productionTargets: parseFloat(item?.productionTargets?.toFixed(2)),
      };
    });
    dispatch(setPjcc(updatedObject));
    dispatch(
      updateProjectAndPjccByProjectId(
        project.projectInfo,
        project,
        adminDefaults
      )
    );
    if (redirect) {
      history.push(`/pre-checklist/${id}`);
    }
  };

  const handleCheckbox = (e: any, index: number) => {
    const newData = [...tableData];
    newData[index].workOrder = e.target.checked;
    setTableData(newData);
  };

  const onSave = () => {
    // Handle the copy state first
    if (isCopy) {
      dispatch(
        updateProjectAndPjccByProjectId(
          copiedProject?.projectInfo,
          copiedProject,
          adminDefaults
        )
      );
      setIsCopy(false)
      return;
    }
    // Proceed with the save operation if there is no copy state
    const currentProjectItem = items[currentProject];
    if (currentProjectItem.checkRequests.length > 0) {
      setUpdatedMisc(productionTargetSum(miscAmounts));
      setShowModal(true);
      setIsEdit(false);
    } else {
      next(false);
      setIsEdit(false);
    }
  };

  const onDiscard = () => {
    setIsEdit(false);
    // Reset production targets to previous values
    const resetData = tableData.map((item: any) => {
      if (previousProductionTargets.hasOwnProperty(item.index)) {
        return {
          ...item,
          productionTargets: previousProductionTargets[item.index]
        };
      }
      return item;
    });
    setTableData(resetData);
  };

  const onCopy = () => {
    let project = _.cloneDeep(items[currentProject]);
    let newP = project.projectInfo.productionPjcc.map((el: any) => {
      return {
        ...el,
        productionTargets: el.cost
      }
    })
    project.projectInfo.productionPjcc = newP;
    const newData = _.cloneDeep(tableData);
    const newTableData = newData.map((item: any) => {
      delete item.initialCost;
      return {
        ...item,
        productionTargets: item.cost,
      };
    });
    setTableData(newTableData);
    if (items[currentProject].checkRequests.length > 0) {
      let diffrence = calculateDifferences(previousProductionTargets, newTableData)
      setMiscAmounts(diffrence.differences)
    } else {
      setIsCopy(true);
      setCopiedProject(project)
    }
  };

  const columns: any = [
    {
      title: "Items",
      dataIndex: "name",
      key: "items",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            {index === 17 ? (
              <div>
                {data}
                {/* {data?.toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "} */}
                %
              </div>
            ) : index === 7 || index === 8 || index === 9 ? (
              <div>{data?.toFixed(2)}</div>
            ) : (
              index != 17 && (
                <div>
                  {index !== 7 && index !== 8 && index !== 9 && "$"}
                  {Math.round(data)}
                  {/* {data?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} */}
                </div>
              )
            )}
          </>
        );
      },
    },
    {
      title: () => {
        return (
          <Row>
            <Typography>Production Targets</Typography>
            {isEdit ? (
              <>
                <CopyOutlined
                  onClick={onCopy}
                  style={{
                    marginLeft: 10,
                    color: "#FDB913",
                    cursor: "pointer",
                    fontSize: "18px"
                  }}
                />
                <SaveOutlined
                  onClick={onSave}
                  style={{
                    marginLeft: 10,
                    color: "#FDB913",
                    cursor: "pointer",
                    fontSize: "18px"
                  }}
                />
                <CloseOutlined
                  onClick={onDiscard}
                  style={{
                    marginLeft: 10,
                    color: "#FDB913",
                    cursor: "pointer",
                    fontSize: "18px"
                  }}
                />
              </>
            ) : (
              <EditOutlined
                onClick={() => setIsEdit(true)}
                style={{
                  marginLeft: 10,
                  color: "#FDB913",
                  cursor: "pointer",
                  fontSize: "18px"
                }}
              />
            )}
          </Row>
        );
      },
      // dataIndex: "productionTargets",
      render: (data: any, _: any, index: number) => {
        return (
          <>
            {index === 3 ||
              index === 8 ||
              index === 7 ||
              index === 9 ||
              index === 12 ||
              index === 13 ||
              index === 14 ||
              index === 15 ||
              index === 17 ? (
              index === 17 ? (
                <div>
                  {data.productionTargets &&
                    numberWithCommas(
                      data.productionTargets?.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    )}
                  %
                </div>
              ) : index === 7 || index === 8 || index === 9 ? (
                <div>
                  <div>{data?.productionTargets}</div>
                </div>
              ) : (
                <div>
                  {/* {index !== 7 && index !== 8 && index !== 9 && "$"} */}$
                  {data?.productionTargets &&
                    numberWithCommas(
                      Math.round(data.productionTargets)
                      // data.productionTargets?.toLocaleString("en-US", {
                      //   minimumFractionDigits: 2,
                      //   maximumFractionDigits: 2,
                      // })
                    )}
                </div>
              )
            ) : index === 16 ? (
              <></>
            ) : (
              <Row>
                <Col>
                  {isEdit ? (
                    <Input
                      type="number"
                      onChange={(e) => handleAmount(e, index)}
                      value={data.productionTargets}
                    />
                  ) : (
                    <div>{data?.productionTargets}</div>
                  )}
                </Col>
                <Col>
                  {data.hasOwnProperty("initialCost") ? (
                    data.initialCost !== data.cost ? (
                      <Tooltip
                        placement="top"
                        title={"Please Review the Cost Changes"}
                      >
                        <WarningFilled
                          style={{
                            color: "#FDB913",
                            fontSize: 20,
                            marginTop: 5,
                            marginLeft: 5,
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            )}
          </>
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Appear on Work Order</div>,
      dataIndex: "workOrder",
      render: (data: any, temp: any, index: number) => {
        return (
          <>
            {temp.index === 4 ||
              temp.index === 8 ||
              temp.index === 9 ||
              temp.index === 10 ||
              temp.index === 13 ||
              temp.index === 14 ||
              temp.index === 15 ||
              temp.index === 16 ||
              temp.index === 17 ||
              temp.name === "Full Bid Revenue" ? (
              <></>
            ) : (
              <Row justify="center">
                <Checkbox
                  checked={data}
                  onChange={(e: any) => handleCheckbox(e, index)}
                />
              </Row>
            )}
          </>
        );
      },
    },
  ];
  const handleModalConfirm = () => {
    handleOk(notes);
  };

  const handleOk = async (notes: string) => {
    let balanceDue = 0;
    let lastBalance =
      items[currentProject].checkRequests[
        items[currentProject].checkRequests.length - 1
      ]?.balanceDue;
    let paymentType = "Misc";
    if (!isNaN(updatedMisc)) {
      if (updatedMisc > 0) {
        // length = items[currentProject].checkRequests.length;
        balanceDue = lastBalance + Math.abs(updatedMisc);
        paymentType = "Misc";
      } else if (updatedMisc < 0) {
        // length = items[currentProject].checkRequests.length;
        balanceDue = lastBalance - Math.abs(updatedMisc);
        paymentType = "Misc";
      } else {
        balanceDue = contractedPrice + +updatedMisc;
        paymentType = "Misc ";
      }
      const body = {
        _id: crypto.randomBytes(12).toString("hex"),
        project: id,
        date: moment(),
        amount: updatedMisc,
        retainer: 0,
        depositAmount: 0,
        balanceDue: balanceDue,
        bonusPayment: updatedMisc,
        paymentType: "Misc",
        projectName: items[currentProject].projectInfo.jobName,
        payor: adminDefaults?.payProcessingDefaults[0]?.user
          ? adminDefaults?.payProcessingDefaults[0]?.user
          : "",
        crew: items[currentProject].prePresentationChecklist?.crew?._id,
        description: notes,
      };
      dispatch(postCheckRequest(body));
      setUpdatedMisc(0);
      setMiscAmounts([])
      next(false)
      setShowModal(false);
    } else {
      message.error("Your input must be a number");
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        centered={true}
        title="Misc Payment"
        visible={showModal}
        onCancel={handleModalCancel}
        destroyOnClose={true}
        className="misc-payment-modal"
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleModalConfirm}
            style={{ color: "black" }}
          >
            Confirm
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <span style={{ color: 'red' }}>
            NOTE: A check request has already been processed for this project. In order to update the remaining balance due for the contractor assigned, you must add a Misc Payment.
          </span>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span>Misc Amount</span>
            <Input type="number" value={updatedMisc} disabled={true} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span>Description</span>
            <Input
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Add a Description"
            // style={{ marginBottom: '5px' }}
            />
          </div>
          <span style={{ marginBottom: '4px', marginTop: '4px' }}>Are you sure you want to add this Misc Payment?</span>
        </div>
      </Modal>
      <Row gutter={24}>
        <Col lg={15} sm={24}>
          <Card className="root-container">
            {/* <Row gutter={24}>
          <Col className="gutter-row" lg={15} sm={24}> */}
            <div className="title-container">
              <h1 className="form-title">PJCC</h1>
            </div>
            <hr style={{ border: "1px solid #E8E8E8", borderBottom: "none" }} />

            <div style={{ margin: "0px 20px" }}>
              <Row gutter={10}>
                <Col span={6}>
                  <div className="pjcc--box">
                    {projectPjcc[17]?.totalAmount?.toFixed(2).length > 6 ? (
                      <>
                        <h3>
                          ${(projectPjcc[17]?.totalAmount / 1000).toFixed(2)}k
                        </h3>
                      </>
                    ) : (
                      <>
                        <h3>${projectPjcc[17]?.totalAmount?.toFixed(2)}</h3>
                      </>
                    )}
                    <p>Gross Profit $p/hr</p>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="pjcc--box">
                    {projectPjcc[18]?.cost?.toFixed(2).length > 6 ? (
                      <>
                        <h3>${(projectPjcc[18]?.cost / 1000).toFixed(2)}k</h3>
                      </>
                    ) : (
                      <>
                        <h3>${projectPjcc[18]?.cost?.toFixed(2)}</h3>
                      </>
                    )}
                    <p>Project Cost</p>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="pjcc--box">
                    {projectPjcc[20]?.totalAmount?.toFixed(2).length > 6 ? (
                      <>
                        <h3>
                          ${(projectPjcc[20]?.totalAmount / 1000).toFixed(2)}k
                        </h3>
                      </>
                    ) : (
                      <>
                        <h3>${projectPjcc[20]?.totalAmount?.toFixed(2)}</h3>
                      </>
                    )}
                    <p>Price To Client</p>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="pjcc--box">
                    {projectPjcc[19]?.totalAmount?.toFixed(2).length > 6 ? (
                      <>
                        <h3>
                          ${(projectPjcc[19]?.totalAmount / 1000).toFixed(2)}k
                        </h3>
                      </>
                    ) : (
                      <>
                        <h3>{projectPjcc[19]?.totalAmount?.toFixed(2)}%</h3>
                      </>
                    )}
                    <p>Project Margin</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="pjcc--listing">
              <div
                className="site-layout-background  table-title"
                style={{ padding: "20px 0px 40px 0px" }}
              >
                <Table
                  rowKey="index"
                  rowClassName={(record, index) =>
                    index % 2 === 0
                      ? "table_row table-row-light"
                      : "table_row table-row-dark"
                  }
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                />
              </div>
            </div>
          </Card>
        </Col>

        <Col className="gutter-row" lg={9} sm={24}>
          <Notes />
        </Col>
      </Row>

      <Row style={{ marginBottom: "20px" }}>
        <Col lg={6} md={10}></Col>
        <Col>
          <div>
            <Button
              type="primary"
              className="save-button"
              onClick={() => next(true)}
            >
              Next
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProductionPjcc;