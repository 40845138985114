import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { LatoRegular } from "../assets/fonts/LatoRegular";
import { LatoBold } from "../assets/fonts/LatoBold";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import ProposalSetup from "../pages/estimator/ProposalSetup";
import { replaceNbsp } from "../utils/helpers";
import { log } from "console";
import { title } from "process";

function drawDivider(
  document: jsPDF,
  position: number,
  x1: number,
  x2: number
) {
  document.setLineWidth(3);
  document.setDrawColor("#fdb913");
  document.line(x1, position, x2, position);
}

const getColorFromHtmlStyleString = (styleText: string) => {
  let startIndex = styleText.indexOf("(");
  let endIndex = styleText.indexOf(")");
  let rgbString = styleText.substring(startIndex + 1, endIndex);
  let rgbValuesArray = rgbString.split(", ");
  return rgbValuesArray.map((rgbValueString) => parseInt(rgbValueString));
};

const printWordsWithWrapping = (
  doc: jsPDF,
  text: string,
  xPos: number,
  yPos: number
) => {
  let words = text.split(" ");
  for (let word of words) {
    word = word.trim();
    if (xPos + doc.getTextWidth(word) > 565) {
      yPos += 10;
      xPos = 30;
    }
    // let wordWithSpace = word;
    let wordWithSpace = `${word} `;
    if (word.length < 1) {
      continue;
    }
    doc.text(wordWithSpace, xPos, yPos);
    xPos += doc.getTextWidth(wordWithSpace);
  }
  return { xPos, yPos };
};

const hasLargerFontSize = async (
  proposalDoc: jsPDF,
  pTagWithMultipleElements: any
) => {
  let fontSize = 0;
  for (const element of pTagWithMultipleElements) {
    if (
      typeof element === "object" &&
      element.type === "span" &&
      element.attributes.class
    ) {
      if (element.attributes.class === "ql-size-large") {
        if (fontSize !== 2) fontSize = 1;
      } else if (element.attributes.class === "ql-size-huge") {
        fontSize = 2;
      }
    }
  }
  return fontSize;
};

export const generateProposalPdfWithHtml = async (
  proposalDoc: jsPDF,
  htmlObject: any,
  htmlStartPosition: number
) => {
  const horizontalMargin = 30;
  const pageLimit = 790; // Define the page limit
  const pageTopMargin = 60;
  proposalDoc.setLineWidth(1);
  proposalDoc.setDrawColor("black");

  let docEndPosition = htmlStartPosition;

  let cursorPosition = {
    xPos: horizontalMargin,
    yPos: docEndPosition,
  };

  const defaultFillColor = proposalDoc.getFillColor();
  const defaultTextColor = proposalDoc.getTextColor();
  const defaultFontSize = proposalDoc.getFontSize();

  proposalDoc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
  proposalDoc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
  proposalDoc.addFileToVFS("Lato-Bold.ttf", LatoBold);
  proposalDoc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  docEndPosition += 10;
  let hasHugeFont = false;
  for (const element of htmlObject.content) {
    if (docEndPosition >= 790) {
      proposalDoc.addPage();
      docEndPosition = 60;
    }
    // p tag with multiple HTML elements
    switch (element.type) {
      case "p":
        if (element.content.length > 1) {
          const containsLargerFontSize = await hasLargerFontSize(
            proposalDoc,
            element.content
          );
          if (containsLargerFontSize === 1) {
            docEndPosition += 10;
          } else if (containsLargerFontSize === 2) {
            docEndPosition += 5;
          }
          let xPos = horizontalMargin;
          element.content.forEach((currentElement: any) => {
            if (typeof currentElement === "string") {
              cursorPosition = printWordsWithWrapping(
                proposalDoc,
                currentElement,
                xPos,
                docEndPosition
              );
              xPos = cursorPosition.xPos;
              docEndPosition = cursorPosition.yPos;
            }
            if (typeof currentElement === "object") {
              switch (currentElement.type) {
                case "strong":
                  proposalDoc.setFont("LatoRegular", "bold");
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  proposalDoc.setFont("LatoRegular", "normal");
                  break;
                case "em":
                  proposalDoc.setFont("LatoRegular", "italic");
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  proposalDoc.setFont("LatoRegular", "normal");
                  break;
                case "u": {
                  const textWidth = proposalDoc.getTextWidth(
                    currentElement.content[0]
                  );
                  proposalDoc.line(
                    xPos,
                    docEndPosition + 1,
                    textWidth + xPos,
                    docEndPosition + 1
                  );
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  // docEndPosition += 10;
                  break;
                }
                case "s": {
                  const textWidth = proposalDoc.getTextWidth(
                    currentElement.content[0]
                  );
                  proposalDoc.line(
                    xPos,
                    docEndPosition - 3,
                    textWidth + xPos,
                    docEndPosition - 3,
                    "FD"
                  );
                  cursorPosition = printWordsWithWrapping(
                    proposalDoc,
                    currentElement.content[0],
                    xPos,
                    docEndPosition
                  );
                  docEndPosition = cursorPosition.yPos;
                  xPos = cursorPosition.xPos;
                  // docEndPosition += 10;
                  break;
                }
                case "a":
                  proposalDoc.setTextColor("blue");
                  if (
                    xPos + proposalDoc.getTextWidth(currentElement.content[0]) >
                    565
                  ) {
                    xPos = horizontalMargin;
                    docEndPosition += 10;
                  }
                  proposalDoc.textWithLink(
                    currentElement.content[0],
                    xPos,
                    docEndPosition,
                    { url: currentElement.attributes.href }
                  );
                  xPos += proposalDoc.getTextWidth(currentElement.content[0]);
                  proposalDoc.setTextColor(defaultTextColor);
                  // docEndPosition += 10;
                  break;
                case "span":
                  let contentStyle = currentElement.attributes.style;
                  if (contentStyle) {
                    if (contentStyle.startsWith("background-color")) {
                      //draw bg rect
                      let colorArry = getColorFromHtmlStyleString(contentStyle);
                      const textWidth = proposalDoc.getTextWidth(
                        currentElement.content[0]
                      );
                      proposalDoc.setFillColor(
                        colorArry[0],
                        colorArry[1],
                        colorArry[2]
                      );
                      proposalDoc.rect(
                        xPos,
                        docEndPosition - 8,
                        textWidth,
                        10,
                        "F"
                      );
                      cursorPosition = printWordsWithWrapping(
                        proposalDoc,
                        currentElement.content[0],
                        xPos,
                        docEndPosition
                      );
                      docEndPosition = cursorPosition.yPos;
                      xPos = cursorPosition.xPos;
                      proposalDoc.setFillColor(defaultFillColor);
                    } else if (contentStyle.startsWith("color")) {
                      //set font color
                      let colorArry = getColorFromHtmlStyleString(contentStyle);
                      proposalDoc.setTextColor(
                        colorArry[0],
                        colorArry[1],
                        colorArry[2]
                      );
                      cursorPosition = printWordsWithWrapping(
                        proposalDoc,
                        currentElement.content[0],
                        xPos,
                        docEndPosition
                      );
                      docEndPosition = cursorPosition.yPos;
                      xPos = cursorPosition.xPos;
                      proposalDoc.setTextColor(defaultTextColor);
                      // docEndPosition += 10;
                    }
                  } else {
                    contentStyle = currentElement.attributes.class;
                    switch (contentStyle) {
                      case "ql-size-huge":
                        proposalDoc.setFontSize(18);
                        cursorPosition = printWordsWithWrapping(
                          proposalDoc,
                          currentElement.content[0],
                          xPos,
                          docEndPosition
                        );
                        docEndPosition = cursorPosition.yPos;
                        xPos = cursorPosition.xPos;
                        proposalDoc.setFontSize(defaultFontSize);
                        break;
                      case "ql-size-large":
                        proposalDoc.setFontSize(12);
                        cursorPosition = printWordsWithWrapping(
                          proposalDoc,
                          currentElement.content[0],
                          xPos,
                          docEndPosition
                        );
                        docEndPosition = cursorPosition.yPos;
                        xPos = cursorPosition.xPos;
                        proposalDoc.setFontSize(defaultFontSize);
                        break;
                      case "ql-size-small":
                        proposalDoc.setFontSize(6);
                        cursorPosition = printWordsWithWrapping(
                          proposalDoc,
                          currentElement.content[0],
                          xPos,
                          docEndPosition
                        );
                        docEndPosition = cursorPosition.yPos;
                        xPos = cursorPosition.xPos;
                        proposalDoc.setFontSize(defaultFontSize);
                        break;
                      default:
                        break;
                    }
                  }
                  break;
                default:
              }
              // xPos += (proposalDoc.getTextWidth(currentElement.content[0]));
              if (xPos >= 565) {
                docEndPosition += 10;
                xPos = horizontalMargin;
              }
            }
          });
          docEndPosition += 10;
        } else {
          //Single line in a <p> tag
          let isLink = false;
          const content = element.content[0];
          if (typeof content === "string") {
            // Simple text without any styling
            let splitText = proposalDoc.splitTextToSize(
              content,
              565 - horizontalMargin
            );
            proposalDoc.text(splitText, horizontalMargin, docEndPosition);
            docEndPosition += 10 * splitText.length;
          } else {
            switch (content.type) {
              case "strong":
                proposalDoc.setFont("LatoRegular", "bold");
                break;
              case "em":
                proposalDoc.setFont("LatoRegular", "italic");
                break;
              case "u": {
                const textWidth = proposalDoc.getTextWidth(content.content[0]);
                proposalDoc.line(
                  horizontalMargin,
                  docEndPosition + 1,
                  textWidth + horizontalMargin,
                  docEndPosition + 1
                );
                break;
              }
              case "s": {
                const textWidth = proposalDoc.getTextWidth(content.content[0]);
                proposalDoc.line(
                  horizontalMargin,
                  docEndPosition - 3,
                  textWidth + horizontalMargin,
                  docEndPosition - 3,
                  "FD"
                );
                break;
              }
              case "a":
                proposalDoc.setTextColor("blue");
                proposalDoc.textWithLink(
                  content.content[0],
                  horizontalMargin,
                  docEndPosition,
                  { url: content.attributes.href }
                );
                docEndPosition += 10;
                isLink = true;
                break;
              case "span":
                let contentStyle = content.attributes.style;
                if (contentStyle) {
                  if (contentStyle.startsWith("background-color")) {
                    //draw bg rect
                    let colorArry = getColorFromHtmlStyleString(contentStyle);
                    const textWidth = proposalDoc.getTextWidth(
                      content.content[0]
                    );
                    proposalDoc.setFillColor(
                      colorArry[0],
                      colorArry[1],
                      colorArry[2]
                    );
                    proposalDoc.rect(
                      horizontalMargin,
                      docEndPosition - 8,
                      textWidth,
                      10,
                      "F"
                    );
                  } else if (contentStyle.startsWith("color")) {
                    //set font color
                    let colorArry = getColorFromHtmlStyleString(contentStyle);
                    proposalDoc.setTextColor(
                      colorArry[0],
                      colorArry[1],
                      colorArry[2]
                    );
                  }
                } else {
                  contentStyle = content.attributes.class;
                  switch (contentStyle) {
                    case "ql-size-huge":
                      proposalDoc.setFontSize(18);
                      docEndPosition += 10;
                      break;
                    case "ql-size-large":
                      proposalDoc.setFontSize(12);
                      docEndPosition += 5;
                      break;
                    case "ql-size-small":
                      proposalDoc.setFontSize(6);
                      break;
                    default:
                      break;
                  }
                }
                break;
              case "br":
                break;
              default:
                debugger;
            }
            if (!isLink) {
              let text = content.content ? content.content[0] : " ";
              let splitText = proposalDoc.splitTextToSize(
                text,
                565 - horizontalMargin
              );
              for (let i = 0; i < splitText.length; i++) {
                proposalDoc.text(
                  splitText[i],
                  horizontalMargin,
                  docEndPosition
                );
                docEndPosition += 10;
                if (docEndPosition >= pageLimit) {
                  proposalDoc.addPage();
                  docEndPosition = pageTopMargin;
                }
              }
            }

            proposalDoc.setTextColor(defaultTextColor);
            proposalDoc.setFillColor(defaultFillColor);
            proposalDoc.setFontSize(defaultFontSize);
            proposalDoc.setFont("LatoRegular", "normal");
          }
        }
        break;
      case "ol": {
        const itemsList: any[] = element.content;

        const processContent = (content: any): string => {
          if (typeof content === "string") {
            return content;
          } else if (Array.isArray(content)) {
            return content.map(processContent).join("");
          } else {
            return processContent(content.content);
          }
        };

        const addTextToDoc = (
          text: string,
          margin: number,
          position: number
        ) => {
          const splitText = proposalDoc.splitTextToSize(text, 515 - margin);
          splitText.forEach((line: any) => {
            if (position > proposalDoc.internal.pageSize.height - 20) {
              proposalDoc.addPage();
              position = 20;
            }
            proposalDoc.setFont("LatoRegular", "normal");
            proposalDoc.text(line, margin + 20, position);
            position += 10;
          });
          return position;
        };

        itemsList.forEach((item: any, index: number) => {
          if (docEndPosition > proposalDoc.internal.pageSize.height - 20) {
            proposalDoc.addPage();
            docEndPosition = 20;
          }

          const numberPrefix = index < 9 ? "        " : "     ";
          const ovalue: string = processContent(item);

          proposalDoc.text(
            `${numberPrefix}${index + 1}.`,
            horizontalMargin,
            docEndPosition
          );
          docEndPosition = addTextToDoc(
            ovalue,
            horizontalMargin,
            docEndPosition
          );
        });

        break;
      }

      case "ul": {
        const itemsList: any[] = element.content;

        const processContent = (content: any): string => {
          if (typeof content === "string") {
            return content;
          } else if (Array.isArray(content)) {
            return content.map(processContent).join("");
          } else {
            return processContent(content.content);
          }
        };

        const addTextToDoc = (
          text: string,
          margin: number,
          position: number
        ) => {
          const splitText = proposalDoc.splitTextToSize(text, 515 - margin);
          splitText.forEach((line: any) => {
            if (position > proposalDoc.internal.pageSize.height - 20) {
              proposalDoc.addPage();
              position = 20;
            }
            proposalDoc.setFont("LatoRegular", "normal");
            proposalDoc.text(line, margin + 20, position);
            position += 10;
          });
          return position;
        };

        itemsList.forEach((item) => {
          if (docEndPosition > proposalDoc.internal.pageSize.height - 20) {
            proposalDoc.addPage();
            docEndPosition = 20; // reset position to top margin
          }

          proposalDoc.text(`        \u2022 `, horizontalMargin, docEndPosition);

          const itemText: string = processContent(item);

          docEndPosition = addTextToDoc(
            itemText,
            horizontalMargin,
            docEndPosition
          );
        });

        break;
      }
    }
  }
  return docEndPosition;
};

export const generateProposalPdf = async (
  project: any,
  adminDefaults: any,
  companyInfo: any,
  type: any,
  userPhoto: any,
  user?: any,
  parsedHTML?: any,
  optionsMediaList?: any
) => {
  //   const { currentProject, items, adminDefaults } = useSelector(
  //     (state: RootStateOrAny) => state.offlineData
  //   );

  let optionPjccForProposal: any[] = [];
  project?.options?.map((option: any) => {
    if (option.optionInfo.included === "included") {
      let optionPjcc = option.pjcc.filter(
        (item: any) => item.name === "Estimated Price"
      );
      optionPjccForProposal.push(...optionPjcc);
    }
  });

  let subTotal = optionPjccForProposal?.reduce((initial: any, value: any) => {
    return value.amount + initial;
  }, 0);

  const horizontalMargin = 30;
  const pageLimit = 790;
  const pageTopMargin = 60;

  let image = new Image();
  image.src = `${companyInfo?.companyLogo}`;
  const imageAspectRatio = image.naturalWidth / image.naturalHeight;
  let contractEndPosition = 100;
  var proposalDoc = new jsPDF("p", "pt");
  const headerAddress = `Address : ${companyInfo?.companyStreetAddressOne}, ${
    companyInfo?.companyStreetAddressTwo
      ? companyInfo?.companyStreetAddressTwo + ", "
      : ""
  }`;
  const headerWeb = `Website: ${companyInfo?.companyWebsite}`;
  const headerCity = `${companyInfo?.companyCity}, ${companyInfo?.companyState}, ${companyInfo?.companyZip}`;
  const headerTelephone = `Telephone: ${companyInfo?.companyTelephone}`;

  let bannerHeight =
    headerAddress.length +
    headerWeb.length +
    headerCity.length +
    headerTelephone.length;

  if (bannerHeight <= 100) {
    bannerHeight = 80;
  } else if (bannerHeight <= 200) {
    bannerHeight = 85;
  } else if (bannerHeight <= 300) {
    bannerHeight = 120;
  } else if (bannerHeight <= 400) {
    bannerHeight = 130;
  } else if (bannerHeight <= 500) {
    bannerHeight = 140;
  } else {
    bannerHeight = 150;
  }

  const defaultFillCol = proposalDoc.getFillColor();
  const defaultDrawCol = proposalDoc.getDrawColor();
  const defaultLineWidth = proposalDoc.getLineWidth();
  proposalDoc.setFillColor("#fdb913");
  proposalDoc.rect(30, 30, 535, bannerHeight, "F");
  proposalDoc.setFillColor(defaultFillCol);

  proposalDoc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
  proposalDoc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
  proposalDoc.addFileToVFS("Lato-Bold.ttf", LatoBold);
  proposalDoc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

  proposalDoc.setFont("LatoRegular");
  proposalDoc.setFontSize(20);

  let formattedDate = moment(project?.projectInfo?.proposal?.createdAt).format(
    "MMMM DD,YYYY"
  );

  proposalDoc.addImage(
    image,
    "png",
    35,
    40,
    140,
    140 / imageAspectRatio,
    "logo",
    "FAST"
  );

  proposalDoc.setLineWidth(5);
  proposalDoc.setDrawColor("#fdb913");
  proposalDoc.rect(42, 48, 122, 37, "D");
  proposalDoc.setDrawColor(defaultDrawCol);
  proposalDoc.setLineWidth(defaultLineWidth);

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  contractEndPosition = contractEndPosition - 40;
  const headerCompanyInfo = `${companyInfo?.companyName}`;
  const headerTextCm = 250;
  proposalDoc.text(
    headerCompanyInfo,
    headerTextCm,
    contractEndPosition,
    { maxWidth: 200 },
    {
      align: "center",
    }
  );

  const headerCompanyEndPosition = proposalDoc.getTextDimensions(
    headerCompanyInfo,
    {
      maxWidth: 200,
    }
  );
  contractEndPosition += headerCompanyEndPosition.h;

  proposalDoc.text(headerAddress, headerTextCm, contractEndPosition, {
    maxWidth: 200,
  });

  const pro3EndPosition = proposalDoc.getTextDimensions(headerAddress, {
    maxWidth: 200,
  });
  contractEndPosition += pro3EndPosition.h;

  proposalDoc.text(
    headerCity,
    headerTextCm,
    contractEndPosition,
    { maxWidth: 200 },
    {
      align: "center",
    }
  );
  const headerCityEndPosition = proposalDoc.getTextDimensions(headerCity, {
    maxWidth: 200,
  });
  contractEndPosition += headerCityEndPosition.h;

  proposalDoc.text(
    headerTelephone,
    headerTextCm,
    contractEndPosition,
    { maxWidth: 200 },
    {
      align: "center",
    }
  );
  const headerTelephoneEndPosition = proposalDoc.getTextDimensions(
    headerTelephone,
    {
      maxWidth: 200,
    }
  );
  contractEndPosition += headerTelephoneEndPosition.h;

  proposalDoc.text(
    `Website: ${companyInfo?.companyWebsite}`,
    headerTextCm,
    contractEndPosition,
    { maxWidth: 200 },
    {
      align: "center",
    }
  );

  const headerWebEndPosition = proposalDoc.getTextDimensions(headerWeb, {
    maxWidth: 200,
  });
  contractEndPosition += headerWebEndPosition.h;

  contractEndPosition = contractEndPosition - 20;

  const headerSubtype = `Type: ${project?.projectInfo?.projectType} ${project?.projectInfo?.projectSubtype}`;
  const headerTextRm = 450;
  proposalDoc.text(
    headerSubtype,
    headerTextRm,
    contractEndPosition,
    { maxWidth: 200 },

    {
      align: "right",
    }
  );
  const headerSubtypeEndPosition = proposalDoc.getTextDimensions(
    headerSubtype,
    {
      maxWidth: 200,
    }
  );
  contractEndPosition += headerSubtypeEndPosition.h;

  const jobNumber = `Proposal #: ${project?.projectInfo?.jobNumber}`;

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  proposalDoc.text(
    jobNumber,
    headerTextRm,
    contractEndPosition,
    { maxWidth: 200 },

    {
      align: "right",
    }
  );
  const headerEndposition = proposalDoc.getTextDimensions(jobNumber, {
    maxWidth: 200,
  });
  contractEndPosition += headerEndposition.h;

  const headerDate = `Date: ${formattedDate}`;
  proposalDoc.text(
    headerDate,
    headerTextRm,
    contractEndPosition,
    { maxWidth: 200 },
    {
      align: "right",
    }
  );
  const headerDateEndPosition = proposalDoc.getTextDimensions(headerDate, {
    maxWidth: 200,
  });
  contractEndPosition += headerDateEndPosition.h + 20;

  // const ProposalDocIconImage = new Image();
  // ProposalDocIconImage.src = proposalIcon;
  // proposalDoc.addImage(ProposalDocIconImage, "png", 200, 70, 40, 40);
  // proposalDoc.setFont("LatoRegular", "bold");
  // proposalDoc.setFontSize(24);
  // proposalDoc.text("PROPOSAL", horizontalMargin + 210, contractEndPosition);
  // proposalDoc.line(0, 120, 600, 120);

  contractEndPosition += bannerHeight / 2;
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(12);
  proposalDoc.text("JOBSITE", horizontalMargin, contractEndPosition);

  proposalDoc.text("PREPARED BY", horizontalMargin + 270, contractEndPosition);

  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 250);

  drawDivider(proposalDoc, contractEndPosition, horizontalMargin + 270, 565);
  contractEndPosition = contractEndPosition + 10;

  if (userPhoto) {
    const profileImage = new Image();
    profileImage.src = userPhoto;
    proposalDoc.addImage(
      profileImage,
      "png",
      horizontalMargin + 450,
      contractEndPosition + 5,
      45,
      45,
      "profile",
      "FAST"
    );
  }

  contractEndPosition = contractEndPosition + 10;
  const fName =
    user?.fullName || project?.projectInfo?.salesAssociate?.fullName;
  proposalDoc.setFontSize(8);
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.text(fName, horizontalMargin + 270, contractEndPosition, {
    maxWidth: 150,
  });

  const fNameEndPosition = proposalDoc.getTextDimensions(fName, {
    maxWidth: 150,
  });
  contractEndPosition += fNameEndPosition.h;

  const jTitle =
    user?.jobTitle || project?.projectInfo?.salesAssociate?.jobTitle
      ? project?.projectInfo?.salesAssociate?.jobTitle
      : " ";
  proposalDoc.text(jTitle, horizontalMargin + 270, contractEndPosition, {
    maxWidth: 150,
  });
  const jTitleEndPosition = proposalDoc.getTextDimensions(jTitle, {
    maxWidth: 150,
  });
  contractEndPosition += jTitleEndPosition.h;

  const proAssociate = `${
    user?.phone || project?.projectInfo?.salesAssociate?.phone
      ? project?.projectInfo?.salesAssociate?.phone
      : " "
  }`;
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.text(proAssociate, horizontalMargin + 270, contractEndPosition, {
    maxWidth: 150,
  });

  const proAssociateEndPosition = proposalDoc.getTextDimensions(proAssociate, {
    maxWidth: 150,
  });
  contractEndPosition += proAssociateEndPosition.h;

  const proEmail = user?.email || project?.projectInfo?.salesAssociate?.email;

  proposalDoc.text(proEmail, horizontalMargin + 270, contractEndPosition, {
    maxWidth: 150,
  });
  const proEmailEndPosition = proposalDoc.getTextDimensions(proEmail, {
    maxWidth: 150,
  });
  contractEndPosition += proEmailEndPosition.h;

  contractEndPosition = contractEndPosition - 30;

  const job = project?.projectInfo?.jobName;
  proposalDoc.setFontSize(8);
  proposalDoc.text(job, horizontalMargin, contractEndPosition, {
    maxWidth: 150,
  });

  const jobEndPosition = proposalDoc.getTextDimensions(job, {
    maxWidth: 150,
  });
  contractEndPosition += jobEndPosition.h;

  const streetAddress = project?.projectInfo?.streetAddressOne;
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.text(
    streetAddress || " ",
    horizontalMargin,
    contractEndPosition,
    { maxWidth: 150 }
  );

  const streetEndPosition = proposalDoc.getTextDimensions(streetAddress, {
    maxWidth: 150,
  });
  contractEndPosition += streetEndPosition.h;

  proposalDoc.setFont("LatoRegular", "normal");
  const locationText = `${project?.projectInfo?.city}, ${project?.projectInfo?.state}, ${project?.projectInfo?.zip}`;
  proposalDoc.text(locationText || "", horizontalMargin, contractEndPosition, {
    maxWidth: 150,
  });
  const locationEndPosition = proposalDoc.getTextDimensions(locationText, {
    maxWidth: 150,
  });
  contractEndPosition += locationEndPosition.h;

  const projectInfo = project?.projectInfo?.primaryContactTelephone
    ? project?.projectInfo?.primaryContactTelephone
    : project?.projectInfo?.primaryContactCell
      ? project?.projectInfo?.primaryContactCell
      : "";
  proposalDoc.text(projectInfo, horizontalMargin, contractEndPosition, {
    maxWidth: 150,
  });
  const projEndPosition = proposalDoc.getTextDimensions(projectInfo, {
    maxWidth: 150,
  });
  contractEndPosition += projEndPosition.h;

  const primaryInfo = project?.projectInfo?.primaryContactEmail;
  proposalDoc.text(primaryInfo, horizontalMargin, contractEndPosition, {
    maxWidth: 150,
  });
  const primEndPosition = proposalDoc.getTextDimensions(primaryInfo, {
    maxWidth: 150,
  });
  contractEndPosition += primEndPosition.h;

  contractEndPosition += 30;
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(12);
  proposalDoc.text("CLIENT", horizontalMargin, contractEndPosition);

  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);

  contractEndPosition += 20;
  let clientContactInfoStartPos = contractEndPosition;
  let ClientMaintenanceInfoStartPos = contractEndPosition;
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(8);
  proposalDoc.text("Type of Contact", horizontalMargin, contractEndPosition);
  proposalDoc.text("Primary", horizontalMargin + 80, contractEndPosition);
  contractEndPosition += 10;

  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  contractEndPosition += 10;

  const primaryContactName = project?.projectInfo.primaryContactName || "";
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(8);
  proposalDoc.text("Name", horizontalMargin, contractEndPosition);
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  proposalDoc.text(
    primaryContactName,
    horizontalMargin + 80,
    contractEndPosition,
    { maxWidth: 100 }
  );
  const primaryContactNameEndPosition = proposalDoc.getTextDimensions(
    primaryContactName,
    {
      maxWidth: 100,
    }
  );
  contractEndPosition += primaryContactNameEndPosition.h;

  const primaryTelephone = project?.projectInfo.primaryContactTelephone || "NA";
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(8);
  proposalDoc.text("Telephone", horizontalMargin, contractEndPosition);
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  proposalDoc.text(
    primaryTelephone,
    horizontalMargin + 80,
    contractEndPosition,
    { maxWidth: 100 }
  );
  const primaryTelephoneEndPosition = proposalDoc.getTextDimensions(
    primaryTelephone,
    {
      maxWidth: 100,
    }
  );
  contractEndPosition += primaryTelephoneEndPosition.h;

  const primaryContact = project?.projectInfo.primaryContactCell || "NA";
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(8);
  proposalDoc.text("Cell", horizontalMargin, contractEndPosition);
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  proposalDoc.text(primaryContact, horizontalMargin + 80, contractEndPosition, {
    maxWidth: 100,
  });
  const primaryContactEndPosition = proposalDoc.getTextDimensions(
    primaryContact,
    {
      maxWidth: 100,
    }
  );
  contractEndPosition += primaryContactEndPosition.h;

  const primaryEmail = project?.projectInfo.primaryContactEmail || "NA";
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(8);
  proposalDoc.text("Email", horizontalMargin, contractEndPosition);
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  proposalDoc.text(primaryEmail, horizontalMargin + 80, contractEndPosition, {
    maxWidth: 100,
  });
  const primaryEmailEndPosition = proposalDoc.getTextDimensions(primaryEmail, {
    maxWidth: 100,
  });
  contractEndPosition += primaryEmailEndPosition.h;

  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(8);
  proposalDoc.text("Address", horizontalMargin, contractEndPosition);

  const proContactAddress = project?.projectInfo.primaryContactAddress;
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  proposalDoc.text(
    proContactAddress || "",
    horizontalMargin + 80,
    contractEndPosition,
    { maxWidth: 100 }
  );
  const proContactEndPosition = proposalDoc.getTextDimensions(
    proContactAddress,
    {
      maxWidth: 100,
    }
  );
  contractEndPosition += proContactEndPosition.h + 10;

  if (project?.projectInfo?.proposal?.onSite) {
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "On-site",
      horizontalMargin + 200,
      clientContactInfoStartPos
    );
    clientContactInfoStartPos += 20;
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);

    const onSiteProjectName = project?.projectInfo.onSiteContactName || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text("Name", horizontalMargin + 200, clientContactInfoStartPos);
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      onSiteProjectName,
      horizontalMargin + 250,
      clientContactInfoStartPos,
      { maxWidth: 85 }
    );
    const onSiteProjectNameEndPosition = proposalDoc.getTextDimensions(
      onSiteProjectName,
      {
        maxWidth: 85,
      }
    );
    clientContactInfoStartPos += onSiteProjectNameEndPosition.h;

    const onSiteProjectTele =
      project?.projectInfo.onSiteContactTelephone || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Telephone",
      horizontalMargin + 200,
      clientContactInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);

    proposalDoc.text(
      onSiteProjectTele,
      horizontalMargin + 250,
      clientContactInfoStartPos,
      { maxWidth: 100 }
    );
    const onSiteProjectTeleEndPosition = proposalDoc.getTextDimensions(
      onSiteProjectTele,
      {
        maxWidth: 100,
      }
    );
    clientContactInfoStartPos += onSiteProjectTeleEndPosition.h;

    const onSiteProjectCell = project?.projectInfo.onSiteContactCell || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text("Cell", horizontalMargin + 200, clientContactInfoStartPos);
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);

    proposalDoc.text(
      onSiteProjectCell,
      horizontalMargin + 250,
      clientContactInfoStartPos,
      { maxWidth: 100 }
    );

    const onSiteProjectCellEndPosition = proposalDoc.getTextDimensions(
      onSiteProjectCell,
      {
        maxWidth: 100,
      }
    );
    clientContactInfoStartPos += onSiteProjectCellEndPosition.h;

    const onSiteProjectContact =
      project?.projectInfo.onSiteContactEmail || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Email",
      horizontalMargin + 200,
      clientContactInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      onSiteProjectContact,
      horizontalMargin + 250,
      clientContactInfoStartPos,
      { maxWidth: 100 }
    );

    const onSiteProjectContactEndPosition = proposalDoc.getTextDimensions(
      onSiteProjectContact,
      {
        maxWidth: 100,
      }
    );
    clientContactInfoStartPos += onSiteProjectContactEndPosition.h;

    const onSiteProjectAddress =
      project?.projectInfo.onSiteContactAddress || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Address",
      horizontalMargin + 200,
      clientContactInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);

    proposalDoc.text(
      onSiteProjectAddress,
      horizontalMargin + 250,
      clientContactInfoStartPos,
      { maxWidth: 100 }
    );
    const onSiteProjectAddressEndPosition = proposalDoc.getTextDimensions(
      onSiteProjectAddress,
      {
        maxWidth: 100,
      }
    );
    clientContactInfoStartPos += onSiteProjectAddressEndPosition.h + 10;
    contractEndPosition = clientContactInfoStartPos + 10;
  }

  if (project?.projectInfo?.proposal?.maintenance) {
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Maintenance",
      horizontalMargin + 350,
      ClientMaintenanceInfoStartPos
    );
    ClientMaintenanceInfoStartPos += 20;

    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);

    const maintenanceProjectInfo =
      project?.projectInfo.maintenanceContactName || "";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Name",
      horizontalMargin + 350,
      ClientMaintenanceInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      maintenanceProjectInfo,
      horizontalMargin + 400,
      ClientMaintenanceInfoStartPos,
      { maxWidth: 100 }
    );
    const maintenanceProjectInfoEndPoint = proposalDoc.getTextDimensions(
      maintenanceProjectInfo,
      {
        maxWidth: 100,
      }
    );
    ClientMaintenanceInfoStartPos += maintenanceProjectInfoEndPoint.h;

    const maintenanceProjectTele =
      project?.projectInfo.maintenanceContactTelephone || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Telephone",
      horizontalMargin + 350,
      ClientMaintenanceInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      maintenanceProjectTele,
      horizontalMargin + 400,
      ClientMaintenanceInfoStartPos,
      { maxWidth: 100 }
    );
    const maintenanceProjectTeleEndPoint = proposalDoc.getTextDimensions(
      maintenanceProjectTele,
      {
        maxWidth: 100,
      }
    );
    ClientMaintenanceInfoStartPos += maintenanceProjectTeleEndPoint.h;

    const maintenanceProjectCell =
      project?.projectInfo.maintenanceContactCell || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Cell",
      horizontalMargin + 350,
      ClientMaintenanceInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      maintenanceProjectCell,
      horizontalMargin + 400,
      ClientMaintenanceInfoStartPos,
      { maxWidth: 100 }
    );
    const maintenanceProjectCellEndPoint = proposalDoc.getTextDimensions(
      maintenanceProjectCell,
      {
        maxWidth: 100,
      }
    );
    ClientMaintenanceInfoStartPos += maintenanceProjectCellEndPoint.h;

    const maintenanceProjectEmail =
      project?.projectInfo.maintenanceContactEmail || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Email",
      horizontalMargin + 350,
      ClientMaintenanceInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      maintenanceProjectEmail,
      horizontalMargin + 400,
      ClientMaintenanceInfoStartPos,
      { maxWidth: 100 }
    );
    const maintenanceProjectEmailEndPoint = proposalDoc.getTextDimensions(
      maintenanceProjectEmail,
      {
        maxWidth: 100,
      }
    );
    ClientMaintenanceInfoStartPos += maintenanceProjectEmailEndPoint.h;

    const maintenanceProjectAddress =
      project?.projectInfo.maintenanceContactAddress || "NA";
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      "Address",
      horizontalMargin + 350,
      ClientMaintenanceInfoStartPos
    );
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.setFontSize(8);
    proposalDoc.text(
      maintenanceProjectAddress,
      horizontalMargin + 400,
      ClientMaintenanceInfoStartPos,
      { maxWidth: 100 }
    );
    const maintenanceProjectAddressEndPoint = proposalDoc.getTextDimensions(
      maintenanceProjectAddress,
      {
        maxWidth: 100,
      }
    );
    ClientMaintenanceInfoStartPos += maintenanceProjectAddressEndPoint.h + 10;
    contractEndPosition = ClientMaintenanceInfoStartPos + 10;
  }

  contractEndPosition += 30;

  const columns = [
    { header: "Options", dataKey: "title" },
    { header: "Price", dataKey: "estimatedPrice" },
  ];

  let tableEndYPos = contractEndPosition + 10;

  let total = 0;
  const includeOptions = project?.options.filter(
    (item: any) => item.optionInfo.included === "included"
  );
  const rows = includeOptions.map((option: any) => {
    let title = option.optionInfo.title;
    let estimatedPriceIndex = option.pjcc.findIndex(
      (item: any) => item.name === "Estimated Price"
    );
    let estimatedPrice = option.pjcc[estimatedPriceIndex].totalAmount;
    total += Math.round(estimatedPrice);
    return {
      title,
      estimatedPrice: `$${estimatedPrice.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`,
    };
  });

  const sumOfAllOptionsPrices = project.options?.reduce(
    (initial: any, curValue: any) => {
      return initial + parseFloat(curValue.pjcc[15].totalAmount);
    },
    0
  );
  const fullBidRevenueIndex = project.pjcc.findIndex(
    (item: any) => item.name === "Full Bid Revenue"
  );
  if (project?.options?.length > 0) {
    proposalDoc.setFontSize(12);
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.text("PRICE SUMMARY", horizontalMargin, contractEndPosition);
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, 30, 565);

    const columns = [
      { header: "Options", dataKey: "title" },
      { header: "Price", dataKey: "estimatedPrice" },
    ];

    let tableEndYPos = contractEndPosition + 10;

    let total = 0;
    const includeOptions = project?.options.filter(
      (item: any) => item.optionInfo.included === "included"
    );

    const rows = [];
    includeOptions.forEach((option: any) => {
      let estimatedPriceIndex = option.pjcc.findIndex(
        (item: any) => item.name === "Estimated Price"
      );
      let estimatedPrice = option.pjcc[estimatedPriceIndex].totalAmount;
      if (estimatedPrice > 0) {
        let title = option.optionInfo.title;
        total += Math.round(estimatedPrice);
        rows.push({
          title,
          estimatedPrice: `$${estimatedPrice.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`,
        });
      }
    });

    const sumOfAllOptionsPrices = project.options?.reduce(
      (initial: any, curValue: any) => {
        return initial + parseFloat(curValue.pjcc[15].totalAmount);
      },
      0
    );
    const fullBidRevenueIndex = project.pjcc.findIndex(
      (item: any) => item.name === "Full Bid Revenue"
    );
    const expressionDiscount =
      project?.pjcc[fullBidRevenueIndex]?.totalAmount - sumOfAllOptionsPrices;

    let discountsArray: { title: string; estimatedPrice: string }[] = [];
    project?.projectInfo?.discounts.map((item: any) => {
      let discountedValue = 0;
      let description = "";
      if (item.amountType === "percentage") {
        discountedValue = total * (item.amount / 100);
        total -= Math.round(discountedValue);
        description = item.description;
      } else if (item.amountType === "dollar") {
        discountedValue = item.amount;
        total -= Math.round(discountedValue);
        description = item.description;
      } else if (item.amountType === "expression") {
        discountedValue = expressionDiscount;
        total += Number(expressionDiscount);
        description = item.description;
      }
      discountsArray.push({
        title: description,
        estimatedPrice:
          item.amountType === "expression"
            ? `-$${Math.abs(discountedValue).toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`
            : `-$${discountedValue.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`,
      });
    });

    rows.push(...discountsArray);

    rows.push({
      title: "Total",
      estimatedPrice: `$${total.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`,
    });

    autoTable(proposalDoc, {
      body: rows,
      showHead: false,
      theme: "plain",
      columns: columns,
      startY: tableEndYPos,
      margin: { horizontal: 30 },
      styles: {
        lineColor: "#585858",
        lineWidth: 0,
        minCellHeight: 5,
      },
      headStyles: {
        fillColor: "#FDB913",
        halign: "center",
        textColor: "black",
      },
      bodyStyles: { fillColor: "#FFFFFF", textColor: "black", fontSize: 8 },
      columnStyles: {
        1: {
          halign: "right",
        },
      },
      didParseCell: (data) => {
        if (data.row.index === rows.length - 1) {
          data.cell.styles.fontStyle = "bold";
        }
      },
      didDrawCell: (data) => {
        const { cell, row, column, table } = data;

        if (column.dataKey === "title" || column.dataKey === "estimatedPrice") {
          proposalDoc.setLineWidth(2);
          proposalDoc.setDrawColor("#585858");
          proposalDoc.line(
            cell.x,
            cell.y + cell.height,
            cell.x + cell.width,
            cell.y + cell.height
          );
        }

        tableEndYPos = cell.y + cell.height;
      },
      didDrawPage: (data) => {
        tableEndYPos = data.cursor?.y || tableEndYPos;
      },
    });

    tableEndYPos += 15;
    contractEndPosition = tableEndYPos + 15;

    if (contractEndPosition >= pageLimit) {
      proposalDoc.addPage();
      contractEndPosition = pageTopMargin;
    }
  }

  if (project.projectInfo.proposal !== undefined) {
    proposalDoc.setFontSize(12);
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.text("PROJECT SUMMARY", horizontalMargin, contractEndPosition);
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, 30, 565);
    contractEndPosition += 20;
    if (contractEndPosition >= pageLimit) {
      proposalDoc.addPage();
      contractEndPosition = pageTopMargin;
    }

    proposalDoc.setFontSize(8);
    proposalDoc.setFont("LatoRegular", "normal");

    let optionTitles = project.options
      .filter((item: any) => item.optionInfo.included === "included")
      .map((item: any) => item.optionInfo.title);

    if (contractEndPosition >= pageLimit) {
      proposalDoc.addPage();
      contractEndPosition = pageTopMargin;
    }
    contractEndPosition += 10;
    let generatedSummary = `Project Includes: ${optionTitles.join(", ")}. For specific details please refer to the Included Details section below.`;
    let splitGeneratedSummary = proposalDoc.splitTextToSize(
      generatedSummary,
      540
    );

    let summaryHeight = splitGeneratedSummary.length * 10;

    if (contractEndPosition + summaryHeight >= pageLimit) {
      proposalDoc.addPage();
      contractEndPosition = pageTopMargin;
    }

    proposalDoc.text(
      splitGeneratedSummary,
      horizontalMargin,
      contractEndPosition
    );

    contractEndPosition += summaryHeight + 10;

    if (contractEndPosition >= pageLimit) {
      proposalDoc.addPage();
      contractEndPosition = pageTopMargin;
    }

    contractEndPosition += 20;

    try {
      contractEndPosition = await generateProposalPdfWithHtml(
        proposalDoc,
        parsedHTML,
        contractEndPosition
      );

      if (contractEndPosition >= pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      } else {
        contractEndPosition += 20;
      }
    } catch (error) {
      console.error("Error adding HTML: ", error);
    }
  }

  if (
    project?.options?.length &&
    project.options.some(
      (option: any) => option.optionInfo?.included === "included"
    )
  ) {
    proposalDoc.setFontSize(12);
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.text("INCLUDED DETAILS", horizontalMargin, contractEndPosition);
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, 30, 565);

    contractEndPosition += 20;

    const projectDetailsHeader = [
      { header: "Surface", dataKey: "item" },
      { header: "Product", dataKey: "material" },
      { header: "Color", dataKey: "color" },
      { header: "Coats", dataKey: "coats" },
      // { header: "Primer", dataKey: "primerMaterial" },
      // { header: "Primer Gallons", dataKey: "primerGallons" },
    ];
    const includedOptions = project?.options.filter(
      (opt: any) => opt.optionInfo?.included === "included"
    );
    const data = includedOptions.map((opt: any) => {
      return {
        title: opt.optionInfo.title,
        description: opt.optionInfo.proposalGeneralDescription,
        rates: opt.rates?.paintRates,
      };
    });

    data?.forEach((dataItem: any) => {
      proposalDoc.setFont("LatoRegular", "bold");
      proposalDoc.setFontSize(10);

      var startXCleanUp = horizontalMargin;
      let splitString = proposalDoc.splitTextToSize(dataItem.title, 560);
      const startXCached = startXCleanUp;

      splitString.map((text: any, i: any) => {
        if (text) {
          const arrayOfNormalAndBoldText = text.split("**");

          arrayOfNormalAndBoldText.map((textItems: any, j: any) => {
            if (j === 1) {
              proposalDoc.setFont("LatoRegular", "bold");
            } else {
              proposalDoc.setFont("LatoRegular", "normal");
            }

            // Check if adding this text will overflow the page
            if (contractEndPosition >= pageLimit) {
              proposalDoc.addPage();
              contractEndPosition = pageTopMargin;
            }

            proposalDoc.text(textItems, startXCleanUp, contractEndPosition);
            startXCleanUp =
              startXCleanUp + proposalDoc.getStringUnitWidth(textItems) * 8;
          });

          // Reset startXCleanUp after each line
          startXCleanUp = startXCached;
          contractEndPosition += 15;

          // Check again after moving to the next line
          if (contractEndPosition >= pageLimit) {
            proposalDoc.addPage();
            contractEndPosition = pageTopMargin;
          }
        }
      });

      contractEndPosition += 15;
      if (contractEndPosition >= pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }
      proposalDoc.setFont("LatoRegular", "normal");
      proposalDoc.setFontSize(8);
      const optionGDIndex = project?.options.findIndex(
        (item: any) =>
          item.optionInfo.title === dataItem.title &&
          item.optionInfo.included === "included"
      );
      const optionGD = dataItem.description;
      if (optionGD) {
        const lines = optionGD.split("\n");
        lines.forEach((line: any) => {
          line = line.replaceAll("\t", " ");
          let currentX = horizontalMargin;
          let start = 0;
          while (start < line.length) {
            let linkStart = line.indexOf("[", start);
            if (linkStart === -1) {
              let splitText = proposalDoc.splitTextToSize(line, 540);
              splitText.forEach((part: any) => {
                // Check if adding this text will overflow the page
                if (
                  contractEndPosition + proposalDoc.getTextDimensions(part).h >
                  pageLimit
                ) {
                  proposalDoc.addPage();
                  contractEndPosition = pageTopMargin;
                }

                proposalDoc.text(part, horizontalMargin, contractEndPosition);
                contractEndPosition += 8;
              });
              start = line.length;
            } else {
              if (linkStart > start) {
                let txt = line.substring(start, linkStart);
                let splitText = proposalDoc.splitTextToSize(txt, 540);
                splitText.forEach((part: any) => {
                  // Check if adding this text will overflow the page
                  if (
                    contractEndPosition +
                      proposalDoc.getTextDimensions(part).h >
                    pageLimit
                  ) {
                    proposalDoc.addPage();
                    contractEndPosition = pageTopMargin;
                  }

                  proposalDoc.text(part, currentX, contractEndPosition);
                  contractEndPosition += 8;
                });
              }
              let linkEnd = line.indexOf("]", linkStart);
              if (linkEnd === -1) {
                start = linkStart + 1;
              } else {
                let linkText = line.substring(linkStart + 1, linkEnd);
                proposalDoc.setTextColor("#fdb913");
                if (
                  !linkText.startsWith("http://") &&
                  !linkText.startsWith("https://")
                ) {
                  linkText = "http://" + linkText;
                }
                proposalDoc.textWithLink(
                  linkText,
                  currentX,
                  contractEndPosition,
                  { linkText: linkText }
                );

                contractEndPosition += proposalDoc.getLineHeight() + 5;
                start = linkEnd + 1;
              }
            }
          }
        });
      }

      contractEndPosition += 10;

      proposalDoc.setTextColor("black");
      const newRows = dataItem.rates;
      const tableDataMapped = newRows
        .filter((row: any) => {
          return (
            (row.primerGallons > 0 || row.paintGallons > 0) &&
            row.showLaborRates &&
            row.isDeleted === false
          );
        })
        .map((row: any) => {
          let paintMaterial = row.paintMaterial;
          let primerMaterial = row.primerMaterial;
          let gallons = "";
          let material = "";
          let color = "      ";
          let coats = "";
          let lines = 0;
          if (
            !primerMaterial ||
            primerMaterial === "undefined:undefined" ||
            row.primerGallons === 0
          ) {
            // primerMaterial = "";
          } else {
            color += "N/A\n      ";
            material += `${primerMaterial.manufacturer ? primerMaterial.manufacturer + ":" : ""}${primerMaterial.product}${primerMaterial.sheen ? ":" + primerMaterial.sheen : ""}\n`;
            gallons += `${row.primerGallons.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}\n`;
            coats = "Full Prime\n";
            lines++;
          }

          if (
            !paintMaterial ||
            paintMaterial === "undefined:undefined" ||
            row.paintGallons === 0
          ) {
            // paintMaterial = "";
          } else {
            color += row.color;
            material += `${paintMaterial.manufacturer ? paintMaterial.manufacturer + ":" : ""}${paintMaterial.product}${paintMaterial.sheen ? ":" + paintMaterial.sheen : ""}`;
            gallons += row.paintGallons.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            coats += row.coats;
            lines++;
          }
          if (paintMaterial === "" && row.paintGallons >= 0) {
            color += row.color;
            material += `${paintMaterial.manufacturer ? paintMaterial.manufacturer + ":" : ""}${paintMaterial.product}${paintMaterial.sheen ? ":" + paintMaterial.sheen : ""}`;
            gallons += row.paintGallons.toFixed(2);
            coats += row.coats;
            lines++;
          }

          let colorObj = adminDefaults.colors.find(
            (item: any) => item.name === row.color
          );

          return {
            ...row,
            gallons,
            material,
            color,
            colorCode: colorObj?.hexCode,
            coats,
            lines,
          };
        });

      const detailsDrawLineYPositions: any[] = [];
      let tableRowIndex = -1;
      if (tableDataMapped.length > 0) {
        autoTable(proposalDoc, {
          body: tableDataMapped,
          columns: projectDetailsHeader,
          startY: contractEndPosition,
          margin: { horizontal: 30 },
          theme: "plain",
          styles: {
            lineColor: "#585858",
            lineWidth: 0,
            minCellHeight: 5,
          },
          headStyles: {
            fillColor: "#FDB913",
            halign: "left",
            textColor: "black",
            fontSize: 8,
          },
          bodyStyles: { fontSize: 8, textColor: "black" },
          columnStyles: {
            0: {
              cellWidth: 75,
            },
            1: {
              cellWidth: 230,
            },
            2: {
              cellWidth: 185,
            },
            3: {
              halign: "center",
              cellWidth: 45,
            },
          },
          // eslint-disable-next-line no-loop-func
          didDrawPage: (data) => {
            contractEndPosition = data.cursor?.y || contractEndPosition;
            // contractEndPosition = data.table.finalY || 0;
          },
          didDrawCell: (data) => {
            const rowEndYPos = data.cursor ? data.cursor.y : 0;
            if (data.row.section !== "head") {
              if (tableRowIndex !== data.row.index) {
                tableRowIndex = data.row.index;
                const temp = {
                  pageNumber: proposalDoc.getCurrentPageInfo().pageNumber,
                  verticalPosition: Math.round(rowEndYPos),
                };
                detailsDrawLineYPositions.push(temp);
              }
              if (data.row.index >= 0) {
                let xPos = data.cursor ? data.cursor.x : 0;
                let yPos = data.cursor ? data.cursor.y : 0;
                let startYColor = 5;
                if (tableDataMapped[data.row.index].lines >= 2) {
                  startYColor = 14;
                }
                if (
                  data.column.dataKey === "color" &&
                  tableDataMapped[data.row.index]?.colorCode
                ) {
                  let fillColor = proposalDoc.getFillColor();
                  let drawColor = proposalDoc.getDrawColor();
                  proposalDoc.setDrawColor(0, 0, 0);
                  proposalDoc.setFillColor(
                    tableDataMapped[data.row.index].colorCode
                  );
                  proposalDoc.rect(xPos + 5, yPos + startYColor, 9, 9, "F");
                  proposalDoc.setFillColor(fillColor);
                  proposalDoc.setDrawColor(drawColor);
                }
              }
            }
          },
          didParseCell: (data) => {
            if (data.section === "head") {
              if (
                data.column.dataKey === "coats" ||
                data.column.dataKey === "gallons"
              ) {
                data.cell.styles.halign = "center";
              }
            }
          },
        });
      }
      detailsDrawLineYPositions.forEach((val, index) => {
        proposalDoc.setPage(val.pageNumber);
        proposalDoc.setDrawColor("#000000");
        proposalDoc.setFillColor("#D3D3D3");
        proposalDoc.setLineWidth(1);

        proposalDoc.line(
          horizontalMargin,
          val.verticalPosition,
          565,
          val.verticalPosition,
          "F"
        );
      });

      proposalDoc.line(
        horizontalMargin,
        contractEndPosition,
        565,
        contractEndPosition,
        "F"
      );
      proposalDoc.setDrawColor(defaultDrawCol);
      proposalDoc.setFillColor(defaultFillCol);
      contractEndPosition += 30;
      if (contractEndPosition >= pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }

      if (contractEndPosition >= pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }
    });
  }

  if (
    project?.projectInfo?.proposal?.setup !== undefined &&
    project.projectInfo.proposal.setup.length > 0
  ) {
    proposalDoc.setFontSize(12);
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.text("SETUP", horizontalMargin, contractEndPosition);
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, 30, 565);
    proposalDoc.setFontSize(8);
    contractEndPosition += 20;
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.text("Customer to:", horizontalMargin, contractEndPosition);
    proposalDoc.text(
      "CertaPro will cover and protect:",
      horizontalMargin + 180,
      contractEndPosition
    );

    proposalDoc.text(
      "CertaPro will:",
      horizontalMargin + 355,
      contractEndPosition
    );

    let customerTaskHeader = [{ header: "", dataKey: "task" }];

    let customerTask = project?.projectInfo?.proposal?.setup?.filter(
      (item: any) => item.title === "customer tasks"
    );
    // let customerTaskData = customerTask.map((item: any)=> {
    //   return {
    //     ...item,
    //     task: '\u2022 ' +item.task
    //   }
    // })

    let certaproCoverAndProtectTaskHeader = [
      { header: "cover and protect", dataKey: "task" },
    ];
    let certaproCoverAndProtectTask =
      project?.projectInfo?.proposal?.setup?.filter(
        (item: any) => item.category === "Cover and protect"
      );
    // let certaproCoverAndProtectTaskData = certaproCoverAndProtectTask.map((item: any)=> {
    //   return {
    //     ...item,
    //     task: '\u2022 ' +item.task
    //   }
    // })

    let certaproWillDoTaskHeader = [{ header: "will do", dataKey: "task" }];
    let certaproWillDoTask = project?.projectInfo?.proposal?.setup?.filter(
      (item: any) => item.category === "will do"
    );
    // let certaproWillDoTaskData = certaproWillDoTask.map((item: any)=> {
    //   return {
    //     ...item,
    //     task: '\u2022 ' +item.task
    //   }
    // })

    contractEndPosition += 5;
    let customerTableEndYPos = contractEndPosition;
    let coverTableEndYPos = contractEndPosition;
    let willDoTableEndYPos = contractEndPosition;

    const pageStart = proposalDoc.getCurrentPageInfo().pageNumber;
    let customerTaskPageNumber = pageStart;

    autoTable(proposalDoc, {
      body: customerTask,
      columns: customerTaskHeader,
      startY: contractEndPosition,
      margin: { horizontal: 30 },
      tableWidth: 180,
      styles: {
        lineColor: "#D3D3D3",
        lineWidth: 0,
        cellPadding: {
          left: 15,
          right: 5,
          top: 5,
          bottom: 5,
        },
      },
      // headStyles: { fillColor: "#FDB913", halign: "center" },
      bodyStyles: { fontSize: 8, font: "LatoRegular", textColor: "black" },
      columnStyles: {
        0: {
          fillColor: 255,
        },
      },
      didDrawCell: (data) => {
        if (data.row.section !== "head") {
          proposalDoc.setFillColor(0, 0, 0);
          proposalDoc.setDrawColor(0, 0, 0);
          proposalDoc.circle(data.cell.x + 10, data.cell.y + 9, 1, "F");
        }
        // customerTaskPageNumber = data.pageNumber;
      },
      // eslint-disable-next-line no-loop-func
      didDrawPage: (data) => {
        customerTableEndYPos = data.cursor?.y || customerTableEndYPos;
        // contractEndPosition = data.table.finalY || 0;
      },
    });

    customerTaskPageNumber = proposalDoc.getCurrentPageInfo().pageNumber;

    proposalDoc.setPage(pageStart);
    let coverAndProtectPageNumber: any = proposalDoc.setPage(pageStart);
    autoTable(proposalDoc, {
      body: certaproCoverAndProtectTask,
      columns: certaproCoverAndProtectTaskHeader,
      startY: contractEndPosition,
      margin: { horizontal: 210 },
      tableWidth: 150,
      showHead: false,
      styles: {
        lineColor: "#D3D3D3",
        lineWidth: 0,
        cellPadding: {
          left: 15,
          right: 5,
          top: 5,
          bottom: 5,
        },
      },
      headStyles: { fillColor: "#faf4ed", halign: "left", textColor: "black" },
      bodyStyles: { fontSize: 8, font: "LatoRegular", textColor: "black" },
      columnStyles: {
        0: {
          fillColor: 255,
        },
      },
      didDrawCell: (data) => {
        if (data.row.section !== "head") {
          proposalDoc.setFillColor(0, 0, 0);
          proposalDoc.setDrawColor(0, 0, 0);
          proposalDoc.circle(data.cell.x + 10, data.cell.y + 9, 1, "F");
        }
        // coverAndProtectPageNumber = data.pageNumber;
      },
      // eslint-disable-next-line no-loop-func
      didDrawPage: (data) => {
        coverTableEndYPos = data.cursor?.y || coverTableEndYPos;
        // contractEndPosition = data.table.finalY || 0;
      },
    });

    coverAndProtectPageNumber = proposalDoc.getCurrentPageInfo().pageNumber;

    proposalDoc.setPage(pageStart);
    let certaproWillDoTaskPageNumber: any = proposalDoc.setPage(pageStart);
    autoTable(proposalDoc, {
      body: certaproWillDoTask,
      columns: certaproWillDoTaskHeader,
      startY: contractEndPosition,
      margin: { horizontal: 385 },
      tableWidth: 180,
      showHead: false,
      styles: {
        lineColor: "#D3D3D3",
        lineWidth: 0,
        cellPadding: {
          left: 15,
          right: 5,
          top: 5,
          bottom: 5,
        },
      },
      headStyles: { fillColor: "#faf4ed", halign: "left", textColor: "black" },
      bodyStyles: { fontSize: 8, font: "LatoRegular", textColor: "black" },
      columnStyles: {
        0: {
          fillColor: 255,
        },
      },
      didDrawCell: (data) => {
        if (data.row.section !== "head") {
          proposalDoc.setFillColor(0, 0, 0);
          proposalDoc.setDrawColor(0, 0, 0);
          proposalDoc.circle(data.cell.x + 10, data.cell.y + 9, 1, "F");
        }
        // certaproWillDoTaskPageNumber = data.pageNumber;
      },
      // eslint-disable-next-line no-loop-func
      didDrawPage: (data) => {
        willDoTableEndYPos = data.cursor?.y || willDoTableEndYPos;
        // contractEndPosition = data.table.finalY || 0;
      },
    });
    certaproWillDoTaskPageNumber = proposalDoc.getCurrentPageInfo().pageNumber;

    let currentPageNumber = Math.max(
      customerTaskPageNumber,
      coverAndProtectPageNumber,
      certaproWillDoTaskPageNumber
    );

    proposalDoc.setPage(currentPageNumber);

    let maxTableHeight = Math.max(
      customerTaskPageNumber === currentPageNumber ? customerTableEndYPos : 0,
      coverAndProtectPageNumber === currentPageNumber ? coverTableEndYPos : 0,
      certaproWillDoTaskPageNumber === currentPageNumber
        ? willDoTableEndYPos
        : 0
    );

    // proposalDoc.addPage();
    if (maxTableHeight === 0) {
      contractEndPosition += 20;
    } else {
      contractEndPosition = maxTableHeight + 20;
    }
    // contractEndPosition = 20;
  }

  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }
  if (
    project?.projectInfo?.proposal?.surface?.length !== undefined &&
    project.projectInfo.proposal.surface.length > 0
  ) {
    proposalDoc.setFontSize(12);
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.text(
      "SURFACE PREPARATIONS",
      horizontalMargin,
      contractEndPosition
    );

    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, 30, 565);

    contractEndPosition += 20;
    proposalDoc.setFontSize(8);

    var startX = horizontalMargin;
    let startY = contractEndPosition;

    project?.projectInfo?.proposal?.surface?.map((item: any) => {
      let surfaceDetail = `•  **${item.title}:** ${item.detail}`;
      let splitString = proposalDoc.splitTextToSize(surfaceDetail, 540);
      const startXCached = startX;
      splitString.map((text: any, i: any) => {
        if (text) {
          const arrayOfNormalAndBoldText = text.split("**");
          arrayOfNormalAndBoldText.map((textItems: any, j: any) => {
            if (startY >= pageLimit) {
              proposalDoc.addPage();
              contractEndPosition = pageTopMargin;
              startY = pageTopMargin;
            }
            if (j === 1) {
              proposalDoc.setFont("LatoRegular", "bold");
            } else {
              proposalDoc.setFont("LatoRegular", "normal");
            }
            if (i === 0) {
              proposalDoc.text(textItems, startX + 5, startY);
            } else {
              proposalDoc.text(textItems, startX + 13, startY);
            }
            startX = startX + proposalDoc.getStringUnitWidth(textItems) * 8;
          });
          startX = startXCached;
          startY += 10;
        }
      });
    });

    contractEndPosition = startY + 20;

    if (contractEndPosition >= pageLimit) {
      proposalDoc.addPage();
      contractEndPosition = pageTopMargin;
    }
  }
  proposalDoc.setFontSize(14);
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text("CLEAN UP", horizontalMargin, contractEndPosition);
  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, 30, 565);

  contractEndPosition += 20;
  proposalDoc.setFontSize(8);

  let startXCleanUp = horizontalMargin;

  let cleanUp =
    "**UPON COMPLETION:** Includes thorough clean up of paint, tape, plastic, dust and debris in areas where work was completed. All tools, supplies & equipment will be removed from the property.";

  let splitString = proposalDoc.splitTextToSize(cleanUp, 560);
  const startXCached = startXCleanUp;
  splitString.map((text: any, i: any) => {
    if (text) {
      const arrayOfNormalAndBoldText = text.split("**");
      arrayOfNormalAndBoldText.map((textItems: any, j: any) => {
        if (j === 1) {
          proposalDoc.setFont("LatoRegular", "bold");
        } else {
          proposalDoc.setFont("LatoRegular", "normal");
        }
        proposalDoc.text(textItems, startXCleanUp, contractEndPosition);
        startXCleanUp =
          startXCleanUp + proposalDoc.getStringUnitWidth(textItems) * 8;
      });
      startXCleanUp = startXCached;
      contractEndPosition += 10;
    }
  });

  contractEndPosition += 20;

  if (contractEndPosition >= pageLimit) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
  }

  let termsAndConditions =
    project?.projectInfo?.proposal?.termsAndConditions?.map((item: any) => {
      return `**${item.title}:** ${item.detail}`;
    });
  if (!(termsAndConditions == undefined) && !(termsAndConditions.length == 0)) {
    proposalDoc.setFontSize(14);
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.text(
      "TERMS & CONDITIONS",
      horizontalMargin,
      contractEndPosition
    );
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, 30, 565);

    contractEndPosition += 20;

    let startXTerms = horizontalMargin;
    proposalDoc.setFontSize(8);

    termsAndConditions.map((item: any) => {
      const bulletItem = "•  " + item.trim(); // Add bullet before each item
      let splitTermsString = proposalDoc.splitTextToSize(bulletItem, 520);
      const startXCached = startXTerms;
      splitTermsString.map((text: any, i: any) => {
        if (text) {
          const arrayOfNormalAndBoldText = text.split("**");
          arrayOfNormalAndBoldText.map((textItems: any, j: any) => {
            if (j === 1) {
              proposalDoc.setFont("LatoRegular", "bold");
            } else {
              proposalDoc.setFont("LatoRegular", "normal");
            }
            if (i === 0) {
              proposalDoc.text(textItems, startXTerms + 5, contractEndPosition);
            } else {
              proposalDoc.text(
                textItems,
                startXTerms + 13,
                contractEndPosition
              );
            }
            startXTerms =
              startXTerms + proposalDoc.getStringUnitWidth(textItems) * 8;
          });
          startXTerms = startXCached;
          contractEndPosition += 10;
        }
        if (contractEndPosition >= pageLimit - 50) {
          proposalDoc.addPage();
          contractEndPosition = pageTopMargin;
        }
      });
    });
  }

  contractEndPosition += 20;

  if (contractEndPosition >= pageLimit - 100) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin + 50;
  }

  proposalDoc.text(
    "For And On Behalf Of",
    horizontalMargin,
    contractEndPosition
  );

  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setTextColor("#FDB913");

  proposalDoc.text(
    "CERTAPRO PAINTERS",
    horizontalMargin,
    contractEndPosition + 10
  );

  proposalDoc.setTextColor("black");

  proposalDoc.setFont("LatoRegular", "normal");

  proposalDoc.setLineWidth(1);
  proposalDoc.setDrawColor(defaultDrawCol);

  proposalDoc.line(
    horizontalMargin + 360,
    contractEndPosition,
    550,
    contractEndPosition
  );

  contractEndPosition += 10;

  proposalDoc.setFontSize(8);
  proposalDoc.text(
    "Authorized Client Signature(S)",
    horizontalMargin + 360,
    contractEndPosition
  );
  contractEndPosition += 30;

  proposalDoc.line(
    horizontalMargin,
    contractEndPosition,
    150,
    contractEndPosition
  );
  proposalDoc.line(
    horizontalMargin + 360,
    contractEndPosition,
    550,
    contractEndPosition
  );

  contractEndPosition += 10;

  proposalDoc.setFontSize(8);

  proposalDoc.text(
    "Authorized Signature(S)",
    horizontalMargin,
    contractEndPosition
  );
  proposalDoc.text(
    "Authorized Client Representative Name & Title",
    horizontalMargin + 360,
    contractEndPosition
  );

  contractEndPosition += 30;

  proposalDoc.line(
    horizontalMargin + 360,
    contractEndPosition,
    550,
    contractEndPosition
  );

  contractEndPosition += 10;
  proposalDoc.setFontSize(8);

  proposalDoc.text("Client", horizontalMargin + 360, contractEndPosition);

  contractEndPosition += 25;
  proposalDoc.setFontSize(14);
  proposalDoc.setFont("LatoRegular", "bold");

  let optionalOptionInfo = project?.options
    .filter((option: any) => option?.optionInfo?.included === "optional")
    .map((item: any) => {
      let estimatedPriceObj = item?.pjcc.filter(
        (item: any) => item.name === "Estimated Price"
      );
      return [
        `${item.optionInfo.title}`,
        `$${Math.round(estimatedPriceObj[0].totalAmount).toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }
        )}`,
      ];
    });

  if (optionalOptionInfo.length > 0) {
    proposalDoc.text(
      "ADDITIONAL OPTIONS",
      horizontalMargin,
      contractEndPosition
    );
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, 30, 565);

    contractEndPosition += 20;
    proposalDoc.setFontSize(10);
    proposalDoc.setFont("LatoRegular");
    proposalDoc.setTextColor("red");
    proposalDoc.text(
      "NOTE: NOT INCLUDED IN THE ABOVE PRICE",
      horizontalMargin,
      contractEndPosition
    );
    proposalDoc.setTextColor("black");

    contractEndPosition += 15;
    autoTable(proposalDoc, {
      body: optionalOptionInfo,
      showHead: true,
      head: [
        [
          {
            content: "",
            colSpan: 2,
            styles: { halign: "right" },
          },
          {
            content: "ACCEPTANCE OF ADDITIONAL CHARGES",
            colSpan: 2,
            styles: { halign: "center" },
          },
        ],
        ["Item & Description", "Price", "CertaPro Initials", "Client Initials"],
      ],
      theme: "plain",
      // columns: columns,
      startY: contractEndPosition,
      margin: { horizontal: 30 },
      styles: {
        lineColor: "black",
        lineWidth: 0.5,
        minCellHeight: 5,
      },
      headStyles: {
        // fillColor: "#FDB913",
        halign: "center",
        textColor: "black",
      },
      bodyStyles: { fillColor: "#FFFFFF", textColor: "black", fontSize: 8 },
      columnStyles: {
        1: {
          halign: "center",
        },
        2: {
          cellWidth: 120,
        },
        3: {
          cellWidth: 120,
        },
      },
      // eslint-disable-next-line no-loop-func
      didDrawPage: (data) => {
        tableEndYPos = data.cursor?.y || tableEndYPos;
        // tableEndYPos = data.table.finalY || 0;
      },
      // didParseCell: (data) => {
      //   if (data.row.index === rows.length - 1) {
      //     data.cell.styles.fontStyle = "bold";
      //   }
      // },
      didDrawCell: (data) => {
        const rowEndYPos = data.cursor?.y || tableEndYPos;
        // if (rowIndex !== data.row.index) {
        //   rowIndex = data.row.index;
        //   summaryDrawLineYPositions.push(Math.round(rowEndYPos));
        // }
      },
    });

    contractEndPosition = tableEndYPos + 15;

    let finalPriceWithOptionalOptions =
      optionalOptionInfo.reduce(
        (acc: any, item: any) =>
          acc + Math.round(parseFloat(item[1].replace(/[$,]/g, ""))),
        0
      ) + total;

    autoTable(proposalDoc, {
      body: [
        [
          [
            `$${(
              optionalOptionInfo.reduce(
                (acc: any, item: any) =>
                  acc + parseFloat(item[1].replace(/[$,]/g, "")),
                0
              ) + total
            ).toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}`,
          ],
        ],
      ],
      showHead: true,
      head: [
        [
          {
            content: "",
            colSpan: 1,
            styles: { halign: "right" },
          },
          {
            content: "ACCEPTANCE OF ADDITIONAL CHARGES",
            colSpan: 2,
            styles: { halign: "center" },
          },
        ],
        [
          "FINAL NEW PROJECT PRICE WITH OPTIONS",
          "CertaPro Initials",
          "Client Initials",
        ],
      ],
      theme: "plain",
      // columns: columns,
      startY: contractEndPosition,
      margin: { horizontal: 30 },
      styles: {
        lineColor: "black",
        lineWidth: 0.5,
        minCellHeight: 5,
      },
      headStyles: {
        // fillColor: "#FDB913",
        halign: "center",
        textColor: "black",
      },
      bodyStyles: { fillColor: "#FFFFFF", textColor: "black", fontSize: 8 },
      columnStyles: {
        0: {
          halign: "center",
        },
        1: {
          // halign: "right",
          cellWidth: 120,
        },
        2: {
          // halign: "right",
          cellWidth: 120,
        },
      },
    });
  }

  if (
    project?.projectInfo?.proposal?.addOptionMedia &&
    optionsMediaList.length > 0
  ) {
    proposalDoc.addPage();
    contractEndPosition = pageTopMargin;
    proposalDoc.setFont("LatoRegular", "bold");
    proposalDoc.setFontSize(12);
    proposalDoc.text(
      "ADDENDUM - ALL PICTURES",
      horizontalMargin,
      contractEndPosition
    );
    contractEndPosition += 5;
    drawDivider(proposalDoc, contractEndPosition, horizontalMargin, 565);

    for (const item of optionsMediaList) {
      let imageMarginX = horizontalMargin;
      let imageWidth = 0;
      let imageHeight = 0;
      let imagesAddedPerRow = 0;
      let maxRowHeight = 0;
      let maxlinesAddedByCaptionInsertion = 0;

      contractEndPosition += 30;

      if (contractEndPosition + 300 > pageLimit) {
        proposalDoc.addPage();
        contractEndPosition = pageTopMargin;
      }

      proposalDoc.setFont("LatoRegular", "bold");
      proposalDoc.setFontSize(12);
      proposalDoc.text(item.title, horizontalMargin, contractEndPosition);
      contractEndPosition += 5;

      await Promise.all(
        item.data.map(async (file: any) => {
          let optionImage = new Image();
          optionImage.src = file?.thumbUrl;
          await new Promise((resolve, reject) => {
            optionImage.onload = resolve;
            optionImage.onerror = reject;
          });
          let orgImgHeight = optionImage.height;
          if (project.projectInfo.proposal.optionImagesPerRow === 2) {
            imageWidth = 250;
            imageHeight = (imageWidth / optionImage.width) * orgImgHeight;
            if (imagesAddedPerRow === 2) {
              if (contractEndPosition + maxRowHeight > pageLimit) {
                proposalDoc.addPage();
                contractEndPosition = pageTopMargin;
              } else {
                contractEndPosition +=
                  maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 15;
              }
              imageMarginX = horizontalMargin;
              maxRowHeight = 0;
              imagesAddedPerRow = 0;
            }
            if (contractEndPosition + imageHeight > pageLimit) {
              proposalDoc.addPage();
              contractEndPosition = pageTopMargin;
            }
            let imageWidthWithOrientation = imageWidth;
            let imageHeightWithOrientation = imageHeight;
            let rotation = 0;
            let imageYPos = contractEndPosition + 5;
            let imageXPos = imageMarginX;
            if (file.orientation === 6) {
              rotation = 270;
              imageWidthWithOrientation = imageHeight;
              imageHeightWithOrientation = imageWidth;
              imageYPos -= imageHeightWithOrientation;
            } else if (file.orientation === 3) {
              rotation = 180;
              imageYPos -= imageHeight;
              imageXPos += imageWidth;
            }
            if (file.name.match(/\.(png|jpg|jpeg)$/i)) {
              let imageType = file.name.split(".").pop();
              proposalDoc.addImage(
                optionImage,
                imageType,
                imageXPos,
                imageYPos,
                imageWidthWithOrientation,
                imageHeightWithOrientation,
                undefined,
                undefined,
                rotation
              );
              if (
                maxRowHeight <
                (imageWidth / optionImage.width) * orgImgHeight
              ) {
                maxRowHeight = (imageWidth / optionImage.width) * orgImgHeight;
              }
            }
            contractEndPosition += imageHeight + 12;

            let captionTextArray = proposalDoc.splitTextToSize(
              file.caption ?? "",
              imageWidth
            );
            let newMarginYByCaption = 0;
            if (maxlinesAddedByCaptionInsertion < captionTextArray.length) {
              maxlinesAddedByCaptionInsertion = captionTextArray.length;
            }
            captionTextArray.map((captionText: string) => {
              let textWidth = proposalDoc.getStringUnitWidth(captionText) * 10;
              let textX = imageMarginX + (imageWidth - textWidth) / 2;
              proposalDoc.setFont("LatoRegular", "normal");
              proposalDoc.setFontSize(10);
              proposalDoc.text(captionText, textX, contractEndPosition + 5);
              contractEndPosition += 12;
              newMarginYByCaption += 12;
            });
            contractEndPosition -= imageHeight + newMarginYByCaption + 12;
            imageMarginX += imageWidth + 10;
          } else if (project.projectInfo.proposal.optionImagesPerRow === 3) {
            imageWidth = 175;
            imageHeight = (imageWidth / optionImage.width) * orgImgHeight;
            if (imagesAddedPerRow === 3) {
              if (contractEndPosition + maxRowHeight > pageLimit) {
                proposalDoc.addPage();
                contractEndPosition = pageTopMargin;
              } else {
                contractEndPosition +=
                  maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 15;
              }
              imageMarginX = horizontalMargin;
              maxRowHeight = 0;
              imagesAddedPerRow = 0;
            }
            if (contractEndPosition + imageHeight > pageLimit) {
              proposalDoc.addPage();
              contractEndPosition = pageTopMargin;
            }
            let imageWidthWithOrientation = imageWidth;
            let imageHeightWithOrientation = imageHeight;
            let rotation = 0;
            let imageYPos = contractEndPosition + 5;
            let imageXPos = imageMarginX;
            if (file.orientation === 6) {
              rotation = 270;
              imageWidthWithOrientation = imageHeight;
              imageHeightWithOrientation = imageWidth;
              imageYPos -= imageHeightWithOrientation;
            } else if (file.orientation === 3) {
              rotation = 180;
              imageYPos -= imageHeight;
              imageXPos += imageWidth;
            }
            if (file.name.match(/\.(png|jpg|jpeg)$/i)) {
              let imageType = file.name.split(".").pop();
              proposalDoc.addImage(
                optionImage,
                imageType,
                imageXPos,
                imageYPos,
                imageWidthWithOrientation,
                imageHeightWithOrientation,
                undefined,
                undefined,
                rotation
              );
              if (
                maxRowHeight <
                (imageWidth / optionImage.width) * orgImgHeight
              ) {
                maxRowHeight = (imageWidth / optionImage.width) * orgImgHeight;
              }
            }
            contractEndPosition += imageHeight + 12;
            let captionTextArray = proposalDoc.splitTextToSize(
              file.caption ?? "",
              imageWidth
            );
            let newMarginYByCaption = 0;
            if (maxlinesAddedByCaptionInsertion < captionTextArray.length) {
              maxlinesAddedByCaptionInsertion = captionTextArray.length;
            }
            captionTextArray.map((captionText: string) => {
              let textWidth = proposalDoc.getStringUnitWidth(captionText) * 10;
              let textX = imageMarginX + (imageWidth - textWidth) / 2;
              proposalDoc.setFont("LatoRegular", "normal");
              proposalDoc.setFontSize(10);
              proposalDoc.text(captionText, textX, contractEndPosition + 5);
              contractEndPosition += 12;
              newMarginYByCaption += 12;
            });
            contractEndPosition -= imageHeight + newMarginYByCaption + 12;
            imageMarginX += imageWidth + 10;
          } else {
            if (project.projectInfo.proposal.optionImagesPerRow === 4) {
              imageWidth = 125;
              imageHeight = (imageWidth / optionImage.width) * orgImgHeight;
              if (imagesAddedPerRow === 4) {
                if (contractEndPosition + maxRowHeight > pageLimit) {
                  proposalDoc.addPage();
                  contractEndPosition = pageTopMargin;
                } else {
                  contractEndPosition +=
                    maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 15;
                }
                imageMarginX = horizontalMargin;
                maxRowHeight = 0;
                imagesAddedPerRow = 0;
              }
              if (contractEndPosition + imageHeight > pageLimit) {
                proposalDoc.addPage();
                contractEndPosition = pageTopMargin;
              }
              let imageWidthWithOrientation = imageWidth;
              let imageHeightWithOrientation = imageHeight;
              let rotation = 0;
              let imageYPos = contractEndPosition + 5;
              let imageXPos = imageMarginX;
              if (file.orientation === 6) {
                rotation = 270;
                imageWidthWithOrientation = imageHeight;
                imageHeightWithOrientation = imageWidth;
                imageYPos -= imageHeightWithOrientation;
              } else if (file.orientation === 3) {
                rotation = 180;
                imageYPos -= imageHeight;
                imageXPos += imageWidth;
              }
              if (file.name.match(/\.(png|jpg|jpeg)$/i)) {
                let imageType = file.name.split(".").pop();
                proposalDoc.addImage(
                  optionImage,
                  imageType,
                  imageXPos,
                  imageYPos,
                  imageWidthWithOrientation,
                  imageHeightWithOrientation,
                  undefined,
                  undefined,
                  rotation
                );
                if (
                  maxRowHeight <
                  (imageWidth / optionImage.width) * orgImgHeight
                ) {
                  maxRowHeight =
                    (imageWidth / optionImage.width) * orgImgHeight;
                }
              }
              contractEndPosition += imageHeight + 12;
              let captionTextArray = proposalDoc.splitTextToSize(
                file.caption ?? "",
                imageWidth
              );
              let newMarginYByCaption = 0;
              if (maxlinesAddedByCaptionInsertion < captionTextArray.length) {
                maxlinesAddedByCaptionInsertion = captionTextArray.length;
              }
              captionTextArray.map((captionText: string) => {
                let textWidth =
                  proposalDoc.getStringUnitWidth(captionText) * 10;
                let textX = imageMarginX + (imageWidth - textWidth) / 2;
                proposalDoc.setFont("LatoRegular", "normal");
                proposalDoc.setFontSize(10);
                proposalDoc.text(captionText, textX, contractEndPosition + 5);
                contractEndPosition += 12;
                newMarginYByCaption += 12;
              });
              contractEndPosition -= imageHeight + newMarginYByCaption + 12;
              imageMarginX += imageWidth + 10;
            }
          }
          imagesAddedPerRow++;
        })
      );
      if (project.projectInfo.proposal.optionImagesPerRow === 3) {
        if (
          item.data.length > 0 &&
          contractEndPosition + maxRowHeight > pageLimit
        ) {
          proposalDoc.addPage();
          contractEndPosition = pageTopMargin;
        } else contractEndPosition += maxRowHeight;
      } else if (project.projectInfo.proposal.optionImagesPerRow === 4) {
        if (
          item.data.length > 0 &&
          contractEndPosition + maxRowHeight > pageLimit
        ) {
          proposalDoc.addPage();
          contractEndPosition = pageTopMargin;
        } else contractEndPosition += maxRowHeight;
      } else {
        if (
          project.projectInfo.proposal.optionImagesPerRow === 2 &&
          item.data.length > 0 &&
          contractEndPosition + maxRowHeight > pageLimit
        ) {
          proposalDoc.addPage();
          contractEndPosition = pageTopMargin;
        } else {
          contractEndPosition +=
            maxRowHeight + maxlinesAddedByCaptionInsertion * 10 + 25;
        }
      }
    }
  }

  // New Page for terms and conditions
  proposalDoc.addPage();
  contractEndPosition = pageTopMargin;

  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.setFontSize(12);
  proposalDoc.text(
    "DEFINITIONS & CONDITIONS OF THIS CONTRACT",
    horizontalMargin,
    contractEndPosition
  );
  contractEndPosition += 5;
  drawDivider(proposalDoc, contractEndPosition, horizontalMargin, 565);
  contractEndPosition += 20;
  proposalDoc.setFont("LatoRegular", "normal");
  proposalDoc.setFontSize(8);
  defAndConditionsLines.forEach((line: string) => {
    let splitText = proposalDoc.splitTextToSize(line, 530);
    proposalDoc.text(splitText, horizontalMargin, contractEndPosition);
    contractEndPosition += splitText.length * 10;
  });
  proposalDoc.setDrawColor("#000000");
  proposalDoc.setLineWidth(1);
  proposalDoc.line(30, contractEndPosition, 565, contractEndPosition);
  contractEndPosition += 10;
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text("ATTENTION CLIENT:", horizontalMargin, contractEndPosition);
  contractEndPosition += 10;
  proposalDoc.setFont("LatoRegular", "normal");
  {
    let splitText = proposalDoc.splitTextToSize(
      defAndConditionsAttentionClient,
      530
    );
    proposalDoc.text(splitText, horizontalMargin, contractEndPosition);
    contractEndPosition += splitText.length * 10;
  }

  contractEndPosition += 10;
  proposalDoc.setFont("LatoRegular", "bold");
  proposalDoc.text(
    "NOTICE OF CANCELLATION:",
    horizontalMargin,
    contractEndPosition
  );
  contractEndPosition += 10;
  proposalDoc.setFont("LatoRegular", "normal");
  {
    let splitText = proposalDoc.splitTextToSize(
      defAndConditionsCancelNotice,
      530
    );
    proposalDoc.text(splitText, horizontalMargin, contractEndPosition);
    contractEndPosition += splitText.length * 10;
  }
  proposalDoc.text(defAndConditionsData, horizontalMargin, contractEndPosition);

  contractEndPosition += 65;
  proposalDoc.line(30, contractEndPosition, 565, contractEndPosition);
  contractEndPosition += 10;
  proposalDoc.text(
    "(Buyers's Signature)",
    horizontalMargin,
    contractEndPosition
  );
  proposalDoc.text("(Date)", horizontalMargin + 380, contractEndPosition);

  contractEndPosition += 30;

  if (project?.projectInfo?.proposal?.addWarranty === true) {
    proposalDoc.setFontSize(8);
    proposalDoc.setFont("LatoRegular", "bold");
    const twoYearWarrantyTextCm = 297;
    proposalDoc.text(
      "LIMITED TWO YEAR WARRANTY",
      twoYearWarrantyTextCm,
      contractEndPosition,
      {
        align: "center",
      }
    );
    contractEndPosition += 20;

    proposalDoc.setFontSize(6);
    proposalDoc.setFont("LatoRegular", "normal");
    proposalDoc.text(warrantyTerms, horizontalMargin, contractEndPosition);
    contractEndPosition += 20;
  }

  const fileNameForPdf = (jobName: string) => {
    if (jobName.includes(".")) {
      return jobName + ".pdf";
    } else return jobName + ".pdf";
  };

  if (type === "save") {
    proposalDoc.save(
      `Proposal - ${fileNameForPdf(project?.projectInfo?.jobName)}`
    );
    let blob = proposalDoc.output();
    return blob;
  }

  if (type === "email") {
    let blob = proposalDoc.output();
    return blob;
  }
};

const defAndConditionsLines = [
  "RELATIONSHIP — The individual giving you this proposal is an independent contractor licensed by CertaPro Painters® to use its systems and trademarks to operate a painting franchise. The work will be completed by the independent franchised contractor. Please make any check payable to the franchise shown on the front of this proposal.",
  "COLORS — Colors may be chosen by the client prior to commencement of work. If, after the job starts, a color change is required, the independent Contractor will have to charge for time and material expenses incurred on the original color.",
  "UNFORESEEN CONDITIONS — Should conditions arise which could not be determined by visual inspection prior to starting work, the client must pay an agreed upon extra for the completion of such work.",
  "PROPOSAL — This proposal is valid for 60 days after it was written. In addition, the Independent Franchised Contractor should be informed of your desire to have the work done and receive a signed copy of the proposal before work is to be started.",
];

const defAndConditionsAttentionClient =
  "YOU, THE BUYER, MAY CANCEL THIS TRANSACTION AT ANY TIME PRIOR TO MIDNIGHT OF THE THIRD BUSINESS DAY AFTER THE DATE OF THIS TRANSACTION. SEE THE BELOW NOTICE OF CANCELLATION FOR AN EXPLANATION OF THIS RIGHT. (SATURDAY IS A LEGAL BUSINESS DAY IN CONNECTICUT.) THIS SALE IS SUBJECT TO THE PROVISIONS OF THE HOME SOLICITATION SALES ACT AND THE HOME IMPROVEMENT ACT. THIS INSTRUMENT IS NOT NEGOTIABLE.";
const defAndConditionsCancelNotice =
  "YOU MAY CANCEL THIS TRANSACTION, WITHOUT ANY PENALTY OR OBLIGATION, WITHIN THREE BUSINESS DAYS FROM THE ABOVE DATE. IF YOU CANCEL, ANY PROPERTY TRADED IN, ANY PAYMENTS MADE BY YOU UNDER THE CONTRACT OR SALE, AND ANY NEGOTIABLE INSTRUMENT EXECUTED BY YOU WILL BE RETURNED WITHIN TEN BUSINESS DAYS FOLLOWING RECEIPT BY THE SELLER OF YOUR CANCELLATION NOTICE, AND ANY SECURITY INTEREST ARISING OUT OF THE TRANSACTION WILL BE CANCELLED. IF YOU CANCEL, YOU MUST MAKE AVAILABLE TO THE SELLER AT YOUR RESIDENCE IN SUBSTANTIALLY AS GOOD CONDITION AS WHEN RECEIVED, ANY GOODS DELIVERED TO YOU UNDER THIS CONTRACT OR SALE; OR YOU MAY, IF YOU WISH, COMPLY WITH THE INSTRUCTIONS OF THE SELLER REGARDING THE RETURN SHIPMENT OF THE GOODS AT THE SELLER'S EXPENSE AND RISK. IF YOU DO MAKE THE GOODS AVAILABLE TO THE SELLER AND THE SELLER DOES NOT PICK THEM UP WITHIN TWENTY DAYS OF THE DATE OF CANCELLATION, YOU MAY RETAIN OR DISPOSE OF THE GOODS WITHOUT ANY FURTHER OBLIGATION. IF YOU FAIL TO MAKE THE GOODS AVAILABLE TO THE SELLER, OR IF YOU AGREED TO RETURN THE GOODS AND FAIL TO DO SO, THEN YOU REMAIN LIABLE FOR PERFORMANCE OF ALL OBLIGATIONS UNDER THE CONTRACT. TO CANCEL THIS TRANSACTION, MAIL OR DELIVER A SIGNED AND DATED COPY OF THIS CANCELLATION NOTICE OR ANY OTHER WRITTEN NOTICE, OR SEND A TELEGRAM TO:";
const defAndConditionsData =
  "Name of Seller __________________\n\nDATE OF TRANSACTION ______________________\nNOT LATER THAN MIDNIGHT OF ______________________\n\nI HEREBY CANCEL THIS TRANSACTION";

const warrantyTerms = `Subject to the limitation set forth below, for a period of 24 months from the date of completion of the work described on the front of this contract, the Independent Franchise Owner named on the\n front of this contract (the "Contractor") will repair peeling, blistering or chipping paint resulting from defective workmanship.

THIS LIMITED WARRANTY DOES NOT COVER:
•               Any work where the Contractor did not supply the paint or other materials.
•               Any work which was not performed by the Contractor.
•               Varnished surfaces.
•               Surfaces made of, or containing, galvanized metal.
•               The cost of paint required to perform the repairs.
•               Repairs to horizontal surfaces or any surface that, by virtue of its design permits moisture to collect. Surfaces include, but are not limited to, decks, railings, stairs, porches, roofs and wood gutters.
•               Exact paint match as environmental conditions will affect the color and finish of all paints over time.
•               Any repairs which are necessitated as a result of a defect in the paint regardless of whether the paint was supplied by the Contractor or the customer.
•               Bleeding caused by knots, rust or cedar.
•               Cracks in drywall, plaster or wood.
•               Peeling, blistering or chipping where they are caused by:
              o               mill-glazing from smooth cedar.
              o               ordinary wear and tear.
              o               abnormal use or misuse.
              o               peeling of layers of paint existing prior to the work performed by the Contractor.
              o               structural defects.
              o               settling or movement.
              o               moisture content of the substrate.
              o               abrasion, mechanical damage, abrasive cleaning, abuse or damage resulting from use of chemicals or cleaning agents or exposure to harmful solids, liquids or gases.
              o               damage or defects caused in whole or in part by reason of fire, explosion, flood, acts of God, extreme weather conditions, misuse, alteration, abuse, vandalism, negligence, or any other\n similar causes beyond the control of the Contractor.

Repairs under this limited warranty will be performed only on the specific areas where peeling, blistering or chipping has occurred and only to the level of surface preparation described in the preparation\n section of the Contract.

FOR THIS WARRANTY TO BE VALID, YOU MUST:

•               Pay the full contract price.
•               Retain a copy of the original contract.
•               Retain a copy of your cancelled check or other evidence of payment in full.
•               Pay for all materials used to perform the repairs.
•               Make the property accessible to the Contractor, or his employees, to perform the repairs.

THIS LIMITED WARRANTY IS THE ONLY EXPRESS WARRANTY MADE BY THE CONTRACTOR AND IS IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR IMPLIED. THIS WARRANTY 
COVERS ONLY THOSE SERVICES PROVIDED BY THE CONTRACTOR TO THE ORIGINAL PURCHASER NAMED ON THE FRONT OF THIS CONTRACT. IN NO EVENT SHALL THE 
CONTRACTOR BE LIABLE FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES IN EXCESS OF THE ORIGINAL CONTRACT PRICE. THIS WARRANTY MAY NOT BE ALTERED OR EXTENDED 
FOR ANY PURPOSE UNLESS DONE SO IN WRITING IN A DOCUMENT EXECUTED BY ALL PARTIES TO THIS CONTRACT.

This warranty gives you specific legal rights. Some jurisdictions do not allow limitations on how long an implied warranty lasts, so the above limitation may not apply to you. Some jurisdictions do not 
allow the exclusion or limitation of incidental or consequential damages, so the above limitations or exclusions may not apply to you.

For warranty service, you should contact your Contractor to schedule an inspection of your property by calling CertaPro Painters® at 800.462.3782.`;
