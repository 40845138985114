import React, { PropsWithChildren, useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Button,
  Input,
  Menu,
  Dropdown,
  message,
  Modal,
  Spin,
  Card,
} from "antd";

import { MoreActionIcon } from "../../utils/icons";
import { SearchOutlined, DashOutlined, HddOutlined } from "@ant-design/icons";
import EmptyScreen from "../../containers/available-jobs/EmptyScreen";
import api from "../../utils/api";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setAdminDefaults,
  setCurrentProject,
  setProjectDataContractor,
} from "../../redux/project/action";
import { getAuthUser } from "../../utils/authToken";
import axios from "axios";
import { pricingCalculation } from "../../utils/priceCalculation";
import JobPostingCard from "../job-posting-card/JobPostingCard";
import { useMediaQuery } from "react-responsive";

const { Content } = Layout;

const JobPostingBoardC: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:850px)" });
  const isMobile = useMediaQuery({ query: "(min-width:440px)" });

  const [userSearched, setUserSearched] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<any>([]);
  const [showEmpty, setShowEmpty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tableRow, setTableRow] = useState();
  const [projectIndex, setProjectIndex] = useState(-1);
  const history = useHistory();
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const { items } = useSelector((state: RootStateOrAny) => state.offlineData);
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const [page, setPage] = useState<number>(1);
  const [availableJobsCount, setAvailableJobsCount] = useState<number>(0);

  useEffect(() => {
    dispatch(setCurrentProject(-1));
    setLoading(true);
    if (online) {
      api
        .get(`pre-presentation-checklist/available-jobs/${page}`)
        .then((resp: any) => {
          setProjects(resp.availableJobs);
          setAvailableJobsCount(resp.availableJobsCount);
        });
      api.get("projects/all-defaults").then((resp: any) => {
        dispatch(setAdminDefaults(resp));
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [online, page]);

  const handleSendMail = (data: any) => {
    const project = items.find(
      (item: any) => item.projectId === data.project._id
    );
    const body = {
      userId: user._id,
      userName: user.fullName,
      email: user.email,
      projectId: data.project._id,
      projectName: data.project.jobName,
      productionAssociate: project.projectInfo.productionAssociate,
    };
    api
      .post("pre-presentation-checklist/send-message", body)
      .then((response: any) => {
        message.success("Email Sent");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleOk = () => {
    handleSendMail(tableRow);
    setShowModal(false);
  };

  const handleModal = (data: any) => {
    let index = projects.findIndex(
      (item: any) => item.project._id === data.project._id
    );
    setProjectIndex(index);
    setTableRow(data);
    setShowModal(true);
  };

  const handleMyJobs = () => {
    history.push("/contractor-my-jobs");
  };

  let segments: any = [];
  Object.keys(projects).length &&
    projects?.forEach((item: any) => {
      if (item?.project?.segment) {
        segments.push(item?.project?.segment);
      }
    });

  let uniqueSegmentArray = [
    ...new Map(segments.map((item: any) => [item["_id"], item])).values(),
  ];

  const segmentFilter = uniqueSegmentArray.map((item: any) => {
    return {
      text: item.segmentName,
      value: item._id,
    };
  });

  const handleProjectSelect = async (project: any) => {
    await api.get(`projects/${project._id}`).then((resp: any) => {
      dispatch(setProjectDataContractor(resp));
      history.push(`/contractor-media/${project._id}`);
    });
  };

  const columns = [
    {
      title: "Project Name",
      sorter: (a: any, b: any) =>
        a.project.jobName.localeCompare(b.project.jobName),
      render: (data: any) => {
        return (
          <div
            onClick={() => handleProjectSelect(data?.project)}
            style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
          >
            <div style={{ paddingTop: "7px" }}>
              <img
                src={`data:image/svg+xml;base64,${Buffer.from(
                  data?.project?.segment?.segmentIcon
                ).toString("base64")}`}
                alt="project"
                width="39"
                height="39"
              />
            </div>
            <div style={{ paddingLeft: "15px" }}>
              <p
                style={{
                  width: "150px",
                  margin: "0",
                  color: " #1F1F1F",
                  fontSize: "16px",
                  fontStyle: "normal",
                }}
              >
                {data?.project?.jobName}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  color: "rgba(51, 51, 51, 0.7)",
                }}
              >
                {`${data?.project?.city}, ${data?.project?.zip}`}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Skills",

      render: (data: any) => {
        return (
          <div>
            {data?.listOfSkills?.map((item: any) => {
              return (
                <p
                  style={{
                    fontSize: "16px",
                    color: " #1F1F1F",
                    margin: "0",
                    padding: "0",
                    width: "max-content",
                  }}
                >
                  {item?.title}
                </p>
              );
            })}
          </div>
        );
      },
    },
    {
      title: "Labor Price",

      render: (data: any) => {
        return (
          <div>
            <p
              style={{
                fontSize: "16px",
                color: " #1F1F1F",
                margin: "0",
              }}
            >
              {/* {data} */}
            </p>
          </div>
        );
      },
    },
    {
      title: "Segment",
      sorter: (a: any, b: any) =>
        a?.project?.segment?.segmentName.localeCompare(
          b?.project?.segment?.segmentName
        ),
      render: (data: any) => {
        return (
          <p style={{ fontSize: "16px" }}>
            {data?.project?.segment?.segmentName}
          </p>
        );
      },

      filters: segmentFilter,
      onFilter: (value: any, record: any) => {
        return record?.project?.segment?.segmentName.indexOf(value) === 0;
      },
    },
    {
      title: "Start date",

      render: (data: any) => {
        return (
          <div>
            <p>
              {data?.projectStartDate &&
                moment(data.projectStartDate).format("MMM DD, YYYY")}
            </p>
          </div>
        );
      },
    },
    {
      title: "Completion Date",
      render: (data: any) => {
        return (
          <div>
            <p>
              {data?.jobCompleteDate &&
                moment(data.jobCompleteDate).format("MMM DD, YYYY")}
            </p>
          </div>
        );
      },
    },

    {
      title: "Action",
      render: (data: any) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => handleModal(data)}
                    style={{
                      fontWeight: 700,
                      fontSize: "15px",
                      lineHeight: "18px",
                      color: "#333333",
                    }}
                  >
                    <span className="nav-text">Apply For Job</span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <button className="action-button">
                <MoreActionIcon />
              </button>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const found = projects.filter((item: any) => {
    if (!userSearched) {
      return true;
    }
    return item.project.jobName
      .toLowerCase()
      .includes(userSearched.toLowerCase());
  });

  return (
    <>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <Modal
            title="Apply for job"
            centered
            visible={showModal}
            okText="Yes"
            cancelText="No"
            onOk={handleOk}
            onCancel={() => setShowModal(false)}
            width={"40%"}
          >
            <div>
              <h3>
                Please confirm that you'll be able to complete the project
                during the stated timeframe before submitting the application:
              </h3>
              {projects[projectIndex]?.startDateFirmOrFlexible === "firm" ? (
                <h3>
                  Subcontractor shall start the Project on:{" "}
                  {projects[projectIndex]?.projectStartDate && (
                    <>
                      {moment(projects[projectIndex]?.projectStartDate).format(
                        "MM-DD-YYYY"
                      )}{" "}
                      ?
                    </>
                  )}
                </h3>
              ) : (
                <h3>
                  Subcontractor shall start the Project within 3 days of:{" "}
                  {projects[projectIndex]?.projectStartDate && (
                    <>
                      {moment(projects[projectIndex]?.projectStartDate).format(
                        "MM-DD-YYYY"
                      )}{" "}
                      ?
                    </>
                  )}
                </h3>
              )}
            </div>
            <div style={{ border: "10px solid black" }}>
              {projects[projectIndex]?.completionDateFirmOrFlexible ===
                "firm" ? (
                <h3>
                  Subcontractor shall complete the entire Project by no later
                  than:{" "}
                  {projects[projectIndex]?.jobCompleteDate && (
                    <>
                      {moment(projects[projectIndex]?.jobCompleteDate).format(
                        "MM-DD-YYYY"
                      )}{" "}
                      ?
                    </>
                  )}
                </h3>
              ) : (
                <h3>
                  Subcontractor shall complete the entire Project within 7 days
                  of:{" "}
                  {projects[projectIndex]?.jobCompleteDate && (
                    <>
                      {moment(projects[projectIndex]?.jobCompleteDate).format(
                        "MM-DD-YYYY"
                      )}{" "}
                      ?
                    </>
                  )}
                </h3>
              )}
            </div>
          </Modal>
          {showEmpty ? (
            <EmptyScreen fetchAllJobs="" showButton={false} />
          ) : (
            <div>
              {!isTabletOrMobile ? (
                <Content style={{ margin: "24px 0px 0" }}>
                  <Row justify="space-between">
                    <Col>
                      <h1
                        style={{
                          color: "#1F1F1F",
                          fontSize: "24px",
                          fontWeight: "bold",
                          paddingLeft: "20px",
                        }}
                      >
                        Job Posting Board
                      </h1>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        onClick={handleMyJobs}
                        style={{
                          marginRight: "30px",
                          height: "35px",
                          borderRadius: "5px",
                          color: "#1F1F1F",
                          fontWeight: "bold",
                          fontSize: "15px",
                          lineHeight: "18px",
                        }}
                      >
                        My Jobs
                      </Button>
                    </Col>
                  </Row>
                  <hr style={{ border: "1px solid #E8E8E8", width: "100%" }} />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "15px 0px 0px 20px",
                    }}
                  >
                    <Input
                      prefix={<SearchOutlined />}
                      style={{ width: "332px", height: "40px" }}
                      placeholder="Search jobs"
                      onChange={(e) => {
                        setUserSearched(e.target.value);
                      }}
                    />
                  </div>

                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <Table
                      scroll={{ x: "1200" }}
                      rowKey="_id"
                      rowClassName="table_row"
                      columns={columns}
                      dataSource={found}
                      pagination={{
                        pageSize: 10,
                      }}
                    />
                  </div>
                </Content>
              ) : (
                <>
                  <h1
                    style={{
                      color: "#1F1F1F",
                      fontSize: "18px",
                      fontWeight: "bold",
                      paddingLeft: "20px",
                      marginTop: "20px",
                      marginLeft: "-15px",
                    }}
                  >
                    Job Posting Board
                  </h1>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Input
                      prefix={<SearchOutlined />}
                      style={{ width: "355px", height: "40px" }}
                      placeholder="Search jobs"
                      onChange={(e) => {
                        setUserSearched(e.target.value);
                      }}
                    />
                  </div>
                  {isMobile ? (
                    <Row>
                      {found.map((item: any) => {
                        return (
                          <>
                            <JobPostingCard item={item} />
                          </>
                        );
                      })}
                    </Row>
                  ) : (
                    <>
                      {found.map((item: any) => {
                        return (
                          <>
                            <JobPostingCard item={item} />
                          </>
                        );
                      })}
                    </>
                  )}
                  {!isMobile ? (
                    <Button
                      type="primary"
                      onClick={handleMyJobs}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        height: "35px",
                        borderRadius: "5px",
                        color: "#1F1F1F",
                        fontWeight: "bold",
                        fontSize: "15px",
                        lineHeight: "18px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      My Jobs
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={handleMyJobs}
                      style={{
                        width: "30%",
                        marginLeft: "35%",
                        textAlign: "center",
                        height: "35px",
                        borderRadius: "5px",
                        color: "#1F1F1F",
                        fontWeight: "bold",
                        fontSize: "15px",
                        lineHeight: "18px",
                        marginTop: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      My Jobs
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default JobPostingBoardC;
