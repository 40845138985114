import { Row, Col, Checkbox } from "antd";
import { useSelector, RootStateOrAny } from "react-redux";
import "./ProposalSetup.less";

type Props = {
  checkedSetup: any;
  handleSetupChange: any;
};

const SetupCheckboxes = (props: Props) => {
  const { setup } = useSelector(
    (state: RootStateOrAny) => state.proposalDefaults
  );

  const checkValue = (item: any) => {
    const found = props.checkedSetup?.find((obj: any) => {
      return obj._id === item._id;
    });

    return found ? true : false;
  };

  return (
    <div className="p-setup-container">
      <h1>Setup</h1>

      <div className="p-setup-sub">
        <div className="setup-ch-title">
          <Row gutter={4}>
            <Col span={8}>
              <h2>Customer to:</h2>
            </Col>
            <Col span={8}>
              <h2>CertaPro will cover and protect:</h2>
            </Col>
            <Col span={8}>
              <h2>CertaPro will:</h2>
            </Col>
          </Row>
        </div>
        <Row gutter={4}>
          <Col span={8}>
            {setup
              .filter((obj: any) => obj.title === "customer tasks")
              .map((item: any) => {
                return (
                  <Row key={item._id}>
                    <Checkbox
                      checked={checkValue(item)}
                      onChange={(e) => props.handleSetupChange(e, item)}
                    >
                      <p>{item.task}</p>
                    </Checkbox>
                  </Row>
                );
              })}
          </Col>
          <Col span={8}>
            {setup
              .filter(
                (obj: any) =>
                  Object.keys("category") &&
                  obj.category === "Cover and protect"
              )
              .map((item: any) => {
                return (
                  <Row key={item._id}>
                    <Checkbox
                      checked={checkValue(item)}
                      onChange={(e) => props.handleSetupChange(e, item)}
                    >
                      <p>{item.task}</p>
                    </Checkbox>
                  </Row>
                );
              })}
          </Col>
          <Col span={8}>
            {setup
              .filter(
                (obj: any) =>
                  Object.keys("category") && obj.category === "will do"
              )
              .map((item: any) => {
                return (
                  <Row key={item._id}>
                    <Checkbox
                      checked={checkValue(item)}
                      onChange={(e) => props.handleSetupChange(e, item)}
                    >
                      <p>{item.task}</p>
                    </Checkbox>
                  </Row>
                );
              })}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SetupCheckboxes;
