import { Row, Col, Checkbox } from "antd";
import "./ProposalSetup.less";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {
  checkedTermsAndConditions: any;
  handleTermsChange: any;
  termsAndConditionsArray: any;
};

const TermsAndConditionsCheckboxes = (props: Props) => {
  const checkValue = (item: any) => {
    const found = props.checkedTermsAndConditions?.find(
      (obj: any) => obj._id === item._id
    );
    return found ? true : false;
  };
  return (
    <div className="surface-ch-container">
      <h1>Terms and Conditions</h1>
      <div className="s-ch-sub">
        <Row>
          {props.termsAndConditionsArray?.map((item: any) => {
            return (
              <Col span={23} key={item._id}>
                <Checkbox
                  checked={checkValue(item)}
                  onChange={(e) => props.handleTermsChange(e, item)}
                >
                  <p>
                    <strong>{item.title}:</strong>
                    {item.detail}
                  </p>
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

export default TermsAndConditionsCheckboxes;
