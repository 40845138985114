import React, { useState, useEffect } from "react";
import { columns } from "./column";
import {
  Layout,
  Table,
  Button,
  Input,
  Avatar,
  Row,
  Spin,
  Dropdown,
  Menu,
  message,
  Col,
  Select,
  AutoComplete,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import EmptyScreen from "./EmptyScreen";
import { Link, useHistory } from "react-router-dom";
import {
  getProjects,
  setCurrentProject,
  getAdminDefaults,
  setAdminDefaults,
  setProjectItems,
  sendToEstimator,
  setProjectAndIndex,
} from "../../redux/project/action";
import { setPjcc } from "../../redux/project-pjcc/action";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import "./AvailableJobsC.less";
import { getAssociatePeople } from "../../redux/associatePeople/action";
import { pricingCalculation } from "../../utils/priceCalculation";
import { API_BASE } from "../../constant";
import axios, { Axios } from "axios";
import { getAuthUser } from "../../utils/authToken";
import { MoreActionIcon } from "../../utils/icons";
import EmailModal from "../email-template-modal/EmailModal";
import moment from "moment";
import _ from "lodash";
import api from "../../utils/api";
import JobsBulkEntry from "../../components/pop-ups/default-modal/JobsBulkEntry";
import { transformData } from "../../utils/helpers";

const { Content } = Layout;

const AvailableJobsC: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [projects, setProjects] = useState<any>([]);
  const [fetching, setFetching] = useState(false);
  const [searchedProjects, setSearchedProjects] = useState([]);
  const history = useHistory();
  const offline = useSelector((state: RootStateOrAny) => state.offline);
  const { user, adminDefaults, items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const associatePeople = useSelector(
    (state: RootStateOrAny) => state.associatePeople
  );
  const [projectId, setProjectId] = useState<any>("");
  const [showModal, setShowModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailRecipients, setEmailRecipients] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const { online } = offline;
  const fetchAllJobs = (page: number) => {
    let endpoints = [
      `${API_BASE}projects/all-projects/page/${page}`,
      `${API_BASE}projects/all-defaults`,
    ];
    const user = getAuthUser();
    axios
      .all(
        endpoints.map((promise) =>
          axios.get(promise, {
            headers: {
              contentType: "application/json",
              authorization: user.accessToken
                ? "Bearer " + user.accessToken
                : "",
            },
          })
        )
      )
      .then(
        axios.spread((projects, defaults) => {
          setLoading(false);
          let allProjects = [];

          if (projects.data?.length > 0) {
            allProjects = projects.data;
          }
          setProjects(allProjects);
          dispatch(setAdminDefaults(defaults.data));
        })
      )
      .catch(() => {
        setLoading(false);
      });
    dispatch(getAssociatePeople());
  };

  useEffect(() => {
    dispatch(setCurrentProject(-1));
    dispatch(setPjcc([]));
    setLoading(true);
    if (online) {
      fetchAllJobs(page);
      // dispatch(getAdminDefaults());
    } else {
      setLoading(false);
    }
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, [online, page]);

  let usersList: any = [];
  let segments: any = [];
  Object.keys(projects)?.length &&
    projects?.forEach((item: any) => {
      if (item?.segment) {
        segments.push(item?.segment);
      }
    });
  Object.keys(projects)?.length &&
    projects?.forEach((item: any) => {
      if (item?.salesAssociate) {
        usersList.push(item?.salesAssociate);
      }
      if (item?.estimator) {
        usersList.push(item?.estimator);
      }
      if (item?.productionAssociate) {
        usersList.push(item?.productionAssociate);
      }
    });
  let uniqueSegmentArray = [
    ...new Map(segments.map((item: any) => [item["_id"], item])).values(),
  ];

  let uniqueObjArray = [
    ...new Map(usersList.map((item: any) => [item["_id"], item])).values(),
  ];

  const segmentFilter = uniqueSegmentArray.map((item: any) => {
    return {
      text: item.segmentName,
      value: item._id,
    };
  });

  const userFilter = uniqueObjArray.map((item: any) => {
    return {
      text: item.fullName,
      value: item._id,
    };
  });

  const onSearchInputChange = (input: string) => {
    if (input.length >= 3) {
      setFetching(true);
      api
        .get(`projects/search-projects?query=${input}`)
        .then((resp: any) => {
          setSearchedProjects(resp);
        })
        .finally(() => {
          setFetching(false);
        });
    } else if (input.length === 0) {
      setSearchedProjects([]);
    }
  };

  const handleSendToEstimator = (data: any) => {
    setProjectId(data?._id);
    setEmailSubject(`Estimate Request - ${data?.jobName}`);
    setEmailBody(
      `${data?.estimator?.fullName
      },\n \nEstimate has been scheduled for ${moment(
        data?.estimateScheduledDate
      ).format(
        "MMMM DD,YYYY"
      )}. For more information please visit https://cppindy.com/manager-preview-profile/${items[currentProject].projectInfo._id
      }. \n \nThanks, \n${user.fullName}`
    );
    setEmailRecipients(`${data?.estimator?.email}`);
    setShowModal(true);
  };

  const handleSendEmail = (body: any) => {
    dispatch(sendToEstimator(projectId, body));
    message.success("Email Sent");
    setShowModal(false);
  };

  const handleNext = (data: any) => {
    const currentProject = projects.find(
      (item: any) => item.projectId === data._id
    );
    let project = _.cloneDeep(currentProject);
    let newProject = pricingCalculation(project, adminDefaults);
    dispatch(setProjectAndIndex([newProject]));
    history.push(`/manager-preview-profile/${data._id}`);
  };

  const tableColumns = [
    {
      title: "Project Name",
      sorter: (a: any, b: any) => a.jobName.localeCompare(b.jobName),
      render: (data: any) => {
        return (
          <div
            onClick={() => handleNext(data)}
            style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
          >
            <div style={{ paddingTop: "7px" }}>
              <img
                src={`data:image/svg+xml;base64,${Buffer.from(
                  data?.segment?.segmentIcon
                ).toString("base64")}`}
                alt="project"
                width="39"
                height="39"
              />
            </div>

            <div style={{ paddingLeft: "15px" }}>
              <p
                style={{
                  margin: "0",
                  color: " #1F1F1F",
                  fontSize: "16px",
                  fontStyle: "normal",
                }}
              >
                {data.jobName}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  color: "rgba(51, 51, 51, 0.7)",
                }}
              >
                {data.jobNumber}
              </p>
            </div>
          </div>
        );
      },
    },
    columns[0],
    {
      title: "Segment",

      sorter: (a: any, b: any) => {
        return a.segment?.segmentName?.localeCompare(b?.segment?.segmentName);
      },
      render: (data: any) => {
        return (
          <>
            <p style={{ fontSize: "16px", margin: 0 }}>
              {data?.segment?.segmentName}
            </p>
            <p
              style={{
                color: "rgba(51, 51, 51, 0.7)",
                fontSize: "12px",
              }}
            >
              {data?.subSegment}
            </p>
          </>
        );
      },

      filters: segmentFilter,
      onFilter: (value: any, record: any) => {
        return record.segment?._id.indexOf(value) === 0;
      },
    },

    {
      title: "Users",
      filters: userFilter,
      onFilter: (value: any, record: any) => {
        return (
          record.salesAssociate?._id.indexOf(value) === 0 ||
          record.estimator?._id.indexOf(value) === 0 ||
          record.productionAssociate?._id.indexOf(value) === 0
        );
      },
      render: (data: any) => {
        const getInitials = (name: string) => {
          try {
            const nameArray = name?.split(" ");
            const firstName = nameArray[0];
            const lastName = nameArray[nameArray?.length - 1];
            if (nameArray?.length === 1) {
              const initials = firstName.charAt(0);
              return initials.toUpperCase();
            } else {
              const initials = firstName.charAt(0) + lastName.charAt(0);
              return initials.toUpperCase();
            }
          } catch (err) {
            console.log(err);
            return "";
          }
        };

        const associateInitials = getInitials(data?.salesAssociate?.fullName);
        const estimatorInitials = getInitials(data?.estimator?.fullName);
        const productionInitials = getInitials(
          data?.productionAssociate?.fullName
        );

        return (
          <div>
            <Avatar.Group>
              {data.salesAssociate && (
                <>
                  {data.salesAssociate?.picture && online ? (
                    <Avatar src={data.salesAssociate?.picture} />
                  ) : (
                    <Avatar>{associateInitials}</Avatar>
                  )}
                </>
              )}
              {data.estimator && (
                <>
                  {data.estimator?.picture && online ? (
                    <Avatar src={data.estimator?.picture} />
                  ) : (
                    <Avatar>{estimatorInitials}</Avatar>
                  )}
                </>
              )}
              {data.productionAssociate && (
                <>
                  {data.productionAssociate?.picture && online ? (
                    <Avatar src={data.productionAssociate?.picture} />
                  ) : (
                    <Avatar>{productionInitials}</Avatar>
                  )}
                </>
              )}
            </Avatar.Group>
          </div>
        );
      },
    },
    columns[2],
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item className="project-menu-items" key={1}>
                  <Link to={`/manager-preview-profile/${data._id}`}>
                    <span className="nav-text">
                      <h1>Edit Profile</h1>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item
                  className="project-menu-items"
                  onClick={() => handleSendToEstimator(data)}
                  key={2}
                >
                  <span className="nav-text">
                    <h1>Send to Estimator</h1>
                  </span>
                </Menu.Item>
                <Menu.Item className="project-menu-items" key={3}>
                  <Link to={`/manager-pjcc/${data._id}`}>
                    <span className="nav-text">
                      <h1>Review PJCC</h1>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item className="project-menu-items" key={4}>
                  <Link to={`/manager-proposal/${data._id}`}>
                    <span className="nav-text">
                      <h1>Preview Proposal</h1>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item className="project-menu-items" key={5}>
                  <Link to={`/manager-on-site-checklists/${data._id}`}>
                    <span className="nav-text">
                      <h1>Start Estimate</h1>
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item className="project-menu-items" key={6}>
                  <Link to={`/manager-preview-profile/${data._id}`}>
                    <span className="nav-text">
                      <h1>Review Estimate</h1>
                    </span>
                  </Link>
                </Menu.Item>

                <Menu.Item className="project-menu-items" key={9}>
                  <span className="nav-text">
                    <h1>Post Job</h1>
                  </span>
                </Menu.Item>
                <Menu.Item className="project-menu-items" key={10}>
                  <span className="nav-text">
                    <h1>Set Inactive</h1>
                  </span>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <Button className="action-button">
              <MoreActionIcon />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const handleCreateProfile = () => {
    dispatch(setCurrentProject(-1));
    history.push("/new-profile");
  };
  const handleProjectSelect = (id: any) => {
    history.push(`/manager-preview-profile/${id}`);
  };

  return (
    <>
      {loading && projects.length < 1 ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <div>
          {projects?.length < 1 ? (
            <EmptyScreen fetchAllJobs={fetchAllJobs} showButton={true} />
          ) : (
            <div className="content--container">
              <Content style={{ margin: "24px 0px 0" }}>
                <EmailModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  emailSubject={emailSubject}
                  emailBody={emailBody}
                  emailRecipients={emailRecipients}
                  sendEmail={handleSendEmail}
                />
                <div className="aj-new-profile">
                  <h1>My Jobs</h1>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <JobsBulkEntry style="aj-create-profile-btn" pageReload={fetchAllJobs} />
                    <Button
                      onClick={handleCreateProfile}
                      className="aj-create-profile-btn"
                      type="primary"
                    >
                      Add New Profile
                    </Button>
                  </div>
                </div>
                <hr style={{ border: "1px solid #E8E8E8", width: "100%" }} />

                <div
                  style={{
                    padding: "15px 0px 0px 20px",
                  }}
                >
                  <Row align={"middle"} gutter={5}>
                    <Col>
                      <AutoComplete
                        size="large"
                        style={{ width: "332px", height: "40px" }}
                        notFoundContent={
                          fetching ? (
                            <Spin size="small" />
                          ) : (
                            <div>No Projects Found</div>
                          )
                        }
                        onSearch={onSearchInputChange}
                        options={searchedProjects.map((d: any) => ({
                          value: d._id,
                          label: (
                            <div onClick={() => handleProjectSelect(d._id)}>
                              <div style={{ fontSize: 15 }}>{d.jobName}</div>
                              <div style={{ fontSize: 12 }}>{d.jobNumber}</div>
                            </div>
                          ),
                        }))}
                      >
                        <Input
                          prefix={<SearchOutlined />}
                          style={{ width: "332px", height: "40px" }}
                          placeholder="Search jobs"
                        />
                      </AutoComplete>
                    </Col>
                    <Col>
                      {searchQuery.length < 3 && searchQuery.length > 0 && (
                        <p>Please enter minimum 3 characters</p>
                      )}
                    </Col>
                  </Row>
                </div>

                {online ? (
                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <Table
                      scroll={{ x: 1100 }}
                      rowClassName="table_row"
                      columns={tableColumns}
                      rowKey="_id"
                      loading={{
                        indicator: (
                          <div>
                            <Spin />
                          </div>
                        ),
                        spinning: loading,
                      }}
                      dataSource={projects?.map(
                        (item: any) => item.projectInfo
                      )}
                      pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 20,
                        total: projects?.length,
                        pageSizeOptions: [20],
                        onChange: (page) => {
                          setPage(page);
                        },
                        current: page,
                      }}
                    />
                  </div>
                ) : (
                  <h1
                    style={{
                      padding: "15px 0px 0px 20px",
                    }}
                  >
                    Please make sure you have an active internet connection.
                  </h1>
                )}
              </Content>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AvailableJobsC;
