import { Row, Col, Button, Avatar, Tag, Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import "./JobCard.less";
import { MapPinIcon, MoreVerticalIcon } from "../../utils/icons";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setActiveProject,
  setProjectDataContractor,
} from "../../redux/project/action";
import moment from "moment";
import api from "../../utils/api";
import { useEffect, useState } from "react";
import { pricingCalculation } from "../../utils/priceCalculation";

type Props = {
  data: any;
};

const JobCard = (props: Props) => {
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const dispatch = useDispatch();
  const [subTotal, setSubTotal] = useState(0);
  const setProject = async (id: string) => {
    await api.get(`projects/${id}`).then((resp: any) => {
      const updatedResp = pricingCalculation(resp, adminDefaults);
      dispatch(setProjectDataContractor(updatedResp));
    });
  };

  useEffect(() => {
    let total = 0;
    props?.data.options?.map((item: any) => {
      let temp = totalLaborPrice(item.optionInfo, props?.data);
      total = total + temp;
    });

    setSubTotal(total);
  }, [props]);

  const history = useHistory();
  const menuData = [
    {
      title: "Proposal-C",
      handleClick: () => {
        setProject(props.data.projectId);
        history.push(`/contractor-proposal/${props.data.projectId}`);
      },
    },
    {
      title: "Work Order- Paint",
      handleClick: () => {
        setProject(props.data.projectId);
        history.push(`/contractor-work-order-paint/${props.data.projectId}`);
      },
    },
    {
      title: "Work order- carpentry",
      handleClick: () => {
        setProject(props.data.projectId);
        history.push(
          `/contractor-work-order-carpentry/${props.data.projectId}`
        );
      },
    },
    {
      title: "Paint Materials",
      handleClick: () => {
        setProject(props.data.projectId);
        history.push(`/contractor-paint-order/${props.data.projectId}`);
      },
    },
    {
      title: "Wood Order",
      handleClick: () => {
        setProject(props.data.projectId);
        history.push(`/contractor-wood-order/${props.data.projectId}`);
      },
    },
    {
      title: "Contract",
      handleClick: () => {
        setProject(props.data.projectId);
        history.push(`/contractor-pre-view/${props.data.projectId}`);
      },
    },
    {
      title: "Media/Documents",
      handleClick: () => {
        setProject(props.data.projectId);
        history.push(`/contractor-media/${props.data.projectId}`);
      },
    },
  ];

  const menu = (
    <Menu>
      {menuData.map((item, index) => (
        <Menu.Item className="jc-menu-item" key={index}>
          <span className="nav-text" onClick={item.handleClick}>
            {item.title}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleViewPayment = () => {
    setProject(props.data?.projectId);
    history.push(`/contractor-payment-record/${props?.data.projectId}`);
  };

  const handleTitleClick = () => {
    setProject(props.data?.projectId);
    history.push(`/contractor-media/${props.data.projectId}`);
  };

  const totalLaborPrice = (data: any, project: any) => {
    let projectPaintLabor = project?.pjcc.find(
      (item: any) => item.name === "Paint Labor"
    );
    let projectCarpentryLabor = project?.pjcc.find(
      (item: any) => item.name === "Carpentry Labor"
    );
    let singleOptionIndex = project?.options.findIndex(
      (item: any) => item.optionInfo._id === data._id
    );
    let paintLabor = project?.options[singleOptionIndex]?.pjcc.find(
      (item: any) => item.name === "Paint Labor"
    );
    let carpentryLabor = project?.options[singleOptionIndex]?.pjcc.find(
      (item: any) => item.name === "Carpentry Labor"
    );

    let paintLaborPrice =
      (paintLabor.cost / projectPaintLabor.cost) *
      projectPaintLabor.productionTargets;
    let carpentryLaborPrice =
      (carpentryLabor.cost / projectCarpentryLabor.cost) *
      projectCarpentryLabor.productionTargets;
    let totalPrice =
      (isNaN(paintLaborPrice) ? 0 : paintLaborPrice) +
      (isNaN(carpentryLaborPrice) ? 0 : carpentryLaborPrice);
    return isNaN(totalPrice) ? 0 : totalPrice;
  };

  return (
    <>
      <div className="job-card-root">
        <div className="job-card-padding">
          <Row justify="space-between">
            <Col span={20}>
              <Row
                style={{ cursor: "pointer" }}
                onClick={handleTitleClick}
                gutter={12}
                align="middle"
              >
                <Col>
                  <Avatar
                    src={`data:image/svg+xml,${encodeURIComponent(
                      props?.data?.prePresentationChecklist.project?.segment
                        ?.segmentIcon
                    )}`}
                    size={64}
                    shape={"circle"}
                    style={{
                      backgroundColor: "rgba(232, 232, 232, 1)",
                    }}
                  />
                </Col>

                <Col span={14}>
                  <h1 style={{ wordWrap: "break-word", minHeight: "50px" }}>
                    {props?.data?.projectInfo?.jobName}
                  </h1>

                  <h2>${subTotal}</h2>
                </Col>
              </Row>
            </Col>
          </Row>

          <div
            // onClick={handleTitleClick}
            // style={{ marginTop: "20px", cursor: "pointer" }}
            style={{ marginTop: "20px" }}
          >
            <Row gutter={[16, 6]}>
              <Col span={8}>
                <h1>Client:</h1>
              </Col>
              <Col span={16} style={{ minHeight: "25px" }}>
                <p>{props?.data?.projectInfo?.primaryContactName}</p>
              </Col>

              <Col span={8}>
                <h1>PA:</h1>
              </Col>
              <Col span={16} style={{ minHeight: "25px" }}>
                <p>{props?.data?.projectInfo?.productionAssociate?.fullName}</p>
              </Col>

              <Col span={8}>
                <h1>Start Date:</h1>
              </Col>
              <Col span={16} style={{ minHeight: "50px" }}>
                {props?.data?.prePresentationChecklist
                  .startDateFirmOrFlexible === "firm" ? (
                  <p>
                    {props?.data?.prePresentationChecklist.projectStartDate ? (
                      <>
                        {moment(
                          props?.data?.prePresentationChecklist.projectStartDate
                        ).format("DD, MMM, YYYY")}
                      </>
                    ) : (
                      <>__________________</>
                    )}
                  </p>
                ) : (
                  <p>
                    within 3 days of{" "}
                    {props?.data?.prePresentationChecklist.projectStartDate ? (
                      <>
                        {moment(
                          props?.data?.prePresentationChecklist.projectStartDate
                        ).format("DD, MMM, YYYY")}
                      </>
                    ) : (
                      <>__________________</>
                    )}
                  </p>
                )}
              </Col>

              <Col span={8}>
                <h1>End Date:</h1>
              </Col>
              <Col span={16} style={{ minHeight: "50px" }}>
                {props?.data?.prePresentationChecklist
                  .completionDateFirmOrFlexible === "firm" ? (
                  <p>
                    no later than{" "}
                    {props?.data?.prePresentationChecklist.jobCompleteDate ? (
                      <>
                        {moment(
                          props?.data?.prePresentationChecklist.jobCompleteDate
                        ).format("DD, MMM, YYYY")}
                      </>
                    ) : (
                      <>__________________</>
                    )}
                  </p>
                ) : (
                  <p>
                    within 7 days of{" "}
                    {props?.data?.prePresentationChecklist.jobCompleteDate ? (
                      <>
                        {moment(
                          props?.data?.prePresentationChecklist.jobCompleteDate
                        ).format("DD, MMM, YYYY")}
                      </>
                    ) : (
                      <>__________________</>
                    )}
                  </p>
                )}
              </Col>

              <Col span={8}>
                <h1>Address: </h1>
              </Col>
              <Col span={16} style={{ minHeight: "80px" }}>
                <a
                  style={{
                    color: "#0044CC",
                    // color: "#2200CC",
                    textDecoration: "underline",
                  }}
                  href={`https://maps.google.com/?q=${props?.data?.prePresentationChecklist.project?.streetAddressOne}, ${props?.data?.prePresentationChecklist.project?.streetAddressTwo}, ${props?.data?.prePresentationChecklist.project?.city}, ${props?.data?.prePresentationChecklist.project?.state}, ${props?.data?.prePresentationChecklist.project?.zip}, ${props?.data?.prePresentationChecklist.project?.country}`}
                  target="_blank"
                >
                  {
                    props?.data?.prePresentationChecklist.project
                      ?.streetAddressOne
                  }
                  ,
                  {
                    props?.data?.prePresentationChecklist.project
                      ?.streetAddressTwo
                  }
                  ,{props?.data?.prePresentationChecklist.project?.city},{" "}
                  {props?.data?.prePresentationChecklist.project?.state},
                </a>
              </Col>
            </Row>
          </div>
        </div>

        <div>
          <hr style={{ border: "1px solid #E8E8E8", margin: 0 }} />
          <Row style={{ padding: "20px 20px" }} justify="center">
            <Col>
              <Button
                className="jc-view-payments"
                type="primary"
                onClick={handleViewPayment}
              >
                View Payments
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default JobCard;
