import React from 'react';
import AllJobsC from '../../containers/all-jobs/AllJobsC';

type Props = {};

const AllJobs = (props: Props) => {
  return <AllJobsC />;
};

export default AllJobs;
