import {
  Row,
  Col,
  Table,
  Form,
  Typography,
  Popconfirm,
  Spin,
  Dropdown,
  Menu,
  Button,
} from "antd";
import "./OtherDefaultsC.less";
import AssociatesCard from "../../components/associates-card/AssociatesCard";
import { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../utils/api";
import { costColumns, wageColumns } from "./config";
import MarketSegments from "./MarketSegments";
import MarginEditable from "./MarginEditable";
import { MoreActionIcon } from "../../utils/icons";
import DiscountModal from "../discount-modal/DiscountModal";

const OtherDefaultsC = () => {
  const [discount, setDiscount] = useState([]);
  const [wage, setWage] = useState([]);
  const [wageForm] = Form.useForm();
  const [marginForm] = Form.useForm();
  const [margin, setMargin] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [associatesList, setAssociatesList] = useState([]);
  const [associateLoading, setAssociateLoading] = useState<boolean>(true);

  const [selectedDiscount, setSelectedDiscount] = useState({});
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);

  const sortedMargins = (arr: any) => {
    arr.sort((a: any, b: any) => {
      return a.cost - b.cost;
    });
    return arr;
  };

  useEffect(() => {
    api.get("discounts/defaults").then((resp: any) => {
      setDiscount(resp);
    });
    api.get("defaults/wage").then((resp: any) => {
      setWage(resp);
    });
    api.get("defaults/margin").then((resp: any) => {
      setMargin(sortedMargins(resp));
    });
    api
      .get("user/associates")
      .then((resp: any) => {
        setAssociatesList(resp);
        setAssociateLoading(false);
      })
      .catch(() => {
        setAssociateLoading(false);
      });
  }, []);

  const isEditing = (record: any) => record._id === editingKey;

  const editMargin = (record: any) => {
    marginForm.setFieldsValue({
      margin: "",
      cost: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const editWage = (record: any) => {
    wageForm.setFieldsValue({
      wageValue: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const saveMargin = async (id: string) => {
    try {
      setEditingKey("");
      const row = (await marginForm.validateFields()) as any;
      const newData: any = [...margin];

      const index = newData.findIndex((item: any) => id == item._id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        api.put(`defaults/margin/${id}`, row);

        setMargin(sortedMargins(newData));
        setEditingKey("");
      } else {
        newData.push(row);
        setMargin(sortedMargins(newData));
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const saveWage = async (id: string) => {
    try {
      setEditingKey("");
      const row = (await wageForm.validateFields()) as any;
      const newData: any = [...wage];

      const index = newData.findIndex((item: any) => id == item._id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        api.patch(`defaults/${id}`, row);

        setWage(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setWage(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const newWageColumns = [
    ...wageColumns,
    {
      title: "Action",
      dataIndex: "operation",
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveWage(record._id)}
              style={{ marginRight: 8 }}
            >
              <CheckOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>
                <CloseOutlined />
              </a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => editWage(record)}
          >
            Edit
          </Typography.Link>
        );
      },
      width: "80px",
    },
  ];

  const newCostColumns = [
    ...costColumns,
    {
      title: "Action",

      dataIndex: "operation",
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveMargin(record._id)}
              style={{ marginRight: 8 }}
            >
              <CheckOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>
                <CloseOutlined />
              </a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => editMargin(record)}
          >
            Edit
          </Typography.Link>
        );
      },
      width: "80px",
    },
  ];

  const mergedWageColumns = newWageColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => {
        return {
          record,
          inputType: "number",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });

  const mergedColumns = newCostColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const discountColumns = [
    {
      title: "Description",
      dataIndex: "description",
      width: "50%",
      // editable: true,
    },
    {
      title: "Discount",
      width: "50%",
      editable: true,
      render: (row: any) => {
        return (
          <div>
            {row?.amountType === "dollar" && "$"}
            {row?.amountType === "expression" ? "" : `${row.amount}`}
            {row?.amountType === "percentage" && "%"}{" "}
          </div>
        );
      },
    },
    {
      title: "Action",
      render: (data: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                {data?.amountType !== "expression" && (
                  <Menu.Item
                    key="edit"
                    style={{ fontWeight: "bold" }}
                    onClick={() => handleEdit(data)}
                  >
                    Edit
                  </Menu.Item>
                )}
                <Menu.Item
                  key="delete"
                  style={{ fontWeight: "bold" }}
                  onClick={() => handleDelete(data._id)}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <div style={{ cursor: "pointer" }}>
              <MoreActionIcon />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  const handleUpdateDiscount = (values: any) => {
    api
      .put(`discounts/${values._id}`, { ...values })
      .then((resp: any) => {
        let newData: any = [...discount];
        let objIndex = discount.findIndex((item: any) => item._id === resp._id);
        newData[objIndex] = resp;
        setDiscount(newData);
        setShowDiscountModal(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleEdit = (data: any) => {
    setSelectedDiscount(data);
    setShowDiscountModal(true);
  };

  const handleDelete = (id: string) => {
    api.delete(`discounts/${id}`).then(() => {
      let newData = discount.filter((item: any) => item._id !== id);
      setDiscount(newData);
    });
  };

  const handleAddDiscount = (values: any) => {
    let body = {};
    if (values.amountType === "expression") {
      body = {
        description: values.description,
        amountType: values.amountType,
        expression: values.expression,
      };
    } else {
      body = {
        description: values.description,
        amountType: values.amountType,
        amount: values.amount,
      };
    }
    api
      .post(`discounts`, { ...body })
      .then((resp: any) => {
        let newData: any = [...discount];
        newData.push(resp);
        setDiscount(newData);
        setShowDiscountModal(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleAddDiscountClick = () => {
    setSelectedDiscount({});
    setShowDiscountModal(true);
  };

  return (
    <div className="other-defaults-root">
      <DiscountModal
        showModal={showDiscountModal}
        setShowModal={setShowDiscountModal}
        addDiscount={handleAddDiscount}
        updateDiscount={handleUpdateDiscount}
        discountValue={selectedDiscount}
      />
      <Row className="other-defaults-header">
        <Col>
          <h1>Pjcc Defaults</h1>
        </Col>
      </Row>
      <hr style={{ border: "1px solid #E8E8E8" }} />
      <Form form={wageForm} component={false}>
        <Table
          scroll={{ x: "900px" }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          components={{
            body: {
              cell: MarginEditable,
            },
          }}
          style={{ margin: "30px 0px" }}
          columns={mergedWageColumns}
          dataSource={wage}
          loading={{
            indicator: <Spin />,
            spinning: !wage.length,
          }}
        />
      </Form>
      <Form form={marginForm} component={false}>
        <Table
          scroll={{ x: "900px" }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          components={{
            body: {
              cell: MarginEditable,
            },
          }}
          columns={mergedColumns}
          dataSource={margin}
          loading={{
            indicator: <Spin />,
            spinning: !margin.length,
          }}
        />
      </Form>

      <Row justify={"space-between"} style={{ padding: "10px 16px" }}>
        <Col>
          <h2 style={{ fontWeight: "bold" }}>Discounts</h2>
        </Col>
        <Col>
          <Button onClick={handleAddDiscountClick}>Add Discount</Button>
        </Col>
      </Row>

      <Form form={marginForm} component={false}>
        <Table
          scroll={{ x: "900px" }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          components={{
            body: {
              cell: MarginEditable,
            },
          }}
          columns={discountColumns}
          dataSource={discount}
          loading={{
            indicator: <Spin />,
            spinning: !margin.length,
          }}
        />
      </Form>

      {/* <div className="other-defaults-associate-container">
        <h1>Associate List</h1>
        {associateLoading ? (
          <Row style={{ padding: "80px 0px" }} justify="center">
            <Spin size="large" />
          </Row>
        ) : (
          <Row style={{ marginTop: 32 }} gutter={[25, 25]}>
            {associatesList?.map((item: any) => (
              <Col key={item._id} span={8}>
                <AssociatesCard
                  key={item._id}
                  name={item.fullName}
                  jobTitle={item.jobTitle}
                  phone={item.phone}
                  email={item.email}
                  address={item.location}
                  picture={item.picture}
                />
              </Col>
            ))}
          </Row>
        )}
      </div> */}
      {/* <div className="od-market-segment-container">
        <MarketSegments />
      </div> */}
    </div>
  );
};

export default OtherDefaultsC;
